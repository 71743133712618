<div *ngIf="loading" class="loader">
	<div class="loader-table-container">
		<div class="spinner-border text-secondary" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</div>
<div *ngIf="sessionReady" [@routeAnimations]="prepareRoute(outlet, 'animation')">
	<router-outlet #outlet="outlet"></router-outlet>
</div>

<simple-notifications></simple-notifications>


<ng-template #tfa_model let-modal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h1 class="modal-title" id="twoFactorauthLabel">Enable 2FA</h1>
		<button (click)="close()" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal" aria-label="Close">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<p class="desc">
				For security reasons, we strongly recommend you enable Two-Factor
				Authentication
			</p>
		</div>
		<div class="btns d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-25">
			<button type="button" class="btn btn-primary w-sm-100" (click)="enable2fa()">
				Enable 2FA
			</button>
			<button type="button" class="btn btn-secondary w-sm-100" (click)="close()">
				Close
			</button>
		</div>
	</div>
</ng-template>