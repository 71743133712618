import { Component } from '@angular/core';
import { ProductService } from '../services/product.service';
import { NotificationsService } from 'angular2-notifications';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { NewProduct } from '../model/ddb.model';
import { Utils } from '../util/utils';
import * as exactMath from "exact-math";
import { SessionApi } from '../api/session.api';

@Component({
  selector: 'app-explore-products',
  templateUrl: './explore-products.component.html',
  styleUrls: []
})
export class ExploreProductsComponent {
  private searchTerms: Subject<string> = new Subject<string>();

  sortOptions = [{
    id: 1,
    text: "Name",
    field: "name"
  }, {
    id: 2,
    text: "Code",
    field: "code"
  }];
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    limit: 12
  });
  private unsubscribe$ = new Subject<void>();
  queryResult: QueryResult<NewProduct> = new QueryResult();
  public isLoad: boolean = false;
  public customerId: number;

  constructor(
    private readonly productService: ProductService,
    public notifications: NotificationsService,
    public utils: Utils,
    private session: SessionApi
  ) {
    this.search();
    this.session.$customerData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(customer => {
        if (customer) {
          this.customerId = customer.id;
        }
      });
    if (this.customerId) {
      this.getProducts();
    }
  }

  /**
* @description Retrives products from the server and attaches them to the component
*/
  getProducts = () => {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    let params: any = {};
    params.customerId = this.customerId;
    this.fetchProduct(params);
  }

  fetchProduct(params) {
    this.productService.getUnAuthProductList(this.query, params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(queryResult => {
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;
        this.isLoad = true;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0) {
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.getProducts();
        }
      }, error => {
        this.notifications.error('Fetch Product', error.error && error.error.message);
      });
  }

  finalPrice(product: NewProduct): string | null {
    if (!product || !product.price) {
      return null;
    }

    const finalPrice = exactMath.sub(
      product.price,
      product.subsidyAmount || 0
    );

    if (finalPrice > 0) {
      return this.utils.twoDecimalPlacesString(finalPrice);
    }

    return null;
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.getProducts();
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.getProducts();
  }

  updateSearchTerm(searchTerm: string) {
    this.currentPageActive = 1;
    this.searchTerms.next(searchTerm);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.query.filter['$or'] = {
        name: { $like: '%' + searchTerm + '%' },
        code: { $like: '%' + searchTerm + '%' },
        uri: { $like: '%' + searchTerm + '%' },
        description: { $like: '%' + searchTerm + '%' },
      }
      this.getProducts();
    });
  }


  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.getProducts();
  }
}
