import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbroideredComponent } from '../embroidered/embroidered.component';
import { EmbroideredRoutingModule } from './embroidered.routing.module';


@NgModule({
  declarations: [
    EmbroideredComponent
  ],
  imports: [
    CommonModule,
    EmbroideredRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmbroideredModule { }
