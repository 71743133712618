import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { has } from 'lodash';
import { Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { IShippingDetails } from 'src/app/main/model/shippingDetail.model';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';
import { addressDisplayText } from '../../../util/shippingDetail';
import { ShippingDetailService } from 'src/app/main/services/shippingDetail.service';
import { ModalComponent } from 'src/app/main/template/model.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

type ISortOption = {
  id: number;
  text: string;
  field: string;
};

type ISortOptions = ISortOption[];

@Component({
  selector: 'app-customer-address',
  templateUrl: './address.component.html',
  styleUrls: []
})
export class AddressComponent implements OnInit, OnDestroy {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'addressName'
  });
  customerId: number;

  sortOptions: ISortOptions = [{
    id: 1,
    text: "Name",
    field: "addressName"
  }, {
    id: 2,
    text: "Id",
    field: 'id'
  }, {
    id: 3,
    text: 'Address',
    field: 'streetAddress'
  }];

  queryResult: QueryResult<IShippingDetails> = new QueryResult();

  // When true, the user MUST have either admin or explicit address-edit permission to edit an address
  requireEditPermission: boolean = true;
  // When true, the user is either an admin or has explicit address-edit permission
  canEditAddresses: boolean = true;
  public isCustomer: boolean = false;

  private readonly subscriptionGroup = new SubscriptionGroup();
  public displayText = addressDisplayText;
  public editLink = '';
  isAlreadyChecked: boolean;

  constructor(
    private shippingAddressService: ShippingDetailService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly session: SessionApi,
    private readonly securityService: SecurityService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        if (userData?.isAdmin) {
          this.requireEditPermission = true;

          if (has(params, "customerId")) {
            this.query.filter.customerId = Number(params["customerId"]);
            this.customerId = Number(params["customerId"]);
          }
          const currentUrl = this.activatedRoute.snapshot.url.join('/');
          if (currentUrl.includes('view')) {
            this.editLink = `/manage/customer/view/${this.customerId}/address`;
          } else {
            this.editLink = `/manage/customer/edit/${this.customerId}/address`;
          }
        } else if (customerData) {
          this.query.filter.customerId = customerData.id;
          this.customerId = customerData.id;
          this.requireEditPermission = !!customerData.disableAddressEdit;
          this.isCustomer = true;

          this.editLink = '/account/addresses/edit';
        }
      })
    );
    // this.loadQueryResult()
    this.monitorAccess();
    this.search();
  }

  loadQueryResult() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.shippingAddressService.list(this.query)
      .subscribe(queryResult => {
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadQueryResult();
        }
      });
  }

  monitorAccess() {
    this.subscriptionGroup.add(this.securityService.canEditAddresses().subscribe(canEditAddresses => this.canEditAddresses = canEditAddresses))
  }

  deleteAddress(id: number | undefined) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Address';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Address</h4>
					<p class="desc">Would you like to remove this Address?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && id) {
          this.shippingAddressService.delete(id, this.customerId).subscribe(() => {
            this.loadQueryResult();
          })
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadQueryResult()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadQueryResult();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadQueryResult();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.addressName = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.addressName;

      this.loadQueryResult();
    });
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }

}
