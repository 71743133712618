<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs d-lg-flex align-items-center justify-content-between">
		<a [routerLink]="[pathBase]" [queryParams]="{customerId: customerId, name: name}" class="btn btn-back p-0 gap-2 d-none d-lg-flex"
			role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
					<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
						stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Back to reports
		</a>
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item"><a [routerLink]="[pathBase]" [queryParams]="{customerId: customerId, name: name}">Reports</a>
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					Business unit report
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<div>
				<h1 class="title mb-0">Business unit report</h1>
				<p class="title-desc">
					List all business-units, including all contacts and shipping details.
					Report can be exported to CSV.
				</p>
			</div>
			<div class="edit-btns w-sm-100">
				<button class="btn btn-secondary w-sm-100" type="button" (click)="downloadFile()" *ngIf="reportData.length">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M13.75 7L10 3.25L6.25 7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 3.25V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
					Export CSV
				</button>
			</div>
		</div>

		<div class="manage-user-list">

			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Code</th>
							<th>Contact Name</th>
							<th>Contact Email</th>
							<th>Address</th>
							<th>City</th>
							<th>Region</th>
							<th>Postal Code</th>
							<th>Country</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of reportData">
							<td>
								<div class="user-name">
									<h5>{{ item.name }}</h5>
								</div>
							</td>
							<td>
								<p>{{ item.code }}</p>
							</td>
							<td>
								<p>{{ item.ContactFirstName }} {{ item.ContactLastName }}</p>
							</td>
							<td>
								<div class="user-name">
									<div>
										<h5>{{ item.ContactEmail }}</h5>
									</div>
								</div>
							</td>
							<td>
								<p>
									{{ item.address_name }} {{ item.street_address }}
									{{ item.street_address2 }} {{ item.suburb }}
								</p>
							</td>
							<td>
								{{ item.city }}
							</td>
							<td>
								<p>{{ item.region }}</p>
							</td>
							<td>
								<p>{{ item.postalCode }}</p>
							</td>
							<td>
								{{ item.country }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of reportData">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Name </span>
									<div class="user-name">
										<h5>{{ item.name }}</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Code </span>
									<p>{{ item.code }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Contact Name </span>
									<p>{{ item.ContactFirstName }} {{ item.ContactLastName }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Contact Email </span>
									<p>{{ item.ContactEmail }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Address </span>
									<p>{{ item.address_name }} {{ item.street_address }}
										{{ item.street_address2 }} {{ item.suburb }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> City </span>
									<p>{{ item.city }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Region </span>
									<p>{{ item.region }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Postal Code </span>
									<p>{{ item.postalCode }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Country </span>
									<p>{{ item.country }}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->

		</div>
	</div>
</div>