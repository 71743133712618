import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: [],
})
export class ResetComponent implements OnInit {
  public password: string = '';
  public email: string;
  public token: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private notifications: NotificationsService,
    public router: Router,
    private jwtService: JwtService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((query) => {
      this.email = query.email;
      this.token = query.token;
    });
  }

  submit() {
    this.authService
      .reset({
        email: this.email,
        password: this.password,
        resetToken: this.token,
      })
      .subscribe(
        (res) => {
          this.jwtService.saveJWTData(res);
          this.router.navigate(['/manage/dashboard']);
        },
        (error) => {}
      );
  }
}
