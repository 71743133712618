<section id="blog" class="blog-section">
  <div class="max-width-container">
    <div class="container">
      <div class="section-title">
        <p>News</p>
        <h1>Latest News Posts</h1>
      </div>
    </div>
  </div>
</section>

<section class="blogs">
  <div class="max-width-container">
    <div class="container">
      <div class="row justify-content-start">
        <ng-container *ngFor="let post of blogPosts">
          <div class="col-lg-4 col-md-8 col-sm-10">
            <div class="single-blog position-relative pt-5">
              <span class="d-block fs-5 text-secondary mb-3" *ngIf="post.categories && post.categories.length">{{post.categories[0].name}}</span>
              <div class="blog-header" *ngIf="post.featured_image">
                <img class="img-fluid" loading="lazy" [src]="post.featured_image" [alt]="post.featured_image_alt">
              </div>
              <div class="blog-body">
                <h5 class="package-name"><a href="#">{{post.title}}</a></h5>
                <p className="single-post-body" [innerHTML]="post.summary"></p>
              </div>
              <div class="blog-footer">
                <a class="btn btn-primary stretched-link" (click)="onClick(post.slug)">Read More</a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div> 
</section>