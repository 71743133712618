import { Component, Input } from '@angular/core';
import { v4 as uuid } from "uuid";
import { IEnum } from '../../model/ddb.model';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-enum-list-items',
  templateUrl: './enum-list-items.component.html',
  styleUrls: []
})
export class EnumListItemsComponent {
  @Input()
  theEnum: any;

  @Input()
  key: any;

  @Input()
  canCascade: boolean;

  @Input()
  payload: any;

  prefix: string = uuid();
  public currentEdit: any = [];
  public dataFromInput: any = [];

  constructor(
    public notifications: NotificationsService,
    private modalService: NgbModal
  ) { }

  editEnum(item: IEnum, isSave?: boolean) {
    let isCreated: boolean = false;

    if (!item.originalName) {
      item.originalName = item.name;
    }

    if (isSave) {
      this.payload.created.map((i) => {
        if (i.id == item.id) {
          i.name = item.name;
          isCreated = true;
          return
        }
      });
    }

    const findItem = this.theEnum.children.find((data) => data.id == item.id);
    if (findItem) {
      findItem.isEdit = true;
      if (isSave && !isCreated) {
        this.payload.updated.push(findItem);
        findItem.isEdit = false;
      } else if (isCreated) {
        findItem.isEdit = false;
      }
    }

  }

  closeEnum(item: IEnum) {
    const findItem = this.theEnum.children.find((data) => data.id == item.id);
    if (findItem && findItem.originalName) {
      findItem.name = findItem.originalName;
      findItem.isEdit = false;
    }
  }

  deleteEnum(item: IEnum) {
    const index = this.theEnum.children.findIndex(o => o.id === item.id);
    this.theEnum.children.splice(index, 1);

    if (index !== -1) {
      if (!Number.isNaN(+item.id)) {
        this.addChildId(item.children);
        this.payload.deleted.push(+item.id);
      }
    }

    const inCreated = this.payload.created.findIndex(e => e.id === item.id);
    if (inCreated !== -1) {
      this.payload.created.splice(inCreated, 1);
    }

    const inUpdated = this.payload.updated.findIndex(u => u.id === item.id);
    if (inUpdated !== -1) {
      this.addChildId(item.children);
      this.payload.deleted.push(+item.id);
      this.payload.updated.splice(inUpdated, 1);
    }

  }

  public openRemoveModal(item: IEnum) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Category';
      component.showIcon = true;
      component.data = `
      <div>
        <h4 class="title">Remove Category</h4>
        <p class="desc">Would you like to remove this sub-category?</p>
      </div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete) {
          this.notifications.success('Deleting', `remove category`);
          this.deleteEnum(item);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  addChildId(data) {
    for (let i = 0; i < data.length; i++) {
      this.payload.deleted.push(+data[i].id);
      if (data[i].children.length > 0) {
        this.addChildId(data[i].children);
      }
    }
  }

  /* Saving and Editing the Enums */
  saveEnum(editedItem: HTMLInputElement) {
    if (editedItem.value.trim().length >= 50) {
      this.notifications.error('Error', 'Category have too big name');
      return;
    }

    if (editedItem.value.trim().length >= 3) {
      let parentLavel = this.theEnum.level;
      const item = {
        name: editedItem.value,
        id: uuid(),
        children: [],
        level: this.theEnum.children.length ? this.theEnum.children[0].level : +parentLavel + 1,
        parentId: this.theEnum.id
      }
      this.theEnum.children.push(item);
      this.payload.created.push(item);
      editedItem.value = '';
      this.notifications.success('Adding', `Creating Category`);
    } else {
      this.notifications.error('Error', `Category have minimum 3 length`);
    }
  }
}
