import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { PageSizeOptions } from "../../model/query.filter.class";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-pagination",
  styles: [``],
  template: `
  <div class="table-footer">
  <div class="sorted">
    <h5>Items Per Page:</h5>
    <div class="dropdown">
      <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{pageSize}}
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#36394A" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let count of pageSizeOptions">
        <a class="dropdown-item" (click)="onPageSizeChange(count)">{{count}}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="">
  <nav class="paggination-nav" aria-label="Page navigation example">
  <ul class="paggination">
    <li class="page-item">
      <a class="page-link" href="javascript:void(0)" aria-label="Previous"
         [ngClass]="{ 'link-disabled': currentPage === 1 }" (click)="setPage(currentPage - 1)">
        <span aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M11.8002 13.6004L8.2002 10.0004L11.8002 6.40039" stroke="#666D80" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </a>
    </li>
    <li class="page-item" *ngIf="checkCurrentPage()">
    <a class="page-link">
      ...
    </a>
  </li>  
    <li *ngFor="let page of visiblePages" class="page-item">
    <a class="page-link" href="javascript:void(0)" [ngClass]="{ 'active': page === currentPage }"
       (click)="setPage(page)">
      {{ page }}
    </a>
  </li>  
  
  <li class="page-item" *ngIf="checkLastPage()">
  <a class="page-link">
    ...
  </a>
</li>  

    <li class="page-item">
      <a class="page-link" href="javascript:void(0)" aria-label="Next"
         [ngClass]="{ 'link-disabled': currentPage === totalPages }" (click)="setPage(currentPage + 1)">
        <span aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="#666D80" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </a>
    </li>
  </ul>
</nav>
</div>
</div>
`,
})
export class PaginationComponent implements OnInit {
  pageSizeOptions = PageSizeOptions;
  subPages: number[] = [];
  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() totalRows: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  private readonly storageKey = "lastPage";

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.pageSize);
  }

  get visiblePages(): number[] {
    const range = 2;
    const start = Math.max(1, this.currentPage - range);
    const end = Math.min(this.totalPages, this.currentPage + range);

    const pages: number[] = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }


  get startRow(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endRow(): number {
    const endRow = this.currentPage * this.pageSize;
    return endRow > this.totalRows ? this.totalRows : endRow;
  }

  get totalPagesDisplay(): string {
    return `of ${this.totalPages}`;
  }

  constructor(private router: Router) { }

  ngOnInit() {
    const lastPage = localStorage.getItem(this.storageKey);
    if (lastPage) {
      this.currentPage = parseInt(lastPage, 10);
      localStorage.setItem(this.storageKey, this.currentPage.toString());
    } else {
      localStorage.setItem(this.storageKey, '1');
    }
    setTimeout(() => {
      this.handlePageChange();
    });
  }

  public handlePageChange() {
    this.pageChange.emit(this.currentPage);
    this.updateQueryParams();
  }

  setPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      localStorage.setItem(this.storageKey, this.currentPage.toString());
      this.handlePageChange();
    }
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(pageSize);
  }

  checkCurrentPage() {
    return this.currentPage > 3;
  }

  checkLastPage() {
    return this.currentPage < this.totalPages - 2;
  }

  updateQueryParams() {
    this.router.navigate([], {
      queryParams: {
        page: this.currentPage,
        pageSize: this.pageSize
      },
      queryParamsHandling: 'merge'
    });
  }
}
