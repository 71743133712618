<div class="main-right-content">
	<div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4 gap-4"
		*ngIf="!isProfile">
		<!-- Back-button start here... -->
		<div class="backs mb-0">
			<a [routerLink]="['/account/users']" class="btn btn-back p-0 gap-2" role="button">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
						<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
							stroke-linecap="round" stroke-linejoin="round"></path>
					</svg>
				</span>
				Back to Users
			</a>
		</div>
		<!-- Back-button end here... -->
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item">
					<a [routerLink]="['/account/users']">Users</a>
				</li>
				<li class="breadcrumb-item active" aria-current="page" *ngIf="customerUser && customerUser.user">
					{{ customerUser.user.firstName }} {{ customerUser.user.lastName }}
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>

	<!-- manage-user main start here... -->
	<div class="manageUser" *ngIf="customerUser && customerUser.user">
		<div class="manage-user-list">
			<div class="edit-users edit-allocation user-profile">
				<div class="employee-detail-order">
					<div class="edit-card card">
						<div class="card-head justify-content-end">
						</div>
						<div class="card-body pt-lg-0">
							<div class="employee-detail">
								<div class="titles">
									<span class="name-icon mx-auto">
										{{ customerUser.user.firstName.trim().charAt(0).toUpperCase()
										}}{{
										customerUser.user.lastName.trim().charAt(0).toUpperCase()
										}}</span>
									<span class="badge bg-aqua-25 ps-3 py-1 mx-auto mb-2">Employee</span>
									<h4 class="card-title mb-0">
										{{ customerUser.user.firstName }}
										{{ customerUser.user.lastName }}
									</h4>
									<p>{{ customerUser.user.email }}</p>
								</div>
								<div class="activity">
									<div>
										<p>
											<span>Joined date</span>
											{{
											customerUser.startDate && customerUser.startDate
											| date : "DD MMM, YYYY"
											}}
											{{ !customerUser.startDate && "N/A" }}
										</p>
									</div>
									<div>
										<p *ngIf="customerUser.user.lastLoginAt">
											<span>Last login</span>
											{{
											customerUser.user.lastLoginAt | date : "MMM d, yyyy"
											}}
										</p>
										<p *ngIf="!customerUser.user.lastLoginAt">
											<span>Last login</span>
											N/A
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<app-order-tab [isEmploye]="true"></app-order-tab>
				</div>
				<div>
					<div class="edit-card card">
						<div class="card-body">
							<div class="employee-detail d-flex align-items-center justify-content-between">
								<div class="progress-garments">
									<div class="bg-shadow-1 d-none">
										<div class="radialProgressBar progress-70">
											<div class="overlay">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
													<path
														d="M11.8396 2.52637H2.59516C2.04533 2.52637 1.60079 3.11746 1.60028 3.72637C1.59961 4.52637 2.04533 5.31748 2.59516 5.31748H12.9774C13.7629 5.31748 14.3996 5.15423 14.3996 5.9397V12.7664C14.3996 13.7089 13.6355 14.473 12.6929 14.473H3.30628C2.36371 14.473 1.59961 13.7089 1.59961 12.7664V4.12637M10.9974 9.3419L10.9863 9.35303"
														stroke="#36394A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												</svg>
											</div>
										</div>
									</div>
									<p class="d-flex flex-column flex-md-row align-items-lg-center gap-2 align-items-start">
										<span>Balance</span>
										<span class="badge bg-alpha-25 ps-3 py-1 text-dark">{{
											customerUser.allocationBalance
											}}</span>
									</p>
								</div>
								<!-- <div class="employee-detail-content text-end">
                  <p class="mb-0">Annual allocation:</p>
                  <h5 class="text-content">2 garments</h5>
                </div> -->
							</div>
						</div>
					</div>
					<div class="edit-card card">
						<div class="card-body">
							<div class="employee-detail">
								<h4 class="card-title">Default Shipping address</h4>
								<div class="employee-detail-content" *ngIf="customerUser.user.shippingDetails">
									<h5 class="content-text">
										{{ customerUser.user.shippingDetails.addressName }}
									</h5>
									<h5 class="content-text">
										{{ customerUser.user.shippingDetails.streetAddress }}
									</h5>
									<h5 class="content-text">
										{{ customerUser.user.shippingDetails.streetAddress2 }}
									</h5>
									<h5 class="content-text">
										{{ customerUser.user.shippingDetails.postalCode }}
									</h5>
									<h5 class="content-text">
										{{ customerUser.user.shippingDetails.city }}
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div class="edit-card card">
						<div class="card-body">
							<div class="employee-detail">
								<h4 class="card-title">User details</h4>
								<div class="employee-detail-content">
									<ul>
										<li>
											<p>First Name</p>
											<h5 class="content-text">
												{{ customerUser.user.firstName }}
											</h5>
										</li>
										<li>
											<p>Last Name</p>
											<h5 class="content-text">
												{{ customerUser.user.lastName }}
											</h5>
										</li>
										<li>
											<p>Email address</p>
											<h5 class="content-text">
												{{ customerUser.user.email }}
											</h5>
										</li>
										<li>
											<p>Job title</p>
											<h5 class="content-text">
												{{ customerUser.user.jobTitle || "N/A" }}
											</h5>
										</li>
										<li>
											<p>Contact Number</p>
											<h5 class="content-text">
												{{ customerUser.user.contactNumber || "N/A" }}
											</h5>
										</li>
										<li>
											<p>Required approval? Orders Over</p>
											<h5 class="content-text">
												{{ customerUser.approvalLimit || "N/A" }}
											</h5>
										</li>
										<li>
											<p>Employee Number</p>
											<h5 class="content-text">
												{{ customerUser.employeeNumber || "N/A" }}
											</h5>
										</li>
										<li>
											<p>Business unit</p>
											<h5 class="content-text">
												{{ businessUnit || "N/A" }}
											</h5>
										</li>
										<!-- <li>
                      <p>Authorized Approver</p>
                      <h5 class="content-text">-</h5>
                    </li> -->
										<li>
											<p>Start date</p>
											<h5 class="content-text">
												{{
												customerUser.startDate
												? (customerUser.startDate | date : 'dd MMM, yyyy')
												: 'N/A'
												}}
											</h5>
										</li>
										<li>
											<p>Cost Account Number (optional)</p>
											<h5 class="content-text">
												{{ customerUser.costAccount || "N/A" }}
											</h5>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>