<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/account/favorites']">My lists</a>
			</li>
			<li *ngIf="isEdit" class="breadcrumb-item">
				<a (click)="isEdit = false">{{ name }}</a>
			</li>
			<li *ngIf="!isEdit" class="breadcrumb-item active" aria-current="page">
				{{ name }}
			</li>
			<li *ngIf="isEdit" class="breadcrumb-item active" aria-current="page">
				Edit
			</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between">
			<h1 class="title" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Click to change the name">
				{{ name }}
			</h1>

			<div *ngIf="!isEdit && productResult.rows.length" class="edit-btns flex-cloumn flex-md-row w-sm-100 w-auto">
				<a (click)="openRemoveModal()" class="btn btn-danger w-sm-100 w-auto"><span><svg
							xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M3.25 5.5H4.75H16.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg></span>Remove list</a>
				<a (click)="isEdit = true" class="btn btn-primary w-sm-100 w-auto"><span><svg xmlns="http://www.w3.org/2000/svg"
							width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M10 16.0002H16.75M13.375 3.62517C13.6734 3.3268 14.078 3.15918 14.5 3.15918C14.7089 3.15918 14.9158 3.20033 15.1088 3.28029C15.3019 3.36024 15.4773 3.47743 15.625 3.62517C15.7727 3.77291 15.8899 3.9483 15.9699 4.14132C16.0498 4.33435 16.091 4.54124 16.091 4.75017C16.091 4.9591 16.0498 5.16599 15.9699 5.35902C15.8899 5.55204 15.7727 5.72743 15.625 5.87517L6.25 15.2502L3.25 16.0002L4 13.0002L13.375 3.62517Z"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg></span>Edit</a>
			</div>

			<div *ngIf="isEdit" class="edit-btns flex-cloumn flex-md-row w-sm-100 w-auto">
				<a (click)="cancelProduct();" class="btn btn-secondary w-sm-100 w-auto">Cancel</a>
				<a (click)="saveChanges()" class="btn btn-primary w-sm-100 w-auto">Save changes</a>
			</div>
		</div>

		<div class="manage-user-list">
			<div class="products-cards">
				<div *ngFor="let product of productResult.rows; let i = index;" class="product-card card">
					<div class="card-img">
						<img src="{{
								product.imageUrl ||
									'../../../../assets/images/collection-product.png'
							}}" alt="collection-product" />
						<div class="badges">
							<span class="badge bg-aqua-25">New</span>
						</div>
						<div class="dlt-top" *ngIf="isEdit">
							<a (click)="deleteProduct(i,product.id)" class="btn btn-secondary-icon" type="button">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round"></path>
										<path
											d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
								</span>
							</a>
						</div>
					</div>
					<div class="card-content">
						<h4 class="title">{{ product.name }}</h4>
						<h6 class="brand">{{ product.brand && product.brand.name }}</h6>
						<p class="price">${{ product.price }}</p>
					</div>
				</div>
				<div *ngIf="productResult.rows.length == 0 && !isLoading">
					No products found in this list
				</div>
			</div>
			<app-pagination *ngIf="canInitiate"  [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
				(pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>
		</div>
	</div>
</div>