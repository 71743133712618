import { Component, ElementRef, HostListener, Input, OnDestroy, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { Subject, Subscription, catchError, combineLatest, debounceTime, distinctUntilChanged, filter, first, interval, of, takeUntil, tap, throwError } from 'rxjs';
import Raven from 'raven-js';
import { logger } from '../../util/Logger';
import { SessionApi } from '../../api/session.api';
import { IAuthDataResponse } from '../../model/session.model';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { SecurityService } from '../../services/security.service';
import { UnleashedCustomerExtended } from '../../model/unleashed.model';
import { CollectionService } from '../../services/collection.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { ICollection } from '../../model/collection.model';
import { ProductService } from '../../services/product.service';
import { EnumCreateParams, ICategory, NewProduct } from '../../model/ddb.model';
import { hasParentWithClass } from '../../util/dom.util';
import { NotificationsService } from 'angular2-notifications';
import { Utils } from '../../util/utils';
import * as exactMath from "exact-math";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from '../../services/category.service';
import { CartService } from '../../services/cart.service';
import { ServerCartItem } from '../../model/cart.model';
import { IShippingDetails, ShippingDetailsClass } from '../../model/shippingDetail.model';
import { CustomerUserService } from '../../services/customerUser.service';
import { AdminApi } from '../../api/admin.api';
import { defaultServerMessage } from './app-header.component.util';
import { IAuthCustomerUser } from '../../model/customer.user.model';
const className = "AppHeaderComponent";

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./_app-header.component.scss']
})
export class AppHeaderComponent implements OnDestroy {
	/** Page Access */
	hasAccountAccess: boolean = false;
	hasCartAccess: boolean = false;
	hasProductAccess: boolean = false;
	isAuthenticated: boolean = false;
	/** / Page Access */
	@Input() isAuth: boolean | undefined;
	collections: ICollection[] = [];
	public isEmulatingUser: boolean;
	public user: IAuthDataResponse;
	private readonly subscriptionGroup = new SubscriptionGroup();
	public total: number = 0;
	public product: NewProduct;
	public totalProductCount = 0;

	// Allows the megamenu to be fixed in place to guarantee that it won't close when the user is selecting a password from their vault etc
	public stickyMenu: string | null = null;
	query: IQueryFilter = new IQueryFilter({
		sortBy: 'name',
		limit: 1000
	});
	queryResult: QueryResult<NewProduct> = new QueryResult();
	private searchTerms: Subject<string> = new Subject<string>();
	private searchSubscription: Subscription;
	public isProductsActive: boolean = false;
	public isAboutOpen: boolean = false;
	public isProductCategoryOpen: boolean = false;
	public selectedCategoryMobile: ICategory;
	public selectedIndex: number;
	public existingCartItems: ServerCartItem[] = [];
	primaryCategory = '';
	secondaryCategory = '';
	categoriesPath: string[] = [];
	processDates: string;
	workWearCategories = [
		{ label: 'Hi-Vis Shirts', category: 'hi-vis' },
		{ label: 'Traditional Workwear', category: 'traditional_workwear' },
		{ label: 'Fire Retardant', category: 'fire_retardant' },
		{ label: 'Casual Wear', category: 'casual_wear' },
		{ label: 'Accessories', category: 'accessories' }
	];
	clothingCategories = [
		{ label: 'Hi-Vis Shirts', category: 'hi-vis' },
		{ label: 'Promotional', category: 'promotional' },
		{ label: 'Casual Wear', category: 'casual_wear' },
		{ label: 'Corporate', category: 'corporate' },
		{ label: 'Accessories', category: 'accessories' }
	];
	workPlaceCategories = [
		{ label: 'Traffic Management', category: 'traffic_management' },
		{ label: 'Cleaning', category: 'cleaning' },
		{ label: 'Lockout Tags', category: 'lockout_tags' },
		{ label: 'Signs', category: 'signs' },
		{ label: 'first Aid', category: 'first aid' }
	];
	ppeCategories = [
		{ label: 'Eye Protection', category: 'eye_protection' },
		{ label: 'Hand Protection', category: 'hand_protection' },
		{ label: 'Respiratory', category: 'respiratory' },
		{ label: 'Height Safety', category: 'height_safety' },
		{ label: 'Hearing Protection', category: 'hearing_protection' }
	];
	@ViewChild('searchpopup', { static: true }) searchPopupRef!: ElementRef<any>;
	public isLoading: boolean;
	public isMenuOpen: boolean = false;
	public isSubMenuOpen: boolean = false;
	public isAllProductsOpen: boolean = false;
	public isAccountMenuOpen: boolean = false;
	public isSolutionOpen: boolean = false;
	public isIndustryOpen: boolean = false;
	private unsubscribe$ = new Subject<void>();
	collectionResult: ICollection[] = [];
	public userShippingDetails: IShippingDetails | null;
	public isBackToMe = false;
	public isLogout = false;
	public isShow = true;
	public requestEmail: string | null;

	/* System Message */
	public serverMessage = defaultServerMessage();
	isShowAllocation: number;
	removeServerMessage: boolean;
	get showServerMessage() {
		return this.serverMessage && this.serverMessage.severity !== 'disabled' && this.serverMessage.message && this.serverMessage.message.length;
	}
	public isCategorySelected: boolean;
	public categories: EnumCreateParams[] = [];
	public staticCategories: EnumCreateParams[] = this.categoryService.staticCategories;

	constructor(
		private readonly authService: AuthService,
		public router: Router,
		private jwtService: JwtService,
		public session: SessionApi,
		public securityService: SecurityService,
		private productService: ProductService,
		private renderer: Renderer2,
		private notifications: NotificationsService,
		private utils: Utils,
		private modalService: NgbModal,
		private route: ActivatedRoute,
		private categoryService: CategoryService,
		public cartService: CartService,
		private customerUserService: CustomerUserService,
		private adminApi: AdminApi,
		private readonly collectionsService: CollectionService
	) {
		if (!this.checkAuthenticated()) {
			this.requestEmail = this.route.snapshot.queryParamMap.get('email');
			const currentRoute = this.router.url;
			if (currentRoute.includes('home') && this.requestEmail) {
				this.enableStickyMenu('login');
				this.isShow = true;
			}
		}
		authService.getStickyMenu().subscribe(menu => {
			if (menu == 'login') {
				this.enableStickyMenu('login');
				authService.setAccountMenu('login');
				this.isShow = true;
			}
			if (menu == 'account') {
				this.enableStickyMenu('login');
				authService.setAccountMenu('account');
				this.isShow = true;
			}
		});


		combineLatest(
			this.authService.$userPassAuthRequested,
			this.session.$userData.pipe(distinctUntilChanged()),
			this.session.$customerData.pipe(distinctUntilChanged()),
			this.securityService.hasCartAccess()
		).pipe(
			filter(([isUserPassAuth, userData, customerData, hasCartAccess]) => !!isUserPassAuth || !!userData),
			takeUntil(this.unsubscribe$)
		).subscribe(([_, userData, customerData, hasCartAccess]) => {
			this.hasCartAccess = hasCartAccess
			if (userData?.customerUsers?.length) {
				const allocation = userData.customerUsers[0].userAllocations;
				const formattedDates: string[] = [];
				if (allocation) {
					allocation.forEach(obj => {
						const allo = this.customerUserService.describeRunSequence(obj);
						const convertedAllocation = allo.split(',');
						if (convertedAllocation.length) {
							const dateParts = convertedAllocation[0].split('/');
							if (dateParts.length === 3) {
								const day = parseInt(dateParts[0], 10);
								const month = parseInt(dateParts[1], 10) - 1;
								const year = parseInt(dateParts[2], 10);

								const processedDate = new Date(year, month, day);
								if (!isNaN(processedDate.getTime())) {
									const formattedDate = processedDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
									formattedDates.push(formattedDate);
								}
							}
						}
					});

					formattedDates.sort((a, b) => {
						const dateA = new Date(a) as any;
						const dateB = new Date(b) as any;
						return dateA - dateB;
					});

					this.processDates = formattedDates.join(", ");
				}

			}
			if (userData) {
				this.user = userData;
			}

			if (customerData && !this.user.isAdmin) {
				if (hasCartAccess) {
					this.fetchCartItems(customerData.id);
				}
				this.loadLastAddress();

				this.collectionsService.getCollections().subscribe(avlCollection => {
					this.collections = avlCollection;
					this.isShowAllocation = avlCollection.reduce((total, coll) => {
						return total + (coll.allocationCount || 0);
					}, 0);
				})
			}
			this.monitorAccess();
		});

		if (this.checkAuthenticated()) {
			this.securityService.isEmulatingUser().subscribe({
				next: isEmulating => {
					this.isEmulatingUser = isEmulating;
				}
			});
		}
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.isProductsActive = (event.url === '/products');
		});

		this.route.queryParams.subscribe(params => {
			if (params.isLogin === 'false') {
				this.stickyMenu = 'login';
			}
		});
		this.search();
	}

	/**
* @description show all collections if user don't have access to see categories
*/
	get displayedCollections() {
		return this.showCategories ? this.collections.slice(0, 4) : this.collections;
	}

	/**
* @description Handles the response from a category list get
* @param items the response from the communication service
*/
	handleCategoriesGet = (items: EnumCreateParams[]) => this.categories = items;

	getCategoriesByParent = (parentCategory?: EnumCreateParams) => {
		if (this.categories.length === 0) {
			return this.staticCategories.filter(category => category.parentId === (parentCategory ? parentCategory.id : undefined));
		}
		return this.categories.filter(category => category.parentId === (parentCategory ? parentCategory.id : null));
	};

	get showCategories(): boolean {
		return this.securityService ? this.securityService.showCategoriesInProductSearch() : true;
	}

	get showCollections(): boolean {
		return this.securityService ? this.securityService.showCollectionsInProductSearch() : true;
	}

	ngOnInit() {
		// Load and refresh the server message every 10 minutes
		this.updateServerMessage();
		interval(600000).pipe(tap(() => this.updateServerMessage())).subscribe();
	}

	updateServerMessage() {
		const authData = this.session.$userData.getValue();

		if (!authData) {
			this.serverMessage = defaultServerMessage();
			return;
		}

		this.adminApi.getServerMessage().pipe(
			first()
		)
			.subscribe(msg => {
				this.serverMessage = msg;
			});
	}

	fetchCartItems(customerId: number) {
		this.cartService.getCartItems(customerId)
			.pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(() => {
				this.cartService.cart.itemCount = this.cartService.items.length;
				for (let i of this.cartService.items) {
					let productImage = i.product.images.find(image => image.name === i.variation.colour)
					i.variation.imageUrl = productImage ? productImage.url : i.product.imageUrl as string | undefined;
				}
			})
	}

	fetchImage(i: number, type: string) {
		if (type == 'collections') {
			switch (i) {
				case 0:
					return '../../../../assets/images/allpavatar1.png';
				case 1:
					return '../../../../assets/images/allpavatar2.png';
				case 2:
					return '../../../../assets/images/allpavatar1.png';
				case 3:
					return '../../../../assets/images/allpavatar3.png';
				default:
					return '../../../../assets/images/allpavatar1.png';
			}
		}
		if (type == 'categories') {
			switch (i) {
				case 0:
					return '../../../../assets/images/allpimg1.png';
				case 1:
					return '../../../../assets/images/allpimg2.png';
				case 2:
					return '../../../../assets/images/allpimg3.png';
				case 3:
					return '../../../../assets/images/allpimg4.png';
				default:
					return '../../../../assets/images/allpimg2.png';
			}
		}
	}

	generateGradient(colorString: string): string {
		if (colorString) {
			const colors = colorString.split('/');
			if (colors.length >= 2) {
				const firstColor = colors[0].trim();
				const secondColor = colors[1].trim();
				return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
			} else if (colorString) {
				return colorString;
			} else {
				return 'black';
			}
		} else {
			return 'black';
		}
	}

	checkAuthenticated() {
		return this.authService.isAuthenticated();
	}

	/**
 * Monitors various access checks and updates the local viewstate where appropriate
 */
	monitorAccess() {
		this.subscriptionGroup.add(this.securityService.hasAccountAccess().subscribe(hasAccountAccess => this.hasAccountAccess = hasAccountAccess));
		this.subscriptionGroup.add(this.securityService.hasProductAccess().subscribe(hasProductAccess => this.hasProductAccess = hasProductAccess));
		this.subscriptionGroup.add(this.securityService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated));
		this.subscriptionGroup.add(this.categoryService.allCategories$.subscribe({
			next: categories => {
				if (categories && categories.rows.length) {
					this.handleCategoriesGet(categories.rows);
					this.totalProductCount = categories.rows
						.filter(val => !val.parentId)
						.reduce((sum, val) => {
							return sum + (val.count || 0);
						}, 0);
					return;
				}

				this.totalProductCount = 0;
			}
		}));
	}

	loadLastAddress() {
		this.userShippingDetails = this.session.getUserProperty('shippingDetails');
		if (!this.userShippingDetails?.addressName && !this.userShippingDetails?.streetAddress) {
			this.subscriptionGroup.add(this.cartService.getLastAddress().subscribe(res => {
				this.userShippingDetails = res;
			}));
		}
	}

	/**
* Generates easily read display text for any given shipping details item
* @param details
* @param includeName
*/
	addressDisplayText(details: (ShippingDetailsClass & { displayName?: string }), includeName = false) {
		return (includeName && (details.addressName || details.displayName) ? (details.displayName || details.addressName) + ': ' : '') +
			(details.streetAddress ? details.streetAddress + ' ' : '') +
			(details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
			(details.suburb ? details.suburb : '');
	}

	get isApprovalManager(): boolean {
		/**
		 * @todo this is only temporary, a permission should be used
		 */
		return this.user && this.user.customerUsers && this.user.customerUsers[0] && this.user.customerUsers[0].userRole ? this.user.customerUsers[0].userRole.name === 'Approval Manager' : false;
	}

	logOut(model) {
		this.modalService.open(model, {
			size: 'sm', centered: true, windowClass: 'deleteModal', backdrop: 'static', keyboard: false
		}).result.then(() => {
			this.confirmLogOut();
		}, () => { });
	}

	confirmLogOut() {
		this.isLogout = true;
		this.authService.logOut()
			.pipe(
				catchError(err => {
					if (err.status === 401 || err.status === 403) {
						return of(undefined);
					}

					return throwError(err)
				})
			).pipe(
				takeUntil(this.unsubscribe$)
			)
			.subscribe(this.handleLogout, error => {
				this.notifications.error('Logout', 'Something Wrong');
			});
	}

	private readonly handleLogout = () => {
		const signature = "UserLoginService.handleLogout: ";
		this.jwtService.removeJWTData();
		Raven.setUserContext();
		this.notifications.success('Logout', 'Logged out successfully');
		logger.info(signature + "Navigating to Home");
		this.router.navigate(['']);

		this.isLogout = false;
	};

	unSwitch() {
		this.isBackToMe = true;
		if (this.isEmulatingUser) {
			this.authService.unswitchUser().pipe(
				takeUntil(this.unsubscribe$)
			)
				.subscribe(() => {
					this.isBackToMe = false;
					this.notifications.success('Back to User', 'Back to current user successfully');
					// this.authService.navigateToDefaultUrl();
				}, err => {
					this.notifications.error('Back to User', 'Something wrong');
				});
		}
	}

	public switchCustomer(customerUser: IAuthCustomerUser) {
		const signature = className + '.switchCustomer: ';
		const currentUser = this.session.$userData.getValue();

		if (!currentUser) {
			logger.error(signature + `Unable to switch customer due to Invalid User`);
			return false;
		}

		if (customerUser.customerId)
			this.authService.switchUser(customerUser.customerId, currentUser.id).pipe(
				takeUntil(this.unsubscribe$)
			).subscribe({
				next: (res) => {
					this.notifications.success('Switch', 'Your access was updated');
				},
				error: err => {
					this.notifications.error('Switch', 'Something went wrong');
				}
			});
	}

	productRoute() {
		// if (this.checkAuthenticated()) {
		this.router.navigate(['/products']);
		this.isCategorySelected = true;
		// } else {
		// 	this.stickyMenu = 'login';
		// 	this.isShow = true;
		// }
	}

	autoHideStickyMenu() {
		const signature = className + ".autoHideStickyMenu: ";

		this.renderer.listen('document', 'click', (evt: MouseEvent) => {
			if (!hasParentWithClass(evt.target, ['megamenu', 'showmega'], "OR")) {
				this.stickyMenu = null;
				logger.silly(signature + `StickyMenu Disabled by Event[click]`);
			}
		});

		this.renderer.listen('document', 'keyup.escape', () => {
			this.stickyMenu = null;
			logger.silly(signature + `StickyMenu Disabled by Event[keyup.escape]`);
		});
	}


	enableStickyMenu(name: string = '') {
		const signature = className + ".enableStickyMenu: ";
		this.stickyMenu = name;
		logger.silly(signature + `StickyMenu enabled`);
	}

	updateSearchTerm(searchTerm: string, isMobile = false): void {
		if (isMobile) {
			if (!this.checkAuthenticated()) {
				this.stickyMenu = 'login';
				this.closeAll();
				return;
			}
		}
		this.searchTerms.next(searchTerm);
	}

	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent) {
		// Check if the click event occurred outside of the dropdown area
		const isClickedInsideDropdown = (event.target as HTMLElement).closest('.showmega');
		if (!isClickedInsideDropdown && this.stickyMenu !== 'login') {
			// If clicked outside, close the dropdown
			this.stickyMenu = '';
		}

		if (!isClickedInsideDropdown && this.isAuthenticated) {
			this.stickyMenu = '';
		}
	}

	removeActiveClassFromElement() {
		const element = this.renderer.selectRootElement(`#login`, true);
		if (element) {
			this.isShow = false;
			this.renderer.removeClass(element, 'active');
		}
	}

	search() {
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}
		this.searchSubscription = this.searchTerms.pipe(
			debounceTime(500),
			distinctUntilChanged(),
		).pipe(
			takeUntil(this.unsubscribe$)
		).subscribe(searchTerm => {
			if (searchTerm && searchTerm.length)
				this.query.filter['$or'] = {
					name: { $like: '%' + searchTerm + '%' },
					code: { $like: '%' + searchTerm + '%' },
					uri: { $like: '%' + searchTerm + '%' },
					description: { $like: '%' + searchTerm + '%' }
				}
			else
				delete this.query.filter['$or'];

			this.getProducts();
		});
	}

	/**
* @description Retrives products from the server and attaches them to the component
*/
	getProducts = () => {
		const subscriber = this.checkAuthenticated() ? this.productService.getProductList(this.query) : this.productService.getUnAuthProductList(this.query);
		subscriber
			.pipe(
				takeUntil(this.unsubscribe$)
			).subscribe(queryResult => {
				this.queryResult = queryResult;
			}, error => {
				this.notifications.error('Fetch Product', error.error && error.error.message);
			});
	}

	finalPrice(product: NewProduct): string | null {
		if (!product || !product.price) {
			return null;
		}

		const finalPrice = exactMath.sub(
			product.price,
			product.subsidyAmount || 0
		);

		if (finalPrice > 0) {
			return this.utils.twoDecimalPlacesString(finalPrice);
		}

		return null;
	}

	public openModel(content) {
		this.modalService.open(content, {
			size: 'md', centered: true, backdrop: true, windowClass: 'searchpopup'
		});
	}

	navigategProductRoute(uri: string) {
		this.router.navigate(['/product/' + uri]);
		this.modalService.dismissAll();
	}

	/**
* @description Standardised conversion of category name to url format
* @param categoryName
*/
	toUrlKey = (categoryName: string) => categoryName.replace(/\s/g, '_').toLowerCase();

	handleCategoryClick(primaryCategory?: string, secondaryCategory?: string) {
		this.primaryCategory = primaryCategory ? this.toUrlKey(primaryCategory) : '';
		this.secondaryCategory = secondaryCategory ? this.toUrlKey(secondaryCategory) : '';
		this.categoriesPath = [];
		if (this.primaryCategory) {
			this.categoriesPath.push(this.primaryCategory);
		}

		let targetRoute = ['products'];
		if (this.primaryCategory && this.primaryCategory.length) {
			targetRoute.push(this.primaryCategory);
			if (this.secondaryCategory && this.secondaryCategory.length) {
				targetRoute.push(this.secondaryCategory);
			}
		}
		this.router.navigate(targetRoute);
		this.isCategorySelected = true;
		this.modalService.dismissAll();
	}

	handleCollectionClick(collectionId: number | undefined) {
		let targetRoute = ['products'];
		this.router.navigate(targetRoute, { queryParams: { collection: collectionId } });
		this.isCategorySelected = true;
		this.modalService.dismissAll();
	}

	changeStyle() {
		this.isCategorySelected = false;
	}

	openMenu() {
		const body = document.querySelector('body');
		this.isMenuOpen = !this.isMenuOpen;
		if (this.isMenuOpen) {
			body?.setAttribute('style', 'overflow: hidden;');
		} else {
			body?.removeAttribute('style');
		}
	}

	openSubMenu() {
		this.isSubMenuOpen = !this.isSubMenuOpen;
		if (!this.isSubMenuOpen) {
			this.isAccountMenuOpen = false;
			this.isAllProductsOpen = false;
			this.isSolutionOpen = false;
			this.isIndustryOpen = false;
			this.isAboutOpen = false;
			this.isProductCategoryOpen = false;
		}
	}

	openAccountMenu(menu: string) {
		switch (menu) {
			case 'account':
				this.isAccountMenuOpen = !this.isAccountMenuOpen;
				break;
			case 'product':
				if (this.showCategories) {
					this.isAllProductsOpen = !this.isAllProductsOpen;
				} else {
					this.productRoute();
					this.closeAll()
				}
				break;
			case 'solution':
				this.isSolutionOpen = !this.isSolutionOpen;
				break;
			case 'industry':
				this.isIndustryOpen = !this.isIndustryOpen;
				break;
			case 'about':
				this.isAboutOpen = !this.isAboutOpen;
				break;
		}
		this.openSubMenu();
	}

	openproductCategory(category: ICategory, i: number) {
		this.selectedCategoryMobile = category;
		this.selectedIndex = i;
		this.isProductCategoryOpen = !this.isProductCategoryOpen;
	}

	closeAll() {
		this.isSubMenuOpen = false;
		this.isMenuOpen = false;
		const body = document.querySelector('body');
		body?.removeAttribute('style');
		this.isAccountMenuOpen = false;
		this.isAllProductsOpen = false;
		this.isSolutionOpen = false;
		this.isIndustryOpen = false;
		this.isAboutOpen = false;
		this.isProductCategoryOpen = false;
	}

	ngOnDestroy() {
		if (this.subscriptionGroup) {
			this.subscriptionGroup.unsubscribe();
		}
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	navigateToProductPage(searchTerm: string, isMobile = false) {
		if (isMobile) {
			if (!this.checkAuthenticated()) {
				this.stickyMenu = 'login';
				this.closeAll();
				return;
			}
		}
		if (searchTerm) {
			let fragment = '';
			let searchUri = '/products';
			if (fragment.length) {
				fragment += '&';
			}
			fragment += 'search=' + searchTerm;
			this.router.navigate([searchUri], { fragment: fragment });

			this.modalService.dismissAll();
			this.closeAll();
		}

		this.showSearchBox();
	}

	isMobileDevice(): boolean {
		return /Mobi|Android/i.test(navigator.userAgent);
	}

	@ViewChild('searchText') searchInput: ElementRef;

	showSearchBox() {
		if (this.isMobileDevice()) {
			setTimeout(() => {
				this.searchInput.nativeElement.blur();
			}, 0);
		}
	}

	updateTotal(total: number) {
		this.total = total;
	}

	updateCollections(collection: ICollection[]) {
		this.collectionResult = collection.filter(coll => coll.allocationAvailable);
	}
}
