<section class="loginBanner py-0">
  <div class="max-width-container">
    <div class="bg-design"></div>
    <div class="card login-card">
      <div class="card-body">
        <form action="">
          <h4 class="text-secondary">Create account</h4>
          <div class="form-group">
            <label for="firstName" class="form-label">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              name="firstName"
              placeholder="Enter your first name"
              [(ngModel)]="firstName"
            />
          </div>
          <div class="form-group">
            <label for="lastName" class="form-label">Last Name </label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              name="lastName"
              placeholder="Enter last name"
              [(ngModel)]="lastName"
            />
          </div>
          <div class="form-group">
            <label for="registerEmail" class="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="registerEmail"
              name="email"
              placeholder="Enter your email address"
              [(ngModel)]="email"
            />
          </div>
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="registerUser()"
          >
            SIGN IN
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
