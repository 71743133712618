import { Component } from '@angular/core';

@Component({
  selector: 'app-gawun-template',
  templateUrl: './gawun-template.component.html',
  styleUrls: ['./_gawun-template.component.scss']
})
export class GawunTemplateComponent {

}
