import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthApi } from 'src/app/main/api/auth.api';

@Component({
  selector: 'app-blogsdetail',
  templateUrl: './blogsdetail.component.html',
  styleUrls: ['./_blogsdetail.component.scss']
})
export class BlogsdetailComponent {
  post!: any;
  slug: string | null;
  constructor(private authApi: AuthApi, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('slug');
    if (this.slug) {
      this.authApi.getBlogDetail(this.slug).subscribe((res: any) => {
        this.post = res.data;
      });
    }
  }

}
