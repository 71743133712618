import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AllocationLoderComponent } from '../view/allocation-loder/allocation-loder.component';
import { TabSetComponent } from '../view/tab-set/tab-set.component';
import { PaginationComponent } from '../template/pagination/pagination.component';
import { SearchComponent } from '../template/search/search.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedOrderModelComponent } from '../shared-order-model/shared-order-model.component';
import { FormsModule } from '@angular/forms';
import { AppFooterComponent } from '../template/app-footer/app-footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AllocationLoderComponent,
    TabSetComponent,
    PaginationComponent,
    SearchComponent,
    SharedOrderModelComponent,
    AppFooterComponent
  ],
  imports: [CommonModule, HttpClientModule, NgxPaginationModule, FormsModule, RouterModule],
  providers: [],
  exports: [
    AllocationLoderComponent,
    TabSetComponent,
    PaginationComponent,
    SearchComponent,
    SharedOrderModelComponent,

    CommonModule,
    NgxPaginationModule,
    FormsModule,
    AppFooterComponent
  ],
})
export class UIModule { }
