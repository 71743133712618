<div class="main-right-content">
  <app-search [sortText]="getSortText()" [sortOptions]="sortOptions" (searchChanged)="updateSearchTerm($event)"
    (sortChanged)="updateSortField($event)"></app-search>
  <section class="product-detail-container p-3">
    <div class="category-product-list">
      <div class="category-product">
        <div class="category-product-items filterOpen">
          <div class="product-card category-card card bg-transparent p-3" *ngFor="let data of queryResult.rows">
            <div class="card-img">
              <img src="{{
						data.imageUrl || '../../../../assets/images/collection-product.png'
					}}" alt=" collection-product" />
              <div class="badges d-none">
                <span class="badge bg-aqua-25">New</span>
              </div>
            </div>
            <div class="card-content">
              <a class="btn stretched-link p-0 mb-2 title shadow-none" role="button">{{ data.name }}</a>
              <h6 class="brand">{{ data.brand && data.brand.name }}</h6>
              <h6 class="brand">{{ data.code }}</h6>
              <div class="prices d-flex align-items-end gap-25" *ngIf="finalPrice(data)">
                <p class="price">${{ finalPrice(data) }}</p>
              </div>
              <div class="cart-category">
                <a [routerLink]="'/account/explore_products/details/' + data.uri" role="button"
                  class="btn btn-primary w-100">
                  View Product
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="no-results-content" *ngIf="!queryResult.rows.length && isLoad">
      <div class="no-result-img">
        <img src="../../../../assets/images/No-result.png" alt="No-result" />
      </div>
      <h4 class="title">No Results!</h4>
      <p class="description">
        Your search returned no results. Try selecting a different category,
        clearing your search or updating your search terms.
      </p>
    </div>
  </section>

  <app-pagination [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
    (pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>
</div>