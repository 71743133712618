import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { logger } from "../../util/Logger";
import { slideInAnimation } from "../../util/animations";
import { AuthService } from "../../services/auth.service";
import { SubscriptionGroup } from "../../util/subscriptionGroup";
import { SecurityService } from "../../services/security.service";
import { NotificationsService } from "angular2-notifications";
import { combineLatest, filter, first, map } from "rxjs";
import { SessionApi } from "../../api/session.api";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthApi } from "../../api/auth.api";
import { WebSocketService } from "../../services/webSocket.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { VersionService } from "../../services/core/version.service";
import { LoadingService } from "../../services/core/loading.service";

const className = "MainTemplateComponent";

@Component({
	moduleId: module.id,
	animations: [slideInAnimation],
	selector: 'app-main-template',
	templateUrl: 'main-template.component.html',
	styleUrls: []
})
export class MainTemplateComponent implements OnInit, OnDestroy {

	public sessionReady: boolean = false;

	year: string = new Date().getFullYear().toString();
	private readonly subscriptionGroup = new SubscriptionGroup();

	@ViewChild('modalContainer', {
		read: ViewContainerRef
	}) modalContainer: ViewContainerRef;

	@ViewChild('tfa_model') tfa_model;
	modalReference: any;

	existingNotification: any = null;
	notificationShown: boolean = false;
	loading = false;

	constructor(
		private securityService: SecurityService,
		private notifications: NotificationsService,
		private authenticationService: AuthService,
		private session: SessionApi,
		private __modalService: NgbModal,
		private router: Router,
		private authApi: AuthApi,
		private websocketService: WebSocketService,
		private gtmService: GoogleTagManagerService,
		private versionService: VersionService,
		private loaderService: LoadingService,
		private cdr: ChangeDetectorRef
	) {
		this.websocketService.initiateConnection();
		this.versionService.monitorAppVersion();
		this.loaderService.loading$.subscribe((isLoading) => {
			setTimeout(() => {
				this.loading = isLoading;
			});
			this.cdr.markForCheck();
		});
	}

	ngOnInit() {
		this.blurNumberInputOnWheel();

		// This starts the session- The user will see nothing until this is complete
		this.session.init();

		this.session.$sessionChanged.pipe(
			filter(ready => ready)
		).subscribe({
			next: () => {
				this.sessionReady = true;
				if (!this.session.$userData.value?.isAdmin) {
					this.authenticationService.navigateToDefaultUrl();
				}
				this.checkCredit();
				this.checkObsolete();
			}
		});

		this.monitor2faEnabled();

		this.unregisterServiceWorkers();

		this.trackAnalytics();
	}

	trackAnalytics() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const finalURL = event.urlAfterRedirects || event.url;

				const gtmTag = {
					event: 'page',
					pageName: finalURL
				};

				this.gtmService.pushTag(gtmTag);
			}
		});
	}


	unregisterServiceWorkers() {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				//returns installed service workers
				if (registrations.length) {
					for (let registration of registrations) {
						registration.unregister();
					}
				}
			});
		}
	}

	prepareRoute(outlet: RouterOutlet, data: string) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData[data];
	}

	blurNumberInputOnWheel() {
		const signature = className + ".blurNumberInput: ";
		document.addEventListener("wheel", function () {
			if (
				document.activeElement
				&& document.activeElement["type"]
				&& document.activeElement["type"] === "number"
				&& document.activeElement["blur"]
			) {
				logger.silly(signature + "Prevented mWheel in Number Input");
				document.activeElement["blur"]();
			}
		});
	}

	private checkCredit() {
		this.subscriptionGroup.add(
			this.securityService.isStopCredit().subscribe(obsolete => {
				if (obsolete && !this.notificationShown) {
					this.notifications.error("Account on hold", "Your account is currently on stop credit, you cannot place orders at this time.");
					this.notificationShown = true;
				}
			})
		);
	};

	private checkObsolete() {
		this.subscriptionGroup.add(
			this.securityService.isObsolete().subscribe(obsolete => {
				if (obsolete && !this.notificationShown) {
					this.notifications.error("Account on hold", "Your account is currently obsolete, you cannot place orders at this time.");
					this.notificationShown = true;
				}
			})
		);
	};

	/**
 * @description Monitors for a user pass auth request. Recommends enabling 2fa exactly once, if not disabled at the customer level
 */
	monitor2faEnabled() {
		this.subscriptionGroup.add(
			combineLatest(
				this.authenticationService.$userPassAuthRequested,
				this.session.$userData
			)
				.pipe(
					filter(([isUserPassAuth, data]) => {
						return !!isUserPassAuth && !!data;
					}),
					map(([isUserPassAuth, data]) => data),
					filter(data => data?.secureLoginType === 'none' || !data?.secureLoginType),
					first(),
					filter(() => {
						const customerData = this.session.$customerData.value;
						return !customerData || !customerData.disable2faCheck;
					})
				)
				.subscribe(data => {
					this.modalReference = this.__modalService.open(this.tfa_model, { size: 'sm', modalDialogClass: 'modal-dialog-centered', windowClass: 'bulk-order' });
				})
		);
	}

	enable2fa() {
		this.modalReference.close();
		this.authApi.getInformation().subscribe((user) => {
			this.session.$userData.getValue();
			if (user && user.isAdmin) {
				this.router.navigate(['/manage/users/admin/edit', user.id]);
			} else if (user && user.customerUsers) {
				this.router.navigate(['/account/details', user.customerUsers[0].id, 'customer', user.customerUsers[0].customerId]);
			}
		})
	}

	close() {
		this.modalReference.close();
	}

	@HostListener('document:click', ['$event'])
	onClick() {
		if (this.modalReference) {
			this.modalReference.close();
		}
	}

	ngOnDestroy() {
		this.subscriptionGroup.unsubscribe();
	}
}
