import {
	AuthApi
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, filter, map, of, switchMap, tap, throwError } from 'rxjs';
import { IAuthPayload, IForgotPayload, IJWTPayload, IResetPayload } from '../model/auth.model';
import { logger } from '../util/Logger';
import { SessionApi } from '../api/session.api';
import { has } from '../util/object.util';
import { ConversionService } from './conversion.service';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';
import { SSOSession } from './sso.service';

interface auth {
	email: string,
	password: string
}

const className = "AuthService";
@Injectable()
export class AuthService {
	public countries = [
		{ name: 'Australia', iso2: 'AU', dialCode: '61' },
		{ name: 'Austria', iso2: 'AT', dialCode: '43' },
		{ name: 'Afghanistan', iso2: 'AF', dialCode: '93' },
		{ name: 'Albania', iso2: 'AL', dialCode: '355' },
		{ name: 'Algeria', iso2: 'DZ', dialCode: '213' },
		{ name: 'Andorra', iso2: 'AD', dialCode: '376' },
		{ name: 'Angola', iso2: 'AO', dialCode: '244' },
		{ name: 'Argentina', iso2: 'AR', dialCode: '54' },
		{ name: 'Armenia', iso2: 'AM', dialCode: '374' },
		{ name: 'Azerbaijan', iso2: 'AZ', dialCode: '994' },
		{ name: 'Bahamas', iso2: 'BS', dialCode: '1-242' },
		{ name: 'Bahrain', iso2: 'BH', dialCode: '973' },
		{ name: 'Bangladesh', iso2: 'BD', dialCode: '880' },
		{ name: 'Belarus', iso2: 'BY', dialCode: '375' },
		{ name: 'Belgium', iso2: 'BE', dialCode: '32' },
		{ name: 'Belize', iso2: 'BZ', dialCode: '501' },
		{ name: 'Benin', iso2: 'BJ', dialCode: '229' },
		{ name: 'Bhutan', iso2: 'BT', dialCode: '975' },
		{ name: 'Bolivia', iso2: 'BO', dialCode: '591' },
		{ name: 'Bosnia and Herzegovina', iso2: 'BA', dialCode: '387' },
		{ name: 'Botswana', iso2: 'BW', dialCode: '267' },
		{ name: 'Brazil', iso2: 'BR', dialCode: '55' },
		{ name: 'Brunei', iso2: 'BN', dialCode: '673' },
		{ name: 'Bulgaria', iso2: 'BG', dialCode: '359' },
		{ name: 'Burkina Faso', iso2: 'BF', dialCode: '226' },
		{ name: 'Burundi', iso2: 'BI', dialCode: '257' },
		{ name: 'Cambodia', iso2: 'KH', dialCode: '855' },
		{ name: 'Cameroon', iso2: 'CM', dialCode: '237' },
		{ name: 'Canada', iso2: 'CA', dialCode: '1' },
		{ name: 'Cape Verde', iso2: 'CV', dialCode: '238' },
		{ name: 'Chad', iso2: 'TD', dialCode: '235' },
		{ name: 'Chile', iso2: 'CL', dialCode: '56' },
		{ name: 'China', iso2: 'CN', dialCode: '86' },
		{ name: 'Colombia', iso2: 'CO', dialCode: '57' },
		{ name: 'Comoros', iso2: 'KM', dialCode: '269' },
		{ name: 'Costa Rica', iso2: 'CR', dialCode: '506' },
		{ name: 'Croatia', iso2: 'HR', dialCode: '385' },
		{ name: 'Cuba', iso2: 'CU', dialCode: '53' },
		{ name: 'Cyprus', iso2: 'CY', dialCode: '357' },
		{ name: 'Czech Republic', iso2: 'CZ', dialCode: '420' },
		{ name: 'Denmark', iso2: 'DK', dialCode: '45' },
		{ name: 'Djibouti', iso2: 'DJ', dialCode: '253' },
		{ name: 'Dominica', iso2: 'DM', dialCode: '1-767' },
		{ name: 'Dominican Republic', iso2: 'DO', dialCode: '1-809' },
		{ name: 'Ecuador', iso2: 'EC', dialCode: '593' },
		{ name: 'Egypt', iso2: 'EG', dialCode: '20' },
		{ name: 'El Salvador', iso2: 'SV', dialCode: '503' },
		{ name: 'Estonia', iso2: 'EE', dialCode: '372' },
		{ name: 'Eswatini', iso2: 'SZ', dialCode: '268' },
		{ name: 'Ethiopia', iso2: 'ET', dialCode: '251' },
		{ name: 'Fiji', iso2: 'FJ', dialCode: '679' },
		{ name: 'Finland', iso2: 'FI', dialCode: '358' },
		{ name: 'France', iso2: 'FR', dialCode: '33' },
		{ name: 'Gabon', iso2: 'GA', dialCode: '241' },
		{ name: 'Gambia', iso2: 'GM', dialCode: '220' },
		{ name: 'Georgia', iso2: 'GE', dialCode: '995' },
		{ name: 'Germany', iso2: 'DE', dialCode: '49' },
		{ name: 'Ghana', iso2: 'GH', dialCode: '233' },
		{ name: 'Greece', iso2: 'GR', dialCode: '30' },
		{ name: 'Guatemala', iso2: 'GT', dialCode: '502' },
		{ name: 'Honduras', iso2: 'HN', dialCode: '504' },
		{ name: 'Hungary', iso2: 'HU', dialCode: '36' },
		{ name: 'Iceland', iso2: 'IS', dialCode: '354' },
		{ name: 'India', iso2: 'IN', dialCode: '91' },
		{ name: 'Indonesia', iso2: 'ID', dialCode: '62' },
		{ name: 'Iran', iso2: 'IR', dialCode: '98' },
		{ name: 'Iraq', iso2: 'IQ', dialCode: '964' },
		{ name: 'Ireland', iso2: 'IE', dialCode: '353' },
		{ name: 'Israel', iso2: 'IL', dialCode: '972' },
		{ name: 'Italy', iso2: 'IT', dialCode: '39' },
		{ name: 'Jamaica', iso2: 'JM', dialCode: '1-876' },
		{ name: 'Japan', iso2: 'JP', dialCode: '81' },
		{ name: 'Kenya', iso2: 'KE', dialCode: '254' },
		{ name: 'Kuwait', iso2: 'KW', dialCode: '965' },
		{ name: 'Malaysia', iso2: 'MY', dialCode: '60' },
		{ name: 'Maldives', iso2: 'MV', dialCode: '960' },
		{ name: 'Mexico', iso2: 'MX', dialCode: '52' },
		{ name: 'Morocco', iso2: 'MA', dialCode: '212' },
		{ name: 'Nepal', iso2: 'NP', dialCode: '977' },
		{ name: 'Netherlands', iso2: 'NL', dialCode: '31' },
		{ name: 'New Zealand', iso2: 'NZ', dialCode: '64' },
		{ name: 'Nigeria', iso2: 'NG', dialCode: '234' },
		{ name: 'Norway', iso2: 'NO', dialCode: '47' },
		{ name: 'Pakistan', iso2: 'PK', dialCode: '92' },
		{ name: 'Philippines', iso2: 'PH', dialCode: '63' },
		{ name: 'Portugal', iso2: 'PT', dialCode: '351' },
		{ name: 'Qatar', iso2: 'QA', dialCode: '974' },
		{ name: 'Russia', iso2: 'RU', dialCode: '7' },
		{ name: 'Saudi Arabia', iso2: 'SA', dialCode: '966' },
		{ name: 'Singapore', iso2: 'SG', dialCode: '65' },
		{ name: 'South Africa', iso2: 'ZA', dialCode: '27' },
		{ name: 'Spain', iso2: 'ES', dialCode: '34' },
		{ name: 'Sri Lanka', iso2: 'LK', dialCode: '94' },
		{ name: 'Sweden', iso2: 'SE', dialCode: '46' },
		{ name: 'Switzerland', iso2: 'CH', dialCode: '41' },
		{ name: 'Thailand', iso2: 'TH', dialCode: '66' },
		{ name: 'Turkey', iso2: 'TR', dialCode: '90' },
		{ name: 'Ukraine', iso2: 'UA', dialCode: '380' },
		{ name: 'United Arab Emirates', iso2: 'AE', dialCode: '971' },
		{ name: 'United Kingdom', iso2: 'GB', dialCode: '44' },
		{ name: 'United States', iso2: 'US', dialCode: '1' },
		{ name: 'Vietnam', iso2: 'VN', dialCode: '84' },
		{ name: 'Yemen', iso2: 'YE', dialCode: '967' },
	];

	public $userPassAuthRequested = new BehaviorSubject<boolean>(false);
	private stickyMenuSubject = new BehaviorSubject<string>('');
	private accountMenuSubject = new BehaviorSubject<string>('');
	constructor(
		private authApi: AuthApi,
		private jwtService: JwtService,
		public notifications: NotificationsService,
		private readonly session: SessionApi,
		private readonly conversionService: ConversionService,
		public router: Router,
		private securityService: SecurityService
	) {
		this.moniterAuthType();
	}

	/**
 * @description Guarantees that every time the user data is cleared, the authentication type is also cleared
 */
	moniterAuthType() {
		this.session.$userData
			.pipe(
				filter(data => !data)
			)
			.subscribe({
				next: () => {
					this.$userPassAuthRequested.next(false);
				}
			});
	}


	/**
	 * @description Refreshes the authentication token by making a request to the server using the provided JWT strings.
	 * @param {string} jwtString - The access token JWT string.
	 * @param {string} jwtRefreshString - The refresh token JWT string.
	 * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
	 * @public
	 * @example
	 * ```
	 * const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Access token JWT string
	 * const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Refresh token JWT string
	 *
	 * auth.refreshToken(accessToken, refreshToken)
	 *   .subscribe(jwtPayload => {
	 *     console.log(jwtPayload); // The refreshed JWT payload
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */
	public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
		return apiCallWrapper(
			this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
			{
				notificationsService: this.notifications,
				action: "Obtaining new access keys"
			}
		)
	}

	/**
	 * @description Resets the user's password by making a request to the server using the provided reset payload.
	 * @param {IResetPayload} opts - The payload object for the password reset request.
	 * @returns {Observable<any>} - An observable that emits the response from the password reset request.
	 * @public
	 * @example
	 * ```
	 * const resetPayload = {
	 *   email: 'john@example.com',
	 *   newPassword: 'newPassword123'
	 * };
	 *
	 * auth.reset(resetPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the password reset request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */
	public reset = (opts: IResetPayload) => {
		return apiCallWrapper(
			this.authApi.reset(opts),
			{
				notificationsService: this.notifications,
				action: "Changing password"
			}
		);
	}

	/**
	 * @description Authenticates the user by making a request to the server using the provided authentication payload.
	 * @param {IAuthPayload} opts - The payload object for the authentication request.
	 * @returns {Observable<any>} - An observable that emits the response from the authentication request.
	 * @public
	 * @example
	 * ```
	 * const authPayload = {
	 *   username: 'john@example.com',
	 *   password: 'password123'
	 * };
	 *
	 * auth.authenticate(authPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the authentication request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */

	public authenticate = (opts: IAuthPayload) => {
		return this.authApi.authenticate(opts)
			.pipe(
				tap(() => this.$userPassAuthRequested.next(true))
			)
	}

	public sso = (opts: SSOSession) => {
		return this.authApi.sso(opts)
	}

	/**
	 * @description Sends a forgot password request by making a request to the server using the provided forgot payload.
	 * @param {IForgotPayload} opts - The payload object for the forgot password request.
	 * @returns {Observable<any>} - An observable that emits the response from the forgot password request.
	 * @public
	 * @example
	 * ```
	 * const forgotPayload = {
	 *   email: 'john@example.com'
	 * };
	 *
	 * auth.forgot(forgotPayload)
	 *   .subscribe(response => {
	 *     console.log(response); // The response from the forgot password request
	 *   }, error => {
	 *     console.error(error); // Handle error
	 *   });
	 * ```
	 */

	public forgot = (opts: IForgotPayload) => {
		return apiCallWrapper(
			this.authApi.forgot(opts),
			{
				notificationsService: this.notifications,
				action: "Reset Password",
				failTitle: "Reset Failed",
				successTitle: "Reset Complete",
				successMessage: "Instructions were sent to your email address"
			}
		)
	}

	/**
	 * @description Checks if the user is authenticated by evaluating the current JWT payload.
	 * @returns {boolean} - A boolean value indicating whether the user is authenticated or not.
	 * @public
	 * @example
	 * ```
	 * const authenticated = auth.isAuthenticated();
	 * console.log(authenticated); // true or false
	 * ```
	 */
	public isAuthenticated = () => this.session.$userData.getValue() !== null;

	public logOut = () => {
		return this.authApi.logOut();
	}

	/**
 * Uses business rules to determine where the user should be sent in a "default" scenario
 */
	readonly navigateToDefaultUrl = async (): Promise<any> => {
		const signature = "UserLoginService.navigateToCustomerUrl: ";

		let route: string | undefined = '/manage/dashboard';

		const customer = this.session.$customerData.getValue();

		if (customer && customer.defaultUri) {
			route = customer.defaultUri;
		}

		this.securityService.hasProductAccess().subscribe(hasProductAccess => {
			if (!hasProductAccess && route && route.match(/^\/products/i)) {
				route = '/manage/dashboard';
			}

			logger.debug(signature + `Navigating to ${route}`);

			if (this.isAuthenticated()) {
				return this.router.navigate([route]);
			}
		});
	}

	switchUser(customerId: number, userId: number) {
		const signature = "AuthService" + '.switchUser: ';
		return this.performAuthenticationRequest(this.authApi.switch(customerId, userId))
			.pipe(
				tap(() => logger.silly(signature + `Switched into Customer[${customerId}] and User[${userId}]`)),
				map(() => true)
			);
	}

	/** Common authentication and post authentication behaviours */
	public performAuthenticationRequest(observable: Observable<IJWTPayload>) {
		return observable
			.pipe(
				tap(payload => this.persistJwtPayload(payload)),
			)
	}

	private persistJwtPayload(payload: IJWTPayload): Observable<void> {
		const signature = className + ".persistJwtPayload: ";
		if (!(this.jwtService.saveJWTData(payload))) {
			logger.info(signature + `Clearing JWT Data`);
			this.jwtService.removeJWTData();

			return throwError("Error Saving JWT Payload");
		}

		logger.silly(signature + `Persisted JWT Data`);
		return of();
	}

	/** Remove user emulation and switch back to self */
	unswitchUser() {
		return this.performAuthenticationRequest(
			this.authApi.unswitch());
	}

	public register = (firstName: string, lastName: string, email: string, secret: string) => {
		return apiCallWrapper(
			this.authApi.register(firstName, lastName, email, secret),
			{
				notificationsService: this.notifications,
				action: "Authenticating"
			}
		)
	}

	public accountCreate = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string }) => {
		return this.authApi.accountCreate(opts);
	}

	public contactUs = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string, generalDesc: string }) => {
		return this.authApi.contactUs(opts);
	}

	setStickyMenu(menuType: string): void {
		this.stickyMenuSubject.next(menuType);
	}

	getStickyMenu(): Observable<string> {
		return this.stickyMenuSubject.asObservable();
	}

	setAccountMenu(menuType: string): void {
		this.accountMenuSubject.next(menuType);
	}

	getAccountMenu(): Observable<string> {
		return this.accountMenuSubject.asObservable();
	}

	readonly acceptGuestInvite = (email: string, token: string) => {
		return this.authApi.acceptGuestInvite(email, token);
	};

	readonly denyGuestInvite = (email: string, token: string) => {
		return this.authApi.denyGuestInvite(email, token);
	};
}
