<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<div class="backs d-flex flex-column flex-lg-row align-items-lg-center justify-content-start gap-4">
				<a [routerLink]="['/products']" class="btn btn-back p-0 gap-2 gap-2" role="button">
					<span class="">
						<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
							<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Back to All Products
				</a>

				<h1 class="quick-main-title d-flex d-lg-none">Quick order</h1>
				<!-- product list tab start here... -->
				<div class="manageTab mb-0 mx-lg-auto">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item" role="presentation" *ngFor="let data of queryResult"
							[class.active]="activeId == data.id" (click)="setActive(data.id)">
							<button class="nav-link" [class.active]="activeId == data.id" id="pills-Shirts-tab" data-bs-toggle="pill"
								data-bs-target="#pills-Shirts" type="button" role="tab" aria-controls="pills-Shirts"
								aria-selected="true">
								{{ data.name.trim().substring(0, 25) }}
							</button>
						</li>
					</ul>
				</div>
				<!-- product list tab end here... -->
			</div>

			<div class="product-list-content">
				<div class="cards-product-list">
					<h1 class="main-title d-none d-lg-flex">Quick order</h1>
					<div class="tab-content" id="pills-tabContent">
						<div class="tab-pane fade show active" id="pills-Shirts" role="tabpanel" aria-labelledby="pills-Shirts-tab"
							tabindex="0">
							<div *ngIf="filteredResult">
								<div
									class="d-flex flex-column flex-md-row gap-3 align-items-start align-items-md-center justify-content-between mb-45">
									<h4 class="title">
										{{ filteredResult.name.trim().substring(0, 25) }}
									</h4>
									<div class="main-shadow selected-product">
										<p>
											You have
											{{ filteredResult.allocationAvailable }} garment{{
											filteredResult.allocationAvailable > 1 ? "s" : ""
											}}
											remaining in this collection
										</p>
									</div>
								</div>
								<div class="max-h">
									<div class="products mb-45">
										<app-quick-order-list-item class="product-card card bg-transparent"
											*ngFor="let product of filteredResult.products" [data]="product"
											(addToCartClicked)="handleAddToCartClicked()">
										</app-quick-order-list-item>
									</div>
								</div>
							</div>
							
							<div class="no-results-content"  *ngIf="!filteredResult && !isLoading">
								<div class="no-result-img">
									<img src="../../../../assets/images/No-result.png" alt="No-result" />
								</div>
								<h4 class="title">No Results!</h4>
								<p class="description">
									Your search returned no results. Try selecting a different category,
									clearing your search or updating your search terms.
								</p>
							</div>
							
							<div class="d-flex align-items-center justify-content-center d-none">
								<button class="btn btn-secondary w-sm-100 w-auto" type="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</span>
									Show more
								</button>
							</div>
						</div>
					</div>
				</div>

				<!-- =========================================================== -->
				<!-- == Quick order Added product for lg screen start here... == -->
				<!-- =========================================================== -->
				<div class="added-product-content d-none d-lg-block">
					<div class="card edit-card">
						<div class="accordion" id="addedCollapse">
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#addedItem"
										aria-expanded="true" aria-controls="addedItem">
										Added products
										<span>{{ existingCartItems.length }}</span>
									</button>
								</h2>
								<div id="addedItem" class="accordion-collapse collapse show" data-bs-parent="#addedCollapse">
									<div class="accordion-body">
										<div class="added-product-list">
											<div class="add-product" *ngFor="
													let item of existingCartItems;
													let lastItem = last
												">
												<div class="card-img">
													<img [src]="item.variation.imageUrl" alt="collection-product" />
												</div>
												<div class="content">
													<h4 class="title">
														{{ item.product.name }}
													</h4>
													<p class="price">
														${{
														utils.twoDecimalPlacesString(getUnitPrice(item))
														}}
													</p>
													<div class="featured">
														<p>
															<span class="color" [style.background]="
																	generateGradient(item.variation.colour)
																"></span>
															{{ item.variation.colour || "default" }}
														</p>
														<p>{{ item.variation.size || "default" }}</p>
														<p>{{ item.quantity }}</p>
													</div>
												</div>
											</div>
											<p *ngIf="existingCartItems.length == 0">No items yet</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card balance-card">
						<div class="card-body">
							<h4 class="card-title d-flex align-items-center gap-25">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
										<path
											d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
											stroke="#C1C6D5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
								</span>
								Balance
							</h4>
							<p class="desc">
								You have {{ avlGarment }} garments remaining in this collection
							</p>
							<p class="desc muted">
								You have saved your details and delivery address, so you can use
								the quick order function.
							</p>
							<button class="btn btn-secondary w-100" type="button" *ngIf="allowCheckout" (click)="checkout()">
								Go to Checkout
							</button>
							<div class="d-flex align-items-center gap-25">
								<div class="radialProgressBar progress-{{ completionPercentage }}">
									<div class="overlay">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
											<path
												d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
												stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</div>
								</div>
								<p class="remain">
									Garment {{ avlGarment }}/{{ totalGarment }}
								</p>
							</div>
							<hr />
							<div class="accordion" id="addressCollapse">
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button shadow-none" type="button" data-bs-toggle="collapse"
											data-bs-target="#addressCollapse1" aria-expanded="false" aria-controls="addressCollapse1">
											Address details
										</button>
									</h2>
									<div id="addressCollapse1" class="accordion-collapse collapse" data-bs-parent="#addressCollapse">
										<div class="accordion-body text-white" *ngIf="
												userShippingDetails &&
												userShippingDetails.addressName &&
												userShippingDetails.streetAddress
											">
											{{ addressDisplayText(userShippingDetails, true) }}
										</div>
										<div class="accordion-body text-white" *ngIf="!userShippingDetails">
											No Address Found
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- ========================================================= -->
				<!-- == Quick order Added product for lg screen end here... == -->
				<!-- ========================================================= -->

				<!-- =========================================================== -->
				<!-- == Quick order Added product for md screen start here... == -->
				<!-- =========================================================== -->
				<div class="quick-collapse-bg">
					<div class="quick-order-md-collpase d-lg-none">
						<button class="btn btn-collapse" type="button" data-bs-toggle="collapse"
							data-bs-target="#addesProductCollapse" aria-expanded="false" aria-controls="addesProductCollapse">
							<h4>
								Added products
								<span class="badge bg-alpha-25 py-2 px-3">{{
									existingCartItems.length
									}}</span>
							</h4>
							<div class="d-flex align-items-center gap-25">
								<div class="radialProgressBar progress-{{ completionPercentage }}">
									<div class="overlay">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
											<path
												d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
												stroke="#36394A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</div>
								</div>

								<span class="main-shadow d-flex align-items-center justify-content-center rounded-circle"><svg
										xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#666D80" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg></span>
							</div>
						</button>

						<div class="collapse added-collapse" id="addesProductCollapse">
							<div class="added-product-list main-shadow">
								<div class="add-product" *ngFor="let item of existingCartItems; let lastItem = last">
									<div class="card-img">
										<img [src]="item.variation.imageUrl" alt="collection-product" />
									</div>
									<div class="content">
										<h4 class="title">
											{{ item.product.name }}
										</h4>
										<p class="price">
											${{ utils.twoDecimalPlacesString(getUnitPrice(item)) }}
										</p>
										<div class="featured">
											<p>
												<span class="color" [style.background]="
														generateGradient(item.variation.colour)
													"></span>
												{{ item.variation.colour || "default" }}
											</p>
											<p>{{ item.variation.size || "default" }}</p>
											<p>{{ item.quantity }}</p>
										</div>
									</div>
								</div>
								<p *ngIf="existingCartItems.length == 0">No items yet</p>
							</div>
							<div class="card balance-card">
								<div class="card-body">
									<h4 class="card-title d-flex align-items-center gap-25">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
												<path
													d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
													stroke="#C1C6D5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
										Balance
									</h4>
									<p class="desc">
										You have {{ avlGarment }} garments remaining in this
										collection
									</p>
									<p class="desc muted">
										You have saved your details and delivery address, so you can
										use the quick order function.
									</p>
									<button class="btn btn-secondary w-100" type="button" [routerLink]="['/cart']">
										Go to Checkout
									</button>
									<div class="d-flex align-items-center gap-25">
										<div class="radialProgressBar progress-{{
												completionPercentage
											}}">
											<div class="overlay">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
													<path
														d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
														stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												</svg>
											</div>
										</div>
										<p class="remain">
											Garment {{ avlGarment }}/{{ totalGarment }}
										</p>
									</div>
									<hr />
									<div class="accordion" id="addressCollapse">
										<div class="accordion-item">
											<h2 class="accordion-header">
												<button class="accordion-button shadow-none" type="button" data-bs-toggle="collapse"
													data-bs-target="#addedAddress" aria-expanded="false" aria-controls="addedAddress">
													Address details
												</button>
											</h2>
											<div id="addedAddress" class="accordion-collapse collapse" data-bs-parent="#addressCollapse">
												<div class="accordion-body text-white" *ngIf="
														userShippingDetails &&
														userShippingDetails.addressName &&
														userShippingDetails.streetAddress
													">
													{{ addressDisplayText(userShippingDetails, true) }}
												</div>
												<div class="accordion-body text-white" *ngIf="!userShippingDetails">
													No Address Found
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- =========================================================== -->
				<!-- == Quick order Added product for md screen end here... == -->
				<!-- =========================================================== -->
			</div>
		</div>
	</div>
</section>