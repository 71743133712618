import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IAllocationLog } from 'src/app/main/model/allocationLog.model';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { IAuthDataResponse } from 'src/app/main/model/session.model';
import { AllocationLogService } from 'src/app/main/services/allocationLog.service';

@Component({
  selector: 'app-allocation-logs',
  templateUrl: './allocation-logs.component.html',
  styleUrls: []
})
export class AllocationLogsComponent implements OnInit {
  public userName: string | null;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  public tab: string | null;
  isAdmin: boolean = false;
  public userId: number;
  public customerId: number;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
  });
  public isLoading = false;

  sortOptions = [{
    id: 1,
    text: "Log Id",
    field: "id"
  }];

  queryResult: QueryResult<IAllocationLog> = new QueryResult();
  private searchTerms: Subject<string> = new Subject<string>();
  isAlreadyChecked: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly allocationLogService: AllocationLogService,
    private readonly session: SessionApi,
    private router: Router
  ) {
    this.userName = this.route.snapshot.queryParamMap.get('userName');
    this.tab = this.route.snapshot.queryParamMap.get('tab');
    this.isAdmin = !!this.session.$userData.getValue()?.isAdmin;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // TODO: Throw if a null value is found and return out
      this.query.filter.userAllocationId = params.userAllocationId;
      if (params.id) {
        this.userId = params.id;
      }

      if (params.customerId) {
        this.customerId = params.customerId;
      }
      // this.loadQueryResult();
      this.search();
    });
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadQueryResult()
  }

  /**
 * @description Ensures the page number is in sync across multiple pagination components
 *
 * @param {number} pageSize Broadcast pageSize value
 */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.loadQueryResult();
  }

  loadQueryResult() {
    this.isLoading = true;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.allocationLogService.list(this.query)
      .subscribe(queryResult => {
        this.totalCountActive = queryResult.count;
        this.queryResult = queryResult;
        this.isLoading = false;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadQueryResult();
        }
      });
  }

  public navigateToUserEdit(): void {
    const currentUrlSegments = this.route.snapshot.url.map(segment => segment.path);
    const editIndex = currentUrlSegments.indexOf('edit');

    if (editIndex !== -1 && this.tab) {
      const baseUrl = 'manage/users/customers/edit/';
      const editSegment = currentUrlSegments[editIndex + 1];
      const navigateOptions = {
        queryParams: { activeTab: this.tab === 'user' ? 1 : 2 }
      };

      const finalUrl = `${baseUrl}${editSegment}`;
      this.router.navigate([finalUrl], this.tab ? navigateOptions : undefined);
    } else {
      this.router.navigate(this.isAdmin ? ['/manage/users'] : ['/account/users']);
    }
  }

  public navigateToUserAllocation() {
    const currentUrlSegments = this.route.snapshot.url.map(segment => segment.path);
    const editIndex = currentUrlSegments.indexOf('edit');
    const baseUrl = `manage/users/edit/${this.customerId}/user/${this.userId}`;
    const userBaseUrl = `account/users/edit/${this.customerId}/user/${this.userId}`
    if (editIndex !== -1 && this.tab) {
      const editSegment = currentUrlSegments[editIndex + 3];
      const navigateOptions = {
        queryParams: { activeTab: 1, userName: this.userName, tab: this.tab }
      };
      const finalUrl = `${baseUrl}`;
      this.router.navigate([finalUrl], this.tab ? navigateOptions : undefined);
    } else {
      this.router.navigate(this.isAdmin ? [baseUrl] : [userBaseUrl], { queryParams: { activeTab: 1, userName: this.userName } });
    }
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter['$or'] = {
          message: { $like: '%' + searchTerm + '%' },
          id: { $like: '%' + searchTerm + '%' }
        }
      else
        delete this.query.filter['$or'];
      this.loadQueryResult();
    });
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadQueryResult();
  }
}
