<!-- ============================ -->
<!-- Transforming section start here... -->
<!-- ============================ -->
<section class="transforming">
  <div class="max-width-container rounded-5">
    <div class="transback embroidered container">
      <div class="contents">
        <div class="items">
          <h1 class="fw-normal">
            Premium Embroidered Workwear & Uniforms in
            <span class="text-warning">Perth</span>
          </h1>
          <p class="text-secondary">
            Custom-Branded Uniforms with In-House Embroidery for Businesses in Perth. High-
            Quality Embroidered Uniforms – Built for Durability & Comfort
          </p>
          <div class="button-grps">
            <button class="btn btn-primary" [routerLink]="['/embroidered']" fragment="getAQuote">
              Get a Free Quote
            </button>
          </div>
        </div>
        <div class="items position-relative">
          <img src="../../../../assets/images/banner2.png" alt="" />
          <div class="position-absolute allocation-box bg-white d-none">
            <div class="alocbox">
              <a href="javascript:void(0);" class="pe-none">
                <img src="../../../../assets/images/pcard.svg" alt="" />
              </a>
              <span>
                <div class="fs-6">Allocation</div>
                <div class="fs-6">4 garments</div>
              </span>
            </div>
          </div>
          <!-- <a href="javascript:void(0);"
            class="d-flex align-items-center justify-content-center bg-white rounded-circle position-absolute cart-box pe-none">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75ZM7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
                stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </a> -->
          <div class="hpba position-absolute d-none">
            <button class="btn btn-primary pe-none">Add to cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ============================ -->
<!-- Transforming section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- Durability section start here... -->
<!-- ============================ -->
<section class="comfort-durable">
  <div class="max-width-container rounded-5">
    <div class="comfort container">
      <div class="head text-center">
        <h2>
          High-Quality Embroidered Uniforms – Built
          for <span class="text-warning">Durability & Comfort</span>
          Embroidered 
        </h2>
        <p>
          At Reali, we specialise in providing custom embroidered workwear tailored for businesses in Perth. With our
          in-house embroidery and printing services, we ensure precision, durability, and quick turnaround times.
          Whether you need construction, mining, corporate, health or hospitality uniforms, we’ve got you covered.
        </p>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="comfort-card card border-0">
            <div class="card-body">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">In-House Embroidery</h4>
              <p class="fw-normal">
                Faster production, better quality control
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="comfort-card card border-0">
            <div class="card-body">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">In-House Printing</h4>
              <p class="fw-normal">
                Direct to garment, As many colors as you like
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="comfort-card card border-0">
            <div class="card-body">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Order uniforms online</h4>
              <p class="fw-normal">
                Manage employee uniform allocations
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="comfort-card card border-0">
            <div class="card-body">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Fast Turnaround</h4>
              <p class="fw-normal">
                Servicing Perth with quick delivery
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ============================ -->
<!-- Durability section end here... -->
<!-- ============================ -->

<!-- ========================================= -->
<!-- Embroidered uniform section start here... -->
<!-- ========================================= -->
<section class="embroidered-uniform">
  <div class="max-width-container">
    <div class="head text-center mb-5 pb-4">
      <h2>
        Why Choose Reali for Embroidered
        <br class="d-none d-md-block">
        <span class="text-warning">Uniforms in Perth?</span>
      </h2>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-12 col-lg-5">
          <h3>Complete In-House Decoration Services</h3>
          <p class="text">
            We don’t outsource! Our in-house embroidery and printing ensures
            superior quality control, fast production, and cost-effective pricing.
            We also offer:
          </p>
          <ul class="list-unstyled">
            <li>
              <p class="d-flex align-items-center justify-content-start gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path
                    d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                    stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Direct-to-Garment Printing
              </p>
            </li>
            <li>
              <p class="d-flex align-items-center justify-content-start gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path
                    d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                    stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Pad Printing on Hard Hats & Promo Items
              </p>
            </li>
            <li>
              <p class="d-flex align-items-center justify-content-start gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path
                    d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                    stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Unlimited colours per print at no additional cost.
              </p>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-6">
          <div class="embroid-img">
            <!-- <img src="../../../../assets/images/embroidered-uniform.png" alt="embroidered-uniform" /> -->
          </div>
        </div>
      </div>

      <div class="pt-5 pb-4">
        <h3>Some of the areas in Perth & Western
          <br>
          Australia we currently service
        </h3>
        <p class="text">
          We partner with local and national couriers to ensure your order is
          <br>
          delivered when you need it.
        </p>
      </div>

      <div class="row row-gap-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Perth</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Rockingham</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Tom Price</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Kalgoorlie</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Karratha</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="comfort-card card border-0">
            <div class="card-body d-flex align-items-center justify-cotent-start gap-4">
              <span class="d-inline-flex align-items-center justify-content-center rounded-circle me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                    stroke="#184E44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="#184E44" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <h4 class="mb-2">Newman</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- Embroidered uniform section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- Workwear Products section end here... -->
<!-- ========================================= -->
<section class="workwear-product">
  <div class="max-width-container">
    <div class="head text-center mb-5">
      <h2>
        Workwear Products to cover all
        <br>
        your Uniform needs
      </h2>
      <p>
        With access to some of Australias most popular workwear brands, we have your team covered
      </p>
    </div>
    <div class="container">
      <div class="workwear-cards">
        <div class="row row-gap-4">
          <div class="col-12 col-lg-6">
            <div class="workwear-card card active">
              <img class="" src="../../../../assets/images/workwear1.png" alt="workwear">
              <p class="big-p text-center">
                Workwear
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row row-gap-4">
              <div class="col-12">
                <div class="workwear-card card">
                  <img class="" src="../../../../assets/images/workwear2.png" alt="workwear">
                  <p class="big-p text-center">
                    Corporate Wear
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="workwear-card card">
                  <img class="" src="../../../../assets/images/workwear3.png" alt="workwear">
                  <p class="text-center">
                    Safety Boots
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="workwear-card card">
                  <img class="" src="../../../../assets/images/workwear4.png" alt="workwear">
                  <p class="text-center">
                    Scrubs
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="workwear-card card">
              <img class="" src="../../../../assets/images/workwear5.png" alt="workwear">
              <p class="big-p text-center">
                PPE
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- Workwear Products section end here... -->
<!-- ========================================= -->

<!-- ============================ -->
<!-- weKnow section start here... -->
<!-- ============================ -->
<section class="weKnow embroied">
  <div class="max-width-container bg-transparent">
    <div class="container">
      <h2 class="text-white text-center">Industries We Service in <span>Perth</span></h2>
      <div class="contents">
        <div class="items shild">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 9V2H18V9" fill="url(#paint0_radial_3509_289096)" fill-opacity="0.5" />
              <path
                d="M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18"
                fill="url(#paint1_radial_3509_289096)" fill-opacity="0.5" />
              <path d="M18 14H6V22H18V14Z" fill="url(#paint2_radial_3509_289096)" fill-opacity="0.5" />
              <path
                d="M18.0986 13.4049C17.1595 14.3438 15.3926 14.3073 12.6836 14.3073C10.9406 14.3073 9.52746 12.892 9.52656 11.1491C9.52656 8.4418 9.49005 6.67393 10.4291 5.73505C11.3681 4.79619 11.7829 4.8342 15.9426 4.8342C16.3446 4.83248 16.5469 5.31862 16.2627 5.60287L14.1209 7.74481C13.5773 8.2885 13.5758 9.16993 14.1195 9.71354C14.6632 10.2572 15.5447 10.2572 16.0885 9.71367L18.2308 7.57225C18.5151 7.28808 19.0013 7.49037 18.9996 7.89229C18.9996 12.0513 19.0376 12.466 18.0986 13.4049Z"
                stroke="white" />
              <path
                d="M12.684 14.3063L7.81182 19.1785C6.93982 20.0504 5.52601 20.0504 4.65401 19.1785C3.782 18.3064 3.782 16.8926 4.65401 16.0206L9.52618 11.1484"
                stroke="white" stroke-linecap="round" />
              <path d="M6.37524 17.4648H6.36816" stroke="white" stroke-width="1.57891" stroke-linecap="round"
                stroke-linejoin="round" />
              <defs>
                <radialGradient id="paint0_radial_3509_289096" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(12 3) rotate(90) scale(14.5)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint1_radial_3509_289096" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(12 3) rotate(90) scale(14.5)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint2_radial_3509_289096" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(12 3) rotate(90) scale(14.5)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </span>
          <div class="">
            <h4 class="text-white py-2">Construction & Trades</h4>
            <p class="text-white">
              Durable, high-visibility workwear with embroidery
            </p>
          </div>
        </div>
        <div class="items shild">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="url(#paint0_radial_3509_289105)" fill-opacity="0.5" />
              <path
                d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2Z"
                fill="url(#paint1_radial_3509_289105)" fill-opacity="0.5" />
              <path
                d="M22 12C22 17.5228 17.5228 22 12 22M22 12C22 6.47715 17.5228 2 12 2M22 12H2M12 22C6.47715 22 2 17.5228 2 12M12 22C14.5013 19.2616 15.9228 15.708 16 12C15.9228 8.29203 14.5013 4.73835 12 2M12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2M2 12C2 6.47715 6.47715 2 12 2"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <defs>
                <radialGradient id="paint0_radial_3509_289105" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(12 3) rotate(90) scale(14.5)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint1_radial_3509_289105" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(12 3) rotate(90) scale(14.5)">
                  <stop stop-color="#8BD6EF" />
                  <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </span>
          <div class="">
            <h4 class="text-white py-2">Mining & Industrial</h4>
            <p class="text-white">
              Heavy-duty uniforms for tough environments
            </p>
          </div>
        </div>
        <div class="items shild">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <div class="">
            <h4 class="text-white py-2">Corporate & Office Wear</h4>
            <p class="text-white">
              Stylish branding for professional teams
            </p>
          </div>
        </div>
        <div class="items shild">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M2 12.5625C2 9.46891 2 7.92211 3.02513 6.96106C4.05025 6 5.70017 6 9 6H15C18.2998 6 19.9497 6 20.9749 6.96106C22 7.92211 22 9.46891 22 12.5625V14.4375C22 17.5311 22 19.0779 20.9749 20.0389C19.9497 21 18.2998 21 15 21H9C5.70017 21 4.05025 21 3.02513 20.0389C2 19.0779 2 17.5311 2 14.4375V12.5625Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 13.5H15M12 10.5V16.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M17 6C17 3.518 16.482 3 14 3H10C7.518 3 7 3.518 7 6" stroke="white" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <div class="">
            <h4 class="text-white py-2">Hospitality & Retail</h4>
            <p class="text-white">
              High-quality branded uniforms for customer-facing teams
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ============================ -->
<!-- weKnow section end here... -->
<!-- ============================ -->

<!-- ========================================= -->
<!-- Embroidered uniform section start here... -->
<!-- ========================================= -->
<section class="embroidered-uniform">
  <div class="max-width-container">
    <div class="head text-center mb-5 pb-4">
      <h2>
        Our Safety Uniform Management
        <br class="d-none d-md-block">
        System <span class="text-warning">(SUMS)</span>
      </h2>
      <p>Managing employee uniforms has never been easier! Our SUMS system allows businesses to:</p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-12 col-lg-5">
          <div class="embroid-img"
            style="background: radial-gradient(71.1% 67.06% at 21.45% 100%, rgba(12, 29, 55, 0.20) 0%, rgba(12, 29, 55, 0.00) 100%), url('../../../../assets/images/sums-system.png') lightgray 50% / cover no-repeat;">
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <h3 class="mb-5">Streamline Your Uniform Management</h3>
          <ul class="list-unstyled mb-5">
            <li class="d-flex align-items-start justify-content-start gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path
                  d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                  stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>
                Track uniform allocations per employee
                <span class="d-block">Monitor and manage uniform distribution per employee</span>
              </p>
            </li>
            <li class="d-flex align-items-start justify-content-start gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path
                  d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                  stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>
                Streamline reordering & budgeting
                <span class="d-block">Simplify budgeting and inventory management</span>
              </p>
            </li>
            <li class="d-flex align-items-start justify-content-start gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path
                  d="M8.74967 13.9987L12.2497 17.4987L19.2497 10.4987M25.6663 13.9987C25.6663 20.442 20.443 25.6654 13.9997 25.6654C7.55635 25.6654 2.33301 20.442 2.33301 13.9987C2.33301 7.55538 7.55635 2.33203 13.9997 2.33203C20.443 2.33203 25.6663 7.55538 25.6663 13.9987Z"
                  stroke="#F68C1E" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>
                Ensure compliance with safety & branding guidelines
                <span class="d-block">Maintain safety and branding guidelines effectively</span>
              </p>
            </li>
          </ul>
          <button class="btn btn-primary px-5" type="button">
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- Embroidered uniform section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- clients say about section start here... -->
<!-- ========================================= -->
<section class="client-say">
  <div class="max-width-container">
    <div class="head text-center mb-5 pb-4">
      <h2>
        What Our <span class="text-warning">Clients Say</span>
      </h2>
    </div>

    <div class="container">
      <div class="row row-gap-5">
        <div class="col-12">
          <img src="../../../../assets/images/client-say.png" alt="client-say" class="w-100">
        </div>
        <div class="col-12">
          <p class="content">
            “The system works great with employees and management, it is easy to use, transparent,
            <br class="d-none d-md-block">
            flexible and reportable.
            <br class="d-none d-md-block">
            REALl staff are very helpful, polite and quick to respond to enquiries and issues.”
          </p>
          <div class="d-flex align-items-center justify-content-start gap-3">
            <span>
              ⭐️⭐️⭐️⭐️⭐️
            </span>
            <p class="title">Roc Drill Brisbane</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- clients say about section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- Request content section start here... -->
<!-- ========================================= -->
<section class="reqst-quote" id="getAQuote">
  <div class="max-width-container rounded-4">
    <div class="head text-center mb-5 pb-4">
      <h2>
        Request a Quote Today!
      </h2>
      <p>Fill out the form below and we’ll get back to you within 24 hours.</p>
    </div>
    <div class="container">
      <div class="rqst-content">
        <div class="left-content">
          <img class="rounded-5" src="../../../../assets/images/rqst-quote.png" alt="request-quote">
        </div>
        <div class="right-content">
          <div class="edit-card">
            <div class="card-body">
              <h4>Contact us</h4>
              <form action="">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="firstName" class="form-label">First name</label>
                      <input type="text" class="form-control" id="firstName" placeholder="">
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <label for="emailAddress" class="form-label">Email address</label>
                      <input type="email" class="form-control" id="emailAddress" placeholder="">
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <!-- <label for="contactNumber" class="form-label">Mobile phone</label> -->
                      <!-- <input type="number" class="form-control" id="contactNumber" placeholder=""> -->
                      <label for="mobileNo" class="form-label">Mobile phone</label>
                      <div class="custom-phone-input input-group flex-nowrap">
                        <!-- Country Selector -->
                        <div class="country-selector d-flex align-items-center justify-content-start">
                          <img [src]="'https://flagcdn.com/' + selectedCountry.iso2.toLowerCase() + '.svg'"
                            alt="Country Flag" width="15" height="15" />
                          <select class="form-select border-0 shadow-none py-0" style="font-size: 1.2rem; width: 7rem;"
                            (change)="onCountryChange($event)" [value]="selectedCountry.iso2">
                            <option *ngFor="let country of countries" [value]="country.iso2">
                              (+{{ country.dialCode }})
                            </option>
                          </select>
                        </div>

                        <div class="phone-number-input">
                          <input class="form-control p-0 shadow-none border-0" type="tel" maxlength="15"
                            placeholder="Enter phone number" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="Company" class="form-label">Business Name</label>
                      <input type="email" class="form-control" id="Company" placeholder="">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="location" class="form-label">Location</label>
                      <input type="text" class="form-control" id="location" placeholder="">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="generalDesc" class="form-label">Tell us about your uniform needs</label>
                      <textarea class="form-control" name="" id="generalDesc" placeholder="Message" rows="4"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary">Send a request</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- Request content section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- Frequently ask section start here... -->
<!-- ========================================= -->
<section class="freq-ask">
  <div class="max-width-container">
    <div class="head text-center mb-5 pb-4">
      <h2>
        Frequently asked questions
      </h2>
    </div>
    <div class="container">
      <div class="accord-content">
        <div class="accordion" id="freqAskaccordion">
          <div class="accordion-item border-0 mb-5">
            <h2 class="accordion-header border-0 bg-transparent">
              <button class="accordion-button border-0 bg-transparent shadow-none p-0 pt-4 fw-medium" type="button"
                data-bs-toggle="collapse" data-bs-target="#freqAskOne" aria-expanded="true" aria-controls="freqAskOne">
                What types of uniforms can you embroider?
              </button>
            </h2>
            <div id="freqAskOne" class="accordion-collapse collapse show" data-bs-parent="#freqAskaccordion">
              <div class="accordion-body p-0 pt-3">
                We embroider shirts, polos, jackets, caps, high-visibility workwear, and more.
              </div>
            </div>
          </div>
          <div class="accordion-item border-0 border-top mb-5">
            <h2 class="accordion-header border-0 bg-transparent">
              <button class="accordion-button border-0 bg-transparent shadow-none p-0 pt-4 fw-medium collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#freqAskTwo" aria-expanded="false"
                aria-controls="freqAskTwo">
                Do you charge setup fees?
              </button>
            </h2>
            <div id="freqAskTwo" class="accordion-collapse collapse" data-bs-parent="#freqAskaccordion">
              <div class="accordion-body p-0 pt-3">
                We embroider shirts, polos, jackets, caps, high-visibility workwear, and more.
              </div>
            </div>
          </div>
          <div class="accordion-item border-0 border-top mb-5">
            <h2 class="accordion-header border-0 bg-transparent">
              <button class="accordion-button border-0 bg-transparent shadow-none p-0 pt-4 fw-medium collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#freqAskThree" aria-expanded="false"
                aria-controls="freqAskThree">
                How long does embroidery take?
              </button>
            </h2>
            <div id="freqAskThree" class="accordion-collapse collapse" data-bs-parent="#freqAskaccordion">
              <div class="accordion-body p-0 pt-3">
                We embroider shirts, polos, jackets, caps, high-visibility workwear, and more.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="get-touch d-flex align-items-center justify-content-center flex-column">
        <h3 class="text-center">
           Get in touch today & elevate your brand with premium
          <br class="d-none d-lg-block">
          embroidered workwear!
        </h3>
        <button class="btn btn-orange" type="button">Get in touch</button>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- Frequently ask section end here... -->
<!-- ========================================= -->