<!-- card table-content for lg screen start here... -->
<div class="edit-card card d-none d-lg-block">
	<div class="card-body">
		<div class="manage-table main-table">
			<table class="table mb-0 shadow-none table-borderless">
				<thead>
					<tr>
						<th>Product</th>
						<th>Collection</th>
						<th>Color</th>
						<th>Size</th>
						<th>Quantity</th>
						<th>Packet Size</th>
						<th *ngIf="showPrice">Price</th>
						<th>Total Price</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of item">
						<td>
							<div class="product-name table-order align-items-start">
								<span class="imgs">
									<img src="{{ item.variation.imageUrl }}" alt="product-1" />
								</span>
								<div class="order-table-content gap-1">
									<a
										role="button"
										[routerLink]="'/product/' + item.product.uri"
										class="title text-wrap"
									>
										{{ item.product.name }}
									</a>
									<ul
										class="order-detail-list"
										*ngIf="item.decorations && item.decorations.length"
									>
										<li class="d-block">
											<p>Decoration:</p>
											<div class="d-flex flex-column gap-2 w-100">
												<span
													class="flex-wrap row-gap-2"
													*ngFor="let decoration of item.decorations"
													>+ $
													{{
														utils.twoDecimalPlacesString(
															decoration.decoration.price
														)
													}}
													-
													{{ decoration.decoration.name }}
													<span class="align-items-start" *ngFor="let option of decoration.options">
														{{ option.decorationOption.name }}:
														<span>{{ option.value }}</span>
													</span>
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</td>
						<td>
							<p *ngIf="item.allocationCollectionId">
								{{ item.allocationCollectionName }}
								<span
									class="badge bg-aqua-25 ps-3 py-1 d-inline-flex"
									*ngIf="item.allocationCollectionConsumption"
									>{{ item.allocationCollectionConsumption }} item{{
										item.allocationCollectionConsumption > 1 ? "s" : ""
									}}</span
								>
								<br />
								<small>Note* Collection Item quantity cannot be updated.</small>
							</p>
						</td>
						<!-- <td>
													<p>{{ item.variation.colour || "default" }}</p>
												</td>
												<td>
													<p>{{ item.variation.size || "default" }}</p>
												</td> -->

						<td>
							<div class="form-group mb-0">
								<select
									#selectedColor
									(change)="colorChanged(item, selectedColor.value)"
									class="form-select"
									aria-label="Default select example"
									id="Colour"
								>
									<option
										*ngFor="let color of getUniqueColors(item.product)"
										[selected]="item.variation.colour == color"
										[value]="color"
									>
										{{ color }}
									</option>
									<option
										*ngIf="!getUniqueColors(item.product)"
										value="default"
									>
										Default
									</option>
								</select>
							</div>
						</td>
						<td>
							<div class="form-group mb-0">
								<select
									#selectedSize
									class="form-select"
									(change)="sizeChanged(item, selectedSize.value)"
									aria-label="Default select example"
									id="Sizes"
								>
									<option
										*ngFor="let data of getUniqueSize(item.product)"
										[selected]="item.variation.size == data"
										value="{{ data }}"
									>
										{{ data }}
									</option>
									<option *ngIf="!getUniqueSize(item.product)" value="default">
										Default
									</option>
								</select>
							</div>
						</td>

						<td>
							<div class="qty-input main-shadow">
								<button
									class="btn qty-count p-0"
									(click)="pushQuantityChange(item, -1)"
									[disabled]="item.allocationCollectionId !== null"
									type="button"
								>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												d="M5 12H19"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											></path>
										</svg>
									</span>
								</button>
								<input
									class="product-qty"
									type="number"
									name="product-qty"
									[(ngModel)]="item.quantity"
									[disabled]="item.allocationCollectionId !== null"
									appNoNegative
									min="1"
								/>
								<button
									(click)="pushQuantityChange(item, 1)"
									class="btn qty-count p-0"
									[disabled]="item.allocationCollectionId !== null"
									type="button"
								>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												d="M12 5V19M5 12H19"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											></path>
										</svg>
									</span>
								</button>
							</div>
						</td>
						<td>
							<ng-container *ngIf="getPackBox(item)">
								<p class="mb-1">
									{{ getPackBox(item).name }}: {{ getPackBox(item).itemCount }}
								</p>
								<p>Total: {{ getPrice(item) }}</p>
							</ng-container>
							<p *ngIf="!getPackBox(item)">N/A</p>
						</td>
						<td *ngIf="showPrice">
							<p
								class="d-flex text-nowrap align-items-center justify-content-start gap-1"
							>
								${{ utils.twoDecimalPlacesString(getUnitPrice(item)) }}
							</p>
						</td>
						<td>
							<p
								class="d-flex text-nowrap align-items-center justify-content-start gap-1"
							>
								${{ utils.twoDecimalPlacesString(getTotalPrice(item)) }}
							</p>
						</td>
						<td>
							<div class="d-flex align-items-center justify-content-start">
								<button
									(click)="deleteCartItem(item)"
									class="btn btn-delete main-shadow rounded-circle"
									type="button"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M3.25 5.5H4.75H16.75"
											stroke="#818898"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										></path>
										<path
											d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
											stroke="#818898"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										></path>
									</svg>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div>
				<button
					[routerLink]="['/products']"
					class="btn btn-secondary shadow-none"
					type="button"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M10 4.75V15.25"
								stroke="#36394A"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M4.75 10H15.25"
								stroke="#36394A"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Add another item
				</button>
			</div>
		</div>
	</div>
</div>
<!-- card table-content for lg screen end here... -->

<!-- Cards for sm screen start here... -->
<div class="manage-cards d-lg-none">
	<div class="card manage-card" *ngFor="let item of item">
		<div class="card-body">
			<ul class="card-list">
				<li>
					<div class="manage-grid">
						<span class="head"> Product </span>
						<div class="table-order">
							<div class="order-head">
								<div class="order-img">
									<img src="{{ item.variation.imageUrl }}" alt="product-1" />
								</div>
								<a
									role="button"
									[routerLink]="'/product/' + item.product.uri"
									class="title"
								>
									{{ item.product.name }}
								</a>
							</div>
							<div
								class="order-table-content"
								*ngIf="item.decorations && item.decorations.length"
							>
								<ul class="order-detail-list">
									<li class="d-block">
										<h6>Decoration:</h6>
										<p class="max-w-100" *ngFor="let decoration of item.decorations">
											+ ${{
												utils.twoDecimalPlacesString(
													decoration.decoration.price
												)
											}}
											-
											{{ decoration.decoration.name }}
											<span class="align-items-start" *ngFor="let option of decoration.options">
												{{ option.decorationOption.name }}:
												<span>{{ option.value }}</span>
											</span>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
				<li>
					<div class="manage-grid" *ngIf="item.allocationCollectionId">
						<span class="head"> Collection </span>
						<div>
							<p>
								{{ item.allocationCollectionName }}
								<strong
									>({{ item.allocationCollectionConsumption }} items)</strong
								>
								<br />
								<small>Note* Collection Item quantity cannot be updated.</small>
							</p>
						</div>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Color </span>
						<div class="form-group mb-0">
							<select
								#selectedColor
								(change)="colorChanged(item, selectedColor.value)"
								class="form-select"
								aria-label="Default select example"
								id="Colour"
							>
								<option
									*ngFor="let color of getUniqueColors(item.product)"
									[selected]="item.variation.colour == color"
									[value]="color"
								>
									{{ color }}
								</option>
								<option *ngIf="!getUniqueColors(item.product)" value="default">
									Default
								</option>
							</select>
						</div>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Size </span>
						<div class="form-group mb-0">
							<select
								#selectedSize
								class="form-select"
								(change)="sizeChanged(item, selectedSize.value)"
								aria-label="Default select example"
								id="Sizes"
							>
								<option
									*ngFor="let data of getUniqueSize(item.product)"
									[selected]="item.variation.size == data"
									value="{{ data }}"
								>
									{{ data }}
								</option>
								<option *ngIf="!getUniqueSize(item.product)" value="default">
									Default
								</option>
							</select>
						</div>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Quantity </span>
						<div class="qty-input main-shadow">
							<button
								class="btn qty-count p-0 me-auto"
								(click)="pushQuantityChange(item, -1)"
								*ngIf="!item.allocationCollectionId"
								type="button"
							>
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M5 12H19"
											stroke="#666D80"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										></path>
									</svg>
								</span>
							</button>
							<input
								class="product-qty"
								type="number"
								name="product-qty"
								[(ngModel)]="item.quantity"
								[disabled]="item.allocationCollectionId !== null"
								readonly
							/>
							<button
								(click)="pushQuantityChange(item, 1)"
								class="btn qty-count p-0 ms-auto"
								*ngIf="!item.allocationCollectionId"
								type="button"
							>
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M12 5V19M5 12H19"
											stroke="#666D80"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										></path>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Packet Size </span>
						<ng-container *ngIf="getPackBox(item)">
							<p class="mb-1">
								{{ getPackBox(item).name }}: {{ getPackBox(item).itemCount }}
							</p>
							<p>Total: {{ getPrice(item) }}</p>
						</ng-container>
						<p *ngIf="!getPackBox(item)">N/A</p>
					</div>
				</li>
				<li *ngIf="showPrice">
					<div class="manage-grid">
						<span class="head"> Price </span>
						<p>${{ utils.twoDecimalPlacesString(getUnitPrice(item)) }}</p>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Total Price </span>
						<p>${{ utils.twoDecimalPlacesString(getTotalPrice(item)) }}</p>
					</div>
				</li>
				<li>
					<div class="manage-grid">
						<span class="head"> Action </span>
						<div class="d-flex align-items-center w-100 justify-content-end">
							<button
								(click)="deleteCartItem(item)"
								class="btn btn-delete main-shadow rounded-circle px-3 py-3"
								type="button"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M3.25 5.5H4.75H16.75"
										stroke="#818898"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>
									<path
										d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
										stroke="#818898"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>
								</svg>
							</button>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div>
		<button
			[routerLink]="['/products']"
			class="btn btn-secondary w-100"
			type="button"
		>
			<span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M10 4.75V15.25"
						stroke="#36394A"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M4.75 10H15.25"
						stroke="#36394A"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</span>
			Add another item
		</button>
	</div>
</div>
<!-- Cards for sm screen end here... -->
