import { Component, ViewChild } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { scrollIntoView } from 'src/app/main/util/dom.util';

@Component({
	selector: "app-embroidered",
	templateUrl: "./embroidered.component.html",
	styleUrls: ["./_embroidered.component.scss"],
})
export class EmbroideredComponent {
	public apiURL = environment.endpoint;
	constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
		this.navigateAbove();
		this.route.fragment.subscribe((fragment) => {
			scrollIntoView(fragment);
		});

		const currentFragment = this.route.snapshot.fragment;
		scrollIntoView(currentFragment);
	}

	countries = this.authService.countries;

	selectedCountry = this.countries[0];
	phoneNumber: string = '';
	isValid: boolean = true;

	productRoute(isExplore = false) {
		if (this.checkAuthenticated()) {
			isExplore
				? this.router.navigate(["/safety-uniform-management-system"])
				: this.router.navigate(["/products"]);
		} else {
			isExplore
				? this.router.navigate(["/safety-uniform-management-system"])
				: this.authService.setStickyMenu("login");
			this.navigateAbove();
		}
	}

	checkAuthenticated() {
		return this.authService.isAuthenticated();
	}

	navigateAbove() {
		window.scroll(0, 0);
	}

	onCountryChange(event: Event): void {
		const selectedIso2 = (event.target as HTMLSelectElement).value;
		this.selectedCountry = this.countries.find(
			(country) => country.iso2 === selectedIso2
		)!;
		this.validatePhoneNumber();
	}

	validatePhoneNumber(): void {
		const fullNumber = `+${this.selectedCountry.dialCode}${this.phoneNumber}`;
		const phoneRegex = new RegExp(
			`^\\+${this.selectedCountry.dialCode}\\d{6,15}$`
		);
		this.isValid = phoneRegex.test(fullNumber);
	}
}
