import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators,
	AbstractControl,
	ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/main/services/auth.service';
import { scrollIntoView } from 'src/app/main/util/dom.util';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: [],
})
export class ContactUsComponent implements OnInit {
	form: FormGroup;
	isLoading: boolean = false;
	captcha: boolean;
	countries = this.authService.countries;

	selectedCountry = this.countries[0];
	phoneNumber: string = '';
	isValid: boolean = true;
	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		public notifications: NotificationsService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		window.scroll(0, 0);
		this.route.fragment.subscribe((fragment) => {
			scrollIntoView(fragment);
		});

		const currentFragment = this.route.snapshot.fragment;
		scrollIntoView(currentFragment);

		this.form = this.fb.group({
			firstname: ['', [Validators.required, noWhitespaceValidator]],
			lastname: ['', [Validators.required, noWhitespaceValidator]],
			username: [
				'',
				[Validators.required, Validators.email, noWhitespaceValidator],
			],
			mobile: [undefined, [Validators.required]],
			company: [''],
			generalDesc: [''],
		});
	}

	onSubmit(): void {
		this.isLoading = true;
		if (this.form.valid && this.captcha) {
			const mobileNumber = this.form.value.mobile.e164Number || '';
			this.form.patchValue({
				mobile: mobileNumber,
			});
			this.notifications.warn('Contact Us', 'Sending Your Request');
			this.authService.contactUs(this.form.value).subscribe(
				() => {
					this.captcha = false;
					this.notifications.success('Contact Us', 'Your message was sent');
					this.form.reset();
					grecaptcha.reset();
					this.isLoading = false;
				},
				(error) => {
					this.notifications.error(
						'ERROR',
						(error && error.detail && error.detail.message) ||
						error.message ||
						{}
					);
				}
			);
		} else {
			this.form.markAllAsTouched();
		}
	}

	resolved(res: string) {
		if (res) {
			this.captcha = true;
		}
	}

	onPhoneInput(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		this.phoneNumber = inputElement.value.replace(/\D/g, '');
		inputElement.value = this.phoneNumber;
		this.validatePhoneNumber();
	}

	validatePhoneNumber(): void {
		const fullNumber = `+${this.selectedCountry.dialCode}${this.phoneNumber}`;
		const phoneRegex = new RegExp(`^\\+${this.selectedCountry.dialCode}\\d{6,15}$`);
		this.isValid = phoneRegex.test(fullNumber);
	}

	onCountryChange(event: Event): void {
		const selectedIso2 = (event.target as HTMLSelectElement).value;
		this.selectedCountry = this.countries.find(
			(country) => country.iso2 === selectedIso2
		)!;
		this.validatePhoneNumber();
	}
}

export const noWhitespaceValidator: ValidatorFn = (
	control: AbstractControl
): { [key: string]: boolean } | null => {
	if (control.value && control.value.trim().length === 0) {
		return { required: true };
	}
	return null;
};
