import { Component, OnDestroy, OnInit } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { CategoryService } from '../../services/category.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Category, EnumCreateParams } from '../../model/ddb.model';
import { map } from 'rxjs';
import { SubscriptionGroup } from '../../util/subscriptionGroup';

@Component({
  selector: 'app-quick-order-categories',
  templateUrl: './quick-order-categories.component.html',
  styleUrls: []
})
export class QuickOrderCategoriesComponent implements OnInit, OnDestroy {
  private subscriptionGroup = new SubscriptionGroup();

  queryParams: Params = {};
  primaryCategory = '';
  primaryCategoryId: string | undefined;
  categoriesPath: string[] = [];
  public categories: EnumCreateParams[] = [];

  constructor(
    private securityService: SecurityService,
    private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;

      if (this.showCategories) {
        this.subscriptionGroup.add(
          this.categoryService.allCategories$
            .pipe(map(res => res.rows))
            .subscribe(this.handleCategoriesGet)
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }

  get showCategories(): boolean {
    return this.securityService ? this.securityService.showCategoriesInProductSearch() : true;
  }

  /**
* @description Handles the response from a category list get
* @param items the response from the communication service
*/
  handleCategoriesGet = (items: EnumCreateParams[]) => this.categories = items;


  getCategoriesByParent = (parentCategory?: EnumCreateParams) => this.categories.filter(category => category.parentId === (parentCategory ? parentCategory.id : null));


  handleCategoryClick(primaryCategory?: Category) {
    this.primaryCategoryId = primaryCategory?.id;
    this.primaryCategory = primaryCategory ? this.toUrlKey(primaryCategory.name) : '';
    this.categoriesPath = [];
    if (this.primaryCategory) {
      this.categoriesPath.push(this.primaryCategory);
    }

    let targetRoute = ['quick-order'];
    if (this.primaryCategory && this.primaryCategory.length) {
      targetRoute.push(this.primaryCategory);
    }

    this.router.navigate(targetRoute, {
      queryParams: this.queryParams
    });

    this.router.navigate(targetRoute, { queryParams: this.queryParams });
  }

  /**
* @description Standardised conversion of category name to url format
* @param categoryName
*/
  toUrlKey = (categoryName: string) => categoryName.replace(/\s/g, '_').toLowerCase();

}
