import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, combineLatest } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { CustomerApi } from '../api/customer.api';
import { NewCustomer } from '../model/ddb.model';
import { BusinessUnit, UnleashedCustomer } from '../model/unleashed.model';
import { isNullOrUndefined } from 'util';
import { CustomerUserRolesInterface } from '../model/role.model';
import { CustomerUserRoleService } from './customerUserRole.service';
import { LoadingService } from './core/loading.service';

@Injectable()
export class CustomerService {
  constructor(
    private notifications: NotificationsService,
    private customerApi: CustomerApi,
    private customerUserRoleService: CustomerUserRoleService,
    private loadingService: LoadingService
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<NewCustomer & HasId<number>>> {
    return apiCallWrapper(
      this.customerApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Customer"
      }
    )
  }

  public getUserField(id: number): Observable<any> {
    return apiCallWrapper(
      this.customerApi.getUserField(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Customer User Field"
      }
    )
  }

  public userField(model: Array<{ fieldName: string, fieldType: string, customerId: number }>): Observable<QueryResult<NewCustomer & HasId<string>>> {
    return apiCallWrapper(
      this.customerApi.createUserField(model[0].customerId,
        model),
      {
        notificationsService: this.notifications,
        action: "Creating Customer User Field"
      }
    )
  }

  public deleteUserField(id: number): Observable<{ message: string }> {
    return apiCallWrapper(
      this.customerApi.deleteUserField(id),
      {
        notificationsService: this.notifications,
        action: "Delete Customer User Field"
      }
    )
  }

  public readonly getById = (id: number | string): Observable<UnleashedCustomer> => {
    return combineLatest(
      this.customerApi.get(id),
      this.customerUserRoleService.items,
      (customer: any, customerUserRoles: any[]) => {
        return this.convertToCustomer(customer, customerUserRoles);
      }
    );
  };

  public readonly getByIdCount = (id: number | string): Observable<NewCustomer & UnleashedCustomer> => {
    return this.customerApi.get(id);
  };

  public create(data: UnleashedCustomer): Observable<UnleashedCustomer> {
    return apiCallWrapper(
      this.customerApi.create(this.convertFromCustomer(data)),
      {
        notificationsService: this.notifications,
        action: "Creating Customer"
      }
    )
  }

  public update(data: any): Observable<UnleashedCustomer> {
    return apiCallWrapper(
      this.customerApi.update(data.id, this.convertFromCustomer(data)),
      {
        notificationsService: this.notifications,
        action: "Updating Customer"
      }
    )
  }

  public getBusinessUnit(id: number, query: IQueryFilter): Observable<QueryResult<BusinessUnit & HasId<string>>> {
    return apiCallWrapper(
      this.customerApi.getBusinessUnit(id, query),
      {
        notificationsService: this.notifications,
        action: "Fetching Business Unit"
      }
    )
  }

  public deleteBusinessUnit(customerId: number, id: number): Observable<{}> {
    return apiCallWrapper(
      this.loadingService.blockWithLoadingOverlayRx(this.customerApi.deleteBusinessUnit(customerId, id)),
      {
        notificationsService: this.notifications,
        action: "Delete Business Units"
      }
    )
  }

  convertFromCustomer(model: UnleashedCustomer): object {
    const obj: { [key: string]: any } = {};

    obj.guid = model.Guid;
    obj.assignedProductsOnly = model.AssignedProductsOnly;
    obj.code = model.CustomerCode;
    obj.handlingFee = model.HandlingFee;
    obj.handlingFeeAmount = model.HandlingFeeAmount ? Number(model.HandlingFeeAmount) : null;
    obj.handlingFeeThreshold = model.HandlingFeeThreshold ? Number(model.HandlingFeeThreshold) : null;
    obj.handlingFeeCoPay = model.HandlingFeeCoPay ? Number(model.HandlingFeeCoPay) : null;
    obj.imageUrl = model.imageUrl;
    obj.name = model.CustomerName;
    obj.purchaseOrderPattern = model.PurchaseOrderPattern || null;
    obj.registerEmailPattern = model.RegisterEmailPattern;
    obj.registerEnabled = model.RegisterEnabled;
    obj.registerSecret = model.RegisterSecret;
    obj.registerUserRoleId = model.RegisterUserRoleId;
    obj.search = model.search;
    obj.reminders = model.reminders || []
    /* The following properties should match 1-1 */
    obj.employeePurchaseMode = model.employeePurchaseMode;
    obj.supportingDocuments = model.supportingDocuments || [];
    obj.disableAddressEdit = model.disableAddressEdit;
    obj.showProductSearchCategories = model.showProductSearchCategories;
    obj.showProductSearchCollections = model.showProductSearchCollections;
    obj.enableBulkOrder = model.enableBulkOrder;
    obj.enableOrdersRequireApproval = model.enableOrdersRequireApproval;
    obj.stopCredit = model.stopCredit;
    obj.allowPackOrderNames = model.allowPackOrderNames;
    obj.enableOrdersAllocation = model.enableOrdersAllocation;
    obj.disable2faCheck = model.disable2faCheck;
    obj.defaultUri = model.defaultUri;
    obj.redirectEmail = model.redirectEmail;
    obj.ccEmail = model.ccEmail;
    obj.discountAmount = this.typeSafeNumberOr(model.discountAmount, 0);
    obj.defaultWarehouse = model.defaultWarehouse;
    obj.obsolete = model.obsolete;
    obj.automaticCollectionSelection = model.automaticCollectionSelection;
    obj.enableUsersRequireApproval = model.enableUsersRequireApproval;
    obj.requiredField = model.requiredField
    return obj;
  }

  private typeSafeNumberOr<T = number | null | undefined>(val: any, defaultVal: T): number | T {
    if (!isNullOrUndefined(val) && !isNaN(Number(val)))
      return Number(val);

    return defaultVal;
  }

  convertToCustomer(obj: any, userRoles: CustomerUserRolesInterface[] = []): UnleashedCustomer {
    const value = new UnleashedCustomer();

    value.id = obj.id;
    value.AssignedProductsOnly = obj.assignedProductsOnly;
    value.CustomerCode = obj.code;
    value.HandlingFee = obj.handlingFee;
    value.HandlingFeeAmount = obj.handlingFeeAmount || null;
    value.HandlingFeeThreshold = obj.handlingFeeThreshold || null;
    value.HandlingFeeCoPay = obj.handlingFeeCoPay || null;
    value.Guid = obj.guid || null;
    value.imageUrl = obj.imageUrl;
    value.CustomerName = obj.name;
    value.PurchaseOrderPattern = obj.purchaseOrderPattern;
    value.RegisterEmailPattern = obj.registerEmailPattern;
    value.RegisterEnabled = obj.registerEnabled;
    value.RegisterSecret = obj.registerSecret;
    value.RegisterUserRole = obj.registerUserRoleId;
    value.RegisterUserRoleId = obj.registerUserRoleId;
    value.search = obj.search;
    value.reminders = obj.reminders || []
    /* The following properties should match 1-1 */
    value.employeePurchaseMode = obj.employeePurchaseMode;
    value.supportingDocuments = obj.supportingDocuments || [];
    value.disableAddressEdit = obj.disableAddressEdit;
    value.showProductSearchCategories = obj.showProductSearchCategories;
    value.showProductSearchCollections = obj.showProductSearchCollections;
    value.enableBulkOrder = obj.enableBulkOrder;
    value.enableOrdersAllocation = obj.enableOrdersAllocation;
    value.enableOrdersRequireApproval = obj.enableOrdersRequireApproval;
    value.defaultUri = obj.defaultUri;
    value.defaultWarehouse = obj.defaultWarehouse;
    value.redirectEmail = obj.redirectEmail;
    value.ccEmail = obj.ccEmail;
    value.stopCredit = obj.stopCredit;
    value.allowPackOrderNames = obj.allowPackOrderNames;
    value.discountAmount = this.typeSafeNumberOr(obj.discountAmount, 0);
    value.disable2faCheck = obj.disable2faCheck;
    value.obsolete = obj.obsolete;
    value.automaticCollectionSelection = obj.automaticCollectionSelection;
    value.collections = obj.collections;
    value.decorations = obj.decorations;
    value.enableUsersRequireApproval = obj.enableUsersRequireApproval;
    value.requiredField = obj.requiredField;
    return value;
  }

  public deleteCustomer(id: number | string) {
    return apiCallWrapper(
      this.customerApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Customer"
      }
    )
  }

}
