import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { LabelService } from '../services/label.service';
import { BrandService } from '../services/brand.service';
import { IQueryFilter } from '../model/query.filter.class';
import { EnumCreateParams, IEnum } from '../model/ddb.model';
import { v4 as uuid } from "uuid";
import { map, tap } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../template/model.component';

@Component({
  selector: 'app-enum',
  templateUrl: './enum.component.html',
  styleUrls: []
})
export class EnumComponent implements OnInit {
  public language: any = {
    pageTitle: 'Manage Items',
    singular: 'item',
    multiple: 'items'
  };
  public key: string;
  public theEnum: Array<IEnum> = [];
  public theEnumOriginal: Array<IEnum> = [];
  public canCascade: boolean;
  public dataFromInput: any[] = [];
  public payload: { created: IEnum[], updated: IEnum[], deleted: number[] } = {
    created: [],
    updated: [],
    deleted: []
  };

  constructor(
    public activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private labelService: LabelService,
    private brandService: BrandService,
    public notifications: NotificationsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((args: Object) => {
      if (args.hasOwnProperty('title')) {
        this.language.pageTitle = args['title'];
      }
      if (args.hasOwnProperty('singular')) {
        this.language.singular = args['singular'];
      }
      if (args.hasOwnProperty('multiple')) {
        this.language.multiple = args['multiple'];
      }
      if (args.hasOwnProperty('canCascade')) {
        this.canCascade = args['canCascade'];
      }

      this.key = args['key'];
      this.getEnum();
    });
  }

  /**
  * Call for a Enum via http
  */
  private readonly getEnum = () => {
    const query: IQueryFilter = new IQueryFilter({ limit: 10000 });
    switch (this.key.toLowerCase()) {
      case 'brands':
        this.brandService.list(query)
          .subscribe(this.handleEnumGet);
        break;

      case 'categories':
        this.categoryService.list(query)
          .pipe(
            map(res => res.rows)
          )
          .subscribe(this.handleEnumGet);
        break;

      case 'labels':
        this.labelService.list(query)
          .subscribe(this.handleEnumGet);
        break;

      default:
        throw new Error(`Unknown Enum Key ${this.key}`);
    }
  };

  private readonly handleEnumGet = (items: EnumCreateParams[]) => {
    let itemsToProcess = [...items];
    const structuredEnums: IEnum[] = [];

    itemsToProcess.forEach(() => {
      const itemsWithoutChild =
        itemsToProcess.filter(item => !itemsToProcess.find(child => child.parentId && child.parentId === item.id));

      itemsWithoutChild.forEach(item => {
        const parent: IEnum | undefined = itemsToProcess.find(enumItem => enumItem.id === item.parentId);
        if (this.canCascade) {
          item.children = (item.children || []);
        }

        if (parent) {
          parent.children = (parent.children || []);
          parent.children.push(item);
        } else {
          structuredEnums.push(item);
        }
      });

      itemsToProcess = itemsToProcess.filter(item => !itemsWithoutChild.find(child => child.id === item.id));
    });

    // alphabet order
    this.theEnumOriginal = structuredEnums.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    // We are intentionally dereference the originals for change detection later
    this.theEnum = structuredEnums.map(a => (JSON.parse(JSON.stringify(a))));

    if (this.canCascade) {
      this.addLevels(this.theEnum, 1);
    }
  };

  addLevels(data: any, level: number) {
    for (let i = 0; i < data.length; i++) {
      data[i].level = level.toString();
      if (data[i].children.length > 0) {
        this.addLevels(data[i].children, level + 1);
      }
    }
  }

  public returnUniqueValues = (items: EnumCreateParams[]) => {
    return items.filter(function (x, i, a) {
      return a.indexOf(x) === i;
    });
  };


  editEnum(item: IEnum, isSave?: boolean) {
    let isCreated: boolean = false;

    if (!item.originalName) {
      item.originalName = item.name;
    }

    if (isSave) {
      this.payload.created.map((i) => {
        if (i.id == item.id) {
          i.name = item.name;
          isCreated = true;
          return
        }
      });
    }

    const findItem = this.theEnum.find((data) => data.id == item.id);
    if (findItem) {
      findItem.isEdit = true;
      if (findItem.name.trim().length < 3) {
        this.notifications.error('Error', `${this.language.singular} have minimum 3 length`);
        return;
      }
      if (isSave && !isCreated) {
        this.payload.updated.push(findItem);
        findItem.isEdit = false;
      } else if (isCreated) {
        findItem.isEdit = false;
      }
    }

  }

  closeEnum(item: IEnum) {
    const findItem = this.theEnum.find((data) => data.id == item.id);
    if (findItem && findItem.originalName) {
      findItem.name = findItem.originalName;
      findItem.isEdit = false;
    }
  }

  deleteEnum(item: IEnum) {
    const index = this.theEnum.findIndex(o => o.id === item.id);
    this.theEnum.splice(index, 1);

    if (index !== -1) {
      if (!Number.isNaN(+item.id)) {
        if (this.canCascade) {
          this.addChildId(item.children);
        }
        this.payload.deleted.push(+item.id);
      }
    }

    const inCreated = this.payload.created.findIndex(e => e.id === item.id);
    if (inCreated !== -1) {
      this.payload.created.splice(inCreated, 1);
    }

    const inUpdated = this.payload.updated.findIndex(u => u.id === item.id);
    if (inUpdated !== -1) {
      if (this.canCascade) {
        this.addChildId(item.children);
      }
      this.payload.deleted.push(+item.id);
      this.payload.updated.splice(inUpdated, 1);
    }

  }

  addChildId(data) {
    for (let i = 0; i < data.length; i++) {
      this.payload.deleted.push(+data[i].id);
      if (data[i].children.length > 0) {
        this.addChildId(data[i].children);
      }
    }
  }


  /* Saving and Editing the Enums */
  saveEnum(editedItem: HTMLInputElement) {
    if (editedItem.value.trim().length >= 50) {
      this.notifications.error('Error', `${this.language.singular} have too big name`);
      return;
    }
    if (editedItem.value.trim().length >= 3) {
      const item = {
        name: editedItem.value,
        id: uuid(),
        children: [],
        level: this.theEnum.length ? this.theEnum[0].level : '1'
      }
      this.notifications.success('Adding', `Creating ${this.language.singular}`);
      this.theEnum.push(item);

      this.payload.created.push(item);
      editedItem.value = '';
    } else {
      this.notifications.error('Error', `${this.language.singular} have minimum 3 length`);
    }
  }

  /* Guarantees the type of the update against the enum key */
  private readonly getUpdateFunction = (changes: any) => {
    switch (this.key.toLowerCase()) {
      case 'brands':
        return this.brandService.create(changes);
      case 'categories':
        return this.categoryService.create(changes);
      case 'labels':
        return this.labelService.create(changes);

      default:
        throw new Error(`Unknown Enum Key ${this.key}`);
    }
  };

  save() {
    let observableUpdate = this.getUpdateFunction(this.payload);

    observableUpdate
      .subscribe((data) => {
        if (data) {
          this.payload = {
            created: [],
            updated: [],
            deleted: []
          };
          this.dataFromInput.length = 0;
          this.getEnum();
        }
      });
  }

  public openRemoveModal(item: IEnum) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = `Delete ${this.language.singular}`;
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove ${this.language.singular}</h4>
					<p class="desc">Would you like to remove this ${this.language.singular}?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && item) {
          this.deleteEnum(item);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

}
