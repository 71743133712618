<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs d-lg-flex align-items-center justify-content-between">
		<a
			[routerLink]="[pathBase]"
			[queryParams]="{ customerId: customerId, name: name }"
			class="btn btn-back p-0 gap-2 d-none d-lg-flex"
			role="button"
		>
			<span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="6"
					height="10"
					viewBox="0 0 6 10"
					fill="none"
				>
					<path
						d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039"
						stroke="#666D80"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</span>
			Back to reports
		</a>
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item">
					<a
						[routerLink]="[pathBase]"
						[queryParams]="{ customerId: customerId, name: name }"
						>Reports</a
					>
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					User Allocation Report
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div
			class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4"
		>
			<div>
				<h1 class="title mb-0">User Allocation Report</h1>
				<p class="title-desc">
					List all users in the account, including business unit, cost account,
					approver, allocation, allocation balance where applicable. Includes
					allocations attached to guest users.
				</p>
			</div>
			<div class="edit-btns w-sm-100">
				<button
					class="btn btn-secondary w-sm-100"
					type="button"
					(click)="downloadFile()"
					*ngIf="reportData && reportData.length"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.75 7L10 3.25L6.25 7"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 3.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Export CSV
				</button>
			</div>
		</div>

		<div class="manage-user-list">
			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Business unit</th>
							<th>Cost account</th>
							<th>Balance</th>
							<th>Approver</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of reportData">
							<td>
								<div class="user-name">
									<span
										>{{ item.firstName.charAt(0).toUpperCase()
										}}{{ item.lastName.charAt(0).toUpperCase() }}</span
									>
									<div>
										<h5>{{ item.firstName }} {{ item.lastName }}</h5>
										<p class="mb-0">{{ item.email }}</p>
									</div>
								</div>
							</td>
							<td>
								<p>{{ item.businessUnit }}</p>
							</td>
							<td>
								<p>{{ item.costAccount }}</p>
							</td>
							<td>
								<div class="d-flex align-items-center gap-2">
									<span class="badge bg-alpha-25 ps-3">
										{{ item.allocationType }}</span
									>
									<span class="badge bg-alpha-25 ps-3">
										$ {{ item.allocationBalance }}</span
									>
								</div>
							</td>
							<td>
								<div class="user-name">
									<!-- <span>JW</span> -->
									<div>
										<h5>{{ item.approver }}</h5>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen start here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of reportData">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Name </span>
									<div class="user-name">
										<span
											>{{ item.firstName.charAt(0).toUpperCase()
											}}{{ item.lastName.charAt(0).toUpperCase() }}</span
										>
										<div>
											<h5>{{ item.firstName }} {{ item.lastName }}</h5>
											<p class="mb-0">{{ item.email }}</p>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Business unit </span>
									<p>{{ item.businessUnit }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Cost account </span>
									<p>{{ item.costAccount }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Balance </span>
									<div class="d-flex align-items-center gap-2">
										<span class="badge bg-alpha-25 ps-3 py-1 fw-bold">
											{{ item.allocationType }}</span
										>
										<span class="badge bg-green-25 ps-3 py-1 fw-bold">
											$ {{ item.allocationBalance }}</span
										>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Approver </span>
									<div class="user-name">
										<!-- <span>JW</span> -->
										<div>
											<h5>{{ item.approver }}</h5>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
</div>
