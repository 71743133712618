export const environment = {
	production: true,
	region: 'ap-southeast-2',
	endpoint: 'https://dev.api.reali.au',
	wsEndPoint: 'wss://dev.api.reali.au',
	logLevel: 'debug',
	buildId: process.env.NG_APP_BUILD_ID || 'local',
	defaultCollectionLimit: 10,
	enableSentry: true,
	defaultTestEmailAddress: "",
version: "dev_767",
	baseDomain: 'dev.reali.au',
};
