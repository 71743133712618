import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AuthApi } from '../../api/auth.api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./_blogs.component.scss']
})
export class BlogsComponent {
  blogPosts: { featured_image: string, featured_image_alt: string, title: string, summary: string, slug: string, categories: [{ name: string }] }[] = [];
  searchValue = ''

  constructor(private authApi: AuthApi, private router: Router) {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    this.authApi.getAllBlogs().subscribe((res: any) => {
      this.blogPosts = res.data
    });
  }

  onClick(slug: string) {
    this.router.navigate(['news', slug]);
  }
}
