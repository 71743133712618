import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProductDraftResult, IRequestPrdoductPayload, IValidateProductUriOpts, NewProduct } from '../model/ddb.model';
import { createUrl, getPublicRoutesHeaders } from './api.util';
import { Observable } from 'rxjs';
import { QueryResult } from '../model/query.filter.class';

// Convenience Type
type T = NewProduct;

@Injectable()
export class ProductApi extends GenericApi<T> {
  public path = "Product";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly getProductDraft = (id?: number): Observable<IProductDraftResult> => {
    let result: Observable<IProductDraftResult>;

    if (id)
      result = this.httpClient.get<IProductDraftResult>(createUrl(this.path, 'draft', id));
    else
      result = this.httpClient.get<IProductDraftResult>(createUrl(this.path, 'draft'));

    return result;
  };

  readonly validateProductUri = (data: IValidateProductUriOpts): Observable<{ unique: boolean }> => {
    return this.httpClient.post<{ unique: boolean }>(createUrl('product', 'validateUri'), data);
  }

  readonly cloneProduct = (product: NewProduct): Observable<any> => this.httpClient.post<any>(createUrl(this.path, 'clone'), product);

  readonly deleteProduct = (id: number | string): Observable<{ success: boolean }> => this.httpClient.delete<{ success: boolean }>(createUrl(this.path, id));

  readonly restoreProduct = (id: number | string): Observable<any> => this.httpClient.get<any>(createUrl(this.path, id, 'restore'), {});

  readonly getProductList = (params: HttpParams): Observable<QueryResult<NewProduct>> => this.httpClient.get<QueryResult<NewProduct>>(
    createUrl(this.path),
    { params });

  readonly getUnAuthProductList = (params: HttpParams): Observable<QueryResult<NewProduct>> => this.httpClient.get<QueryResult<NewProduct>>(
    createUrl(this.path, 'unauth', 'list'),
    { params, headers: getPublicRoutesHeaders() });

  readonly getProductByUri = (params: HttpParams, uri: string): Observable<QueryResult<NewProduct>> => this.httpClient.get<QueryResult<NewProduct>>(
    createUrl(this.path, 'uri', ...uri.split('/')),
    { params });

  readonly getUnAuthProductByUri = (params: HttpParams, uri: string): Observable<QueryResult<NewProduct>> => this.httpClient.get<QueryResult<NewProduct>>(
    createUrl(this.path, 'unauth', 'uri', ...uri.split('/')),
    { params, headers: getPublicRoutesHeaders() });

  readonly getTopProducts = (params: HttpParams): Observable<any> => this.httpClient.get<any>(
    createUrl(this.path, 'best'),
    { params });

  readonly getAllocationProductList = (params: HttpParams): Observable<any> => this.httpClient.get<any>(
    createUrl(this.path, 'user/allocation'),
    { params }
  );

  readonly requestProduct = (payload: IRequestPrdoductPayload): Observable<any> => this.httpClient.post(
    createUrl(this.path, 'request-product'),
    payload
  );
}
