<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs">
		<a *ngIf="currentUser.isAdmin" [routerLink]="[editLink]" [queryParams]="{ activeTab: activeTab }"
			class="btn btn-back p-0 gap-2" role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
					<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
						stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Back to Edit customer
		</a>
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{documentId ? 'Edit': 'Add'}} document</h1>
		</div>

		<div class="manage-user-list">
			<div class="edit-users">
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M10.75 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7.75L10.75 2.5Z"
											stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10.75 2.5V7.75H16" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</span>
								General Information
							</h4>
						</div>
						<div class="card-body">
							<form action="">
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label for="Name" class="form-label">Name</label>
											<input type="text" class="form-control" id="Name" name="name" placeholder="Enter document Name"
												[(ngModel)]="documentData.name" appInputMaxLength="120" />
										</div>
									</div>
									<div class="col-12 col-md-6 d-none">
										<div class="form-group">
											<label for="Approver" class="form-label">Approver</label>
											<select #customerUser class="form-select" aria-label="Default select example" id="Approver"
												(change)="documentData.userId = +customerUser.value">
												<option *ngIf="!documentData.userId">
													Select User
												</option>
												<option *ngFor="let data of customerUsers" [value]="data.user.id"
													[selected]="documentData.userId == data.user.id">
													{{ data.user.firstName + data.user.lastName }}
												</option>
											</select>
										</div>
									</div>
									<div class="col-12">
										<div class="uploader">
											<div class="form-group w-100">
												<label for="logoFile" class="btn btn-secondary w-100">Upload document</label>
												<input class="form-control d-none" type="file" id="logoFile"
													(change)="persistS3Documents($event)" />
											</div>
											<div *ngFor="
													let document of getResourceDocuments();
													let i = index
												">
												<a [href]="document.path" target="_blank">{{
													document.name
													}}</a>
											</div>
										</div>
									</div>

									<div class="col-12 col-md-10 d-none">
										<div class="form-group form-check">
											<input class="form-check-input" type="checkbox" name="isApproved" id="flexCheckDefault"
												[value]="true" [(ngModel)]="documentData.isApproved" />
											<label class="form-check-label" for="flexCheckDefault">
												Approved for View
											</label>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="d-flex align-items-start justify-content-between">
						<div class="edit-btns w-sm-100">
							<button (click)="saveDocumentData()" class="btn btn-primary w-sm-100" type="button">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
											stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>