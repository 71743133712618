<div class="main-right-content">
	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<h1 class="title mb-0">My lists</h1>
			<a (click)="openAddModal(add_model)" class="btn btn-primary"><span><svg xmlns="http://www.w3.org/2000/svg"
						width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg></span>Add new list</a>
		</div>

		<div class="manage-user-list">
			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Products</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let list of queryResult.rows">
							<td>
								<div class="user-name">
									<h5 (click)="viewFavoritesList(list)">
										{{ list.name }}{{ showLockIcon(list) ? " 🔒" : "" }}
									</h5>
								</div>
							</td>
							<td>
								<div class="employees" *ngIf="list.productCount">
									<a class="btn btn-employee pe-none" *ngFor="let item of getProductCountRange(list.productCount)">
										<span><img class="rounded-circle" src="../../../assets/images/employee-1.svg"
												alt="employee" /></span>
									</a>
									<a href="#" class="btn btn-employee pe-none" *ngIf="list.productCount >= 4">
										+{{ list.productCount - 4 }}
									</a>
									<a href="#" class="btn btn-employee pe-none" *ngIf="list.productCount < 4">
										+0
									</a>
								</div>
								<div class="employees" *ngIf="!list.productCount">
									<a href="#" class="btn btn-employee pe-none"> +0 </a>
								</div>
							</td>
							<td>
								<div class="d-flex align-items-center justify-content-start">
									<button [disabled]="isRemove && listId == list.id"
										(click)="openRemoveModal(list)" class="btn btn-delete" role="button">
										<svg *ngIf="listId !== list.id" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
											viewBox="0 0 20 20" fill="none">
											<path d="M3.25 5.5H4.75H16.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
										<div class="spinner-border" role="status" *ngIf="isRemove && listId == list.id">
											<span class="visually-hidden">Loading</span>
										</div>
									</button>
									<a (click)="openRenameModal(list, add_model)" class="btn btn-edit" role="button">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M10 16.0002H16.75M13.375 3.62517C13.6734 3.3268 14.078 3.15918 14.5 3.15918C14.7089 3.15918 14.9158 3.20033 15.1088 3.28029C15.3019 3.36024 15.4773 3.47743 15.625 3.62517C15.7727 3.77291 15.8899 3.9483 15.9699 4.14132C16.0498 4.33435 16.091 4.54124 16.091 4.75017C16.091 4.9591 16.0498 5.16599 15.9699 5.35902C15.8899 5.55204 15.7727 5.72743 15.625 5.87517L6.25 15.2502L3.25 16.0002L4 13.0002L13.375 3.62517Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</a>
									<a [routerLink]="['/account/favorites/detail', list.id]" [queryParams]="{ name: list.name }"
										class="btn btn-more" role="button">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M4.75 10H15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M10 4.75L15.25 10L10 15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</a>
								</div>
							</td>
						</tr>
						<tr *ngIf="queryResult.rows.length === 0 && !isLoading">
							<td colspan="6">
								<div class="no-results-content">
									<div class="no-result-img for-admin">
										<img src="../../../../assets/images/No-result.png" alt="No-result" />
									</div>
									<h4 class="title">No Results Found!</h4>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let list of queryResult.rows">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Name </span>
									<div class="user-name">
										<h5 (click)="viewFavoritesList(list)">
											{{ list.name }}{{ showLockIcon(list) ? " 🔒" : "" }}
										</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Products </span>
									<div class="employees" *ngIf="list.productCount">
										<a class="btn btn-employee pe-none" *ngFor="
												let item of getProductCountRange(list.productCount)
											">
											<span><img class="rounded-circle" src="../../../assets/images/employee-1.svg"
													alt="employee" /></span>
										</a>
										<a href="#" class="btn btn-employee pe-none" *ngIf="list.productCount >= 4">
											+{{ list.productCount - 4 }}
										</a>
										<a href="#" class="btn btn-employee pe-none" *ngIf="list.productCount < 4">
											+0
										</a>
									</div>
									<div class="employees" *ngIf="!list.productCount">
										<a href="#" class="btn btn-employee pe-none"> +0 </a>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Actions: </span>
									<div class="d-flex align-items-center w-100 justify-content-end">
										<button [disabled]="isRemove && listId == list.id"
											(click)="openRemoveModal(list)" class="btn btn-delete" type="button">
											<svg *ngIf="listId !== list.id" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
												viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
											<div class="spinner-border" role="status" *ngIf="isRemove && listId == list.id">
												<span class="visually-hidden">Loading</span>
											</div>
										</button>
										<a (click)="openRenameModal(list, add_model)" class="btn btn-edit" role="button">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M10 16.0002H16.75M13.375 3.62517C13.6734 3.3268 14.078 3.15918 14.5 3.15918C14.7089 3.15918 14.9158 3.20033 15.1088 3.28029C15.3019 3.36024 15.4773 3.47743 15.625 3.62517C15.7727 3.77291 15.8899 3.9483 15.9699 4.14132C16.0498 4.33435 16.091 4.54124 16.091 4.75017C16.091 4.9591 16.0498 5.16599 15.9699 5.35902C15.8899 5.55204 15.7727 5.72743 15.625 5.87517L6.25 15.2502L3.25 16.0002L4 13.0002L13.375 3.62517Z"
													stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</a>
										<a [routerLink]="['/account/favorites/detail', list.id]" [queryParams]="{ name: list.name }"
											class="btn btn-more" role="button">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M4.75 10H15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path d="M10 4.75L15.25 10L10 15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
											</svg>
										</a>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="no-results-content" *ngIf="queryResult.rows.length == 0 && !isLoading">
					<div class="no-result-img for-admin">
						<img src="../../../../assets/images/No-result.png" alt="No-result" />
					</div>
					<h4 class="title">No Results Found!</h4>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
</div>

<ng-template #add_model let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="myListModalLabel">
				{{ modalMode === "new" ? "New" : "Edit" }} Favorite List
			</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-12">
					<div class="form-group mb-4">
						<label for="ListName" class="form-label">List Name</label>
						<input type="text" name="listName" class="form-control" id="ListName" placeholder="Enter List Name"
							[(ngModel)]="newListName" />
					</div>
				</div>
				<div class="col-12">
					<div class="form-group form-check mb-4">
						<input type="checkbox" class="form-check-input" id="listType" name="private" [(ngModel)]="isListPrivate" />
						<label class="form-check-label" for="listType">Private List </label>
					</div>
				</div>
				<div class="col-12">
					<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
						<a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
							Cancel
						</a>
						<button (click)="c('Create')" [disabled]="!newListName || !newListName.trim()"
							class="btn btn-primary text-nowrap w-100">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
							{{ modalMode === "new" ? "Create" : "Save" }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
