import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewCartItem, ServerCartItem } from '../../model/cart.model';
import { CartService } from '../../services/cart.service';
import { NotificationsService } from 'angular2-notifications';
import { SessionApi } from '../../api/session.api';
import { Utils } from '../../util/utils';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: []
})
export class CartItemsComponent {
  cartItem: NewCartItem = new NewCartItem();
  showDialog = false;
  private updateTimer: any;
  public customerId: number;
  private _item: ServerCartItem[];
  @Output() cartUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set item(item: ServerCartItem[]) {
    for (let i of item) {
      let productImage = i.product.images.find(image => image.name === i.variation.colour)
      i.variation.imageUrl = productImage ? productImage.url : i.product.imageUrl as string | undefined;
    }
    this._item = item;
  }
  get item() {
    return this._item;
  }

  @Output()
  deleted: EventEmitter<ServerCartItem> = new EventEmitter();

  @Input()
  showPrice: ServerCartItem | undefined;

  constructor(
    public cartService: CartService,
    public notifications: NotificationsService,
    private sessionService: SessionApi,
    public utils: Utils
  ) { }

  ngOnInit() {
    this.sessionService.$customerData.subscribe((customer) => {
      if (customer && customer.id) {
        this.customerId = customer.id;
      }
    });
  }

  /**
   * @description pushes changes to the cart quantity to the server, and to the stored server objects. Has a delay to
   * wait for the user to finish making changes before the server is updated
   */
  public pushQuantityChange = (cartItem: ServerCartItem, quantity: number) => {
    const cartItems = cartItem.asNewCartItem();
    cartItems.pushQuantity();

    cartItems.incrementQuantity(quantity);

    cartItem.quantity = +cartItems.selectedQuantity;
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }

    this.updateTimer = setTimeout(() => {
      this.notifications.warn('Saving', 'Saving changes');

      const exist = cartItem.product.variations.find((data) => data.size === cartItem.variation.size && data.colour === cartItem.variation.colour);
      if (exist && exist.id) {
        cartItems.selectedColour = exist.id.toString();
      }
      this.cartService.updateCartItem(this.customerId, cartItems)
        .subscribe(() => {
          this.notifications.success('Saved', 'Changes Saved');
        });
    }, 1000);
  };

  getUniqueColors(product: any) {
    product.variations = product.variation;
    const cartItema = new NewCartItem(product);
    return cartItema.getUniqueColours();
  }

  colorChanged(item: ServerCartItem, colour: string) {
    const cartItems = item.asNewCartItem();
    const exist = item.product.variations.find((data) => data.colour === colour && data.size === item.variation.size);
    if (exist && exist.id) {
      cartItems.selectedColour = exist.id.toString();
    }
    this.cartService.updateCartItem(this.customerId, cartItems)
      .subscribe(() => {
        this.notifications.success('Saved', 'Changes Saved');
        this.cartUpdate.emit();
      });
  }

  getUniqueSize(product: any) {
    product.variations = product.variation;
    const cartItema = new NewCartItem(product);
    return cartItema.getUniqueSizes();
  }

  sizeChanged(item: ServerCartItem, size: string) {
    const cartItems = item.asNewCartItem();
    const exist = item.product.variations.find((data) => data.size === size && data.colour === item.variation.colour);
    if (exist && exist.id) {
      cartItems.selectedColour = exist.id.toString();
    }
    this.cartService.updateCartItem(this.customerId, cartItems)
      .subscribe(() => {
        this.notifications.success('Saved', 'Changes Saved');
        this.cartUpdate.emit();
      });
  }


  /**
   * @description Removes this item from the cart
   */
  deleteCartItem(item: ServerCartItem) {
    this.cartService.deleteCartItem(this.customerId, item.id)
      .pipe(
        debounceTime(1000)
      )
      .subscribe(() => {
        this.notifications.success('Saved', 'Removed Cart Item');
        this.deleted.emit(item);
      });
  }

  getUnitPrice(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.getUnitPrice();
  }

  getTotalPrice(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.getTotalPrice();
  }

  getPackBox(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.selectedPack;
  }

  getPrice(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.totalQuantity;
  }

}
