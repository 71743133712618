import { Component, OnDestroy, OnInit } from '@angular/core';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { NewCustomer } from '../model/ddb.model';
import { HasId } from '../model/generics';
import { Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { SubscriptionGroup } from '../util/subscriptionGroup';
import { CustomerService } from '../services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../template/model.component';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: []
})
export class CustomersComponent implements OnInit, OnDestroy {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public hasManageCustomersAccess: boolean = false;
  public isSuperAdmin: boolean | null = false;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  query: IQueryFilter = new IQueryFilter({
    filter: {},
    sortBy: 'name',
    order: 'desc'
  });

  sortOptions = [{
    id: 1,
    text: "Name",
    field: "name"
  }, {
    id: 2,
    text: "Code",
    field: "code"
  }];

  queryResult: QueryResult<HasId<number> & NewCustomer> = new QueryResult();
  isAlreadyChecked: boolean;

  constructor(
    private securityService: SecurityService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // this.route.queryParams.subscribe(queryparams => {
    //   this.currentPageActive = +queryparams['page'] || 1;
    //   this.query.limit = +queryparams['pageSize'] || 10;
    // this.loadCustomers();
    // });
    this.monitorAccess();
    this.search();
  }

  /**
   * @description UI helper method for retrieving the text of the selected sort option
   * @returns {string | undefined}
   */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };


  loadCustomers() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";
    this.customerService.list(this.query)
      .subscribe(queryResult => {
        this.totalCountActive = queryResult.count;
        this.queryResult = queryResult;

        if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
          this.isAlreadyChecked = true;
          this.query.skip = 0;
          this.currentPageActive = 1;
          this.loadCustomers();
        }
      });

  }

  monitorAccess() {
    this.subscriptionGroup.add(
      combineLatest(
        this.securityService.isSuperAdmin(),
        this.securityService.hasManageCustomerAccess()
      ).subscribe(([
        isSuperAdmin,
        hasManageCustomersAccess
      ]) => {
        this.isSuperAdmin = isSuperAdmin;
        this.hasManageCustomersAccess = this.isSuperAdmin || hasManageCustomersAccess;
      })
    );
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadCustomers();
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.loadCustomers();
  }

  updateSearchTerm(searchTerm: string) {
    this.searchTerms.next(searchTerm);
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.query.filter.name = { $like: '%' + searchTerm + '%' };
      this.loadCustomers();
    });
  }


  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadCustomers();
  }

  deleteCustomer(customer: HasId<number> & NewCustomer) {
    this.customerService.deleteCustomer(customer.id).subscribe(() => {
      this.loadCustomers();
    });
  }

  public openRemoveModal(customer: HasId<number> & NewCustomer) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Customer';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Customer</h4>
					<p class="desc">Would you like to remove this customer?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && customer) {
          this.deleteCustomer(customer);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }
}
