import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '../../services/group.service';
import { CustomerService } from '../../services/customer.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { IGroup } from '../../model/group.model';
import { ModalComponent } from '../../template/model.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-group-tab',
  templateUrl: './group-tab.component.html',
  styleUrls: []
})
export class GroupTabComponent implements OnInit {
  public groupId: string;
  public count: IGroup;
  public queryResult: IGroup;
  public activeTabIdx: number = 0;
  customerId: string | null;
  constructor(
    public route: ActivatedRoute,
    private groupService: GroupService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.customerId = this.route.snapshot.queryParamMap.get('customerId');
  }

  getTabs(): string[] {
    return ['Overview', 'Collections'];
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.groupId = params['groupId'];

      if (this.groupId) {
        this.loadGroup(this.groupId);
      }
    });
  }

  loadGroup(groupId: string) {
    this.groupService.get(groupId)
      .subscribe((group: IGroup) => {
        this.queryResult = group;
        this.count = group;
      });
  }

  deleteGroup(groupId: string | undefined) {
    if (groupId) {

      const modal = this.modalService.open(ModalComponent, {
        scrollable: false,
        size: 'sm',
        centered: true,
        backdrop: true,
        windowClass: 'deleteModal'
      });

      if (modal.componentInstance) {
        const component = modal.componentInstance as ModalComponent;

        component.title = 'Delete Group';
        component.showIcon = true;
        component.data = `
          <div>
            <h4 class="title">Remove Group</h4>
            <p class="desc">Would you like to remove this group?</p>
          </div>`;
        component.buttons = [{
          text: 'Remove',
          action: 'close',
          value: true,
          class: 'btn-danger'
        }, {
          text: 'Cancel',
          action: 'close',
          value: false,
          class: 'btn btn-secondary'
        }]
      }

      modal.result
        .then((isDelete) => {
          if (isDelete && groupId) {
            this.groupService.delete(+groupId).subscribe(() => {
              this.getRoute();
            });
          }
        })
        .catch(() => {
          modal.dismiss();
        });
    }
  }

  getRoute() {
    if (!this.customerId) {
      this.router.navigate(['/manage/users'], { queryParams: { activeTab: 1 } });
    } else {
      this.router.navigate(['/manage/customer/edit', this.customerId], { queryParams: { activeTab: 5 } });
    }
  }
}
