import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { UIModule } from './ui/ui.module';
import { MainTemplateComponent } from './template/main-template/main-template.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AuthApi } from './api/auth.api';
import { SessionApi } from './api/session.api';
import { AuthService } from './services/auth.service';
import { SecurityService } from './services/security.service';
import { WebSocketService } from './services/webSocket.service';
import { WebsocketInterceptor } from './interceptor/webSocket.interceptor';
import { RolesApi } from './api/roles.api';
import { JwtService } from './services/jwt.service';
import { ConversionService } from './services/conversion.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppHeaderComponent } from './template/app-header/app-header.component';
import { LoginComponent } from './auth/login/login.component';
import { MobileMenuComponent } from './template/mobile-menu/mobile-menu.component';
import { AuthTemplateComponent } from './template/auth-template/auth-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { AuthGuard } from './guard/auth.guard';
import { UnAuthGuard } from './guard/unauth.guard';
import { UserRoleGuard } from './guard/user_role.guard';
import { RoleGuard } from './guard/role.guard';
import { PermissionsGuard } from './guard/permissions.guard';
import { ProductService } from './services/product.service';
import { ProductApi } from './api/product.api';
import { Utils } from './util/utils';
import { CategoryApi } from './api/category.api';
import { CategoryService } from './services/category.service';
import { CartApi } from './api/cart.api';
import { CartService } from './services/cart.service';
import * as Sentry from '@sentry/angular-ivy';
import { AdminApi } from './api/admin.api';
import { CollectionApi } from './api/colllection.api';
import { CustomerDomainApi } from './api/customerDomain.api';
import { CustomerUserApi } from './api/customerUser.api';
import { CollectionService } from './services/collection.service';
import { CustomerUserService } from './services/customerUser.service';
import { SSOService } from './services/sso.service';
import { StorageService } from './services/storage.service';
import { AppTemplateComponent } from './template/app-template/app-template.component';
import { MenuComponent } from './template/menu/menu.component';
import { LoadingService } from './services/core/loading.service';
import { VersionService } from './services/core/version.service';
import { ResetComponent } from './auth/reset/reset.component';
import { ModalComponent } from './template/model.component';
import { RegisterComponent } from './auth/register/register.component';
import { ShippingDeliveryComponent } from './view/shipping-delivery/shipping-delivery.component';
import { ReturnsExchangesComponent } from './view/returns-exchanges/returns-exchanges.component';
import { UnauthProductPageComponent } from './view/unauth-product-page/unauth-product-page.component';
import { NoNegativeDirective } from './view/directive/negative.directive';
import { InputMaxLengthDirective } from './directive/input-max-length.directive';
import { GawunTemplateComponent } from '../gawun/gawun-template/gawun-template.component';
import { environment } from 'src/environments/environment';
import { BlogsComponent } from './view/blogs/blogs.component';
import { BlogsdetailComponent } from './view/blogs/blogsdetail/blogsdetail.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    MainTemplateComponent,
    AppHeaderComponent,
    LoginComponent,
    MobileMenuComponent,
    AuthTemplateComponent,
    AppTemplateComponent,
    MenuComponent,
    ResetComponent,
    ModalComponent,
    RegisterComponent,
    ReturnsExchangesComponent,
    ShippingDeliveryComponent,
    UnauthProductPageComponent,
    NoNegativeDirective,
    InputMaxLengthDirective,
    GawunTemplateComponent,
    BlogsComponent,
    BlogsdetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UIModule,
    SimpleNotificationsModule.forRoot({
      timeOut: 3500,
      showProgressBar: true,
      clickToClose: true,
    }),
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
		environment.analyticsTrackingCode ? NgxGoogleAnalyticsModule.forRoot(environment.analyticsTrackingCode) : [],
		environment.analyticsTrackingCode ? NgxGoogleAnalyticsRouterModule : []
  ],
  providers: [
    AuthApi,
    AuthService,

    JwtService,
    StorageService,

    AuthGuard,
    UnAuthGuard,
    RoleGuard,
    UserRoleGuard,
    PermissionsGuard,

    SessionApi,
    SecurityService,

    RolesApi,

    ConversionService,

    CartApi,
    CartService,

    ProductApi,
    ProductService,

    CustomerUserApi,
    CustomerUserService,

    CollectionApi,
    CollectionService,

    AdminApi,

    CustomerDomainApi,

    CategoryApi,
    CategoryService,

    Utils,

    SSOService,

    WebSocketService,
    WebsocketInterceptor,

    LoadingService,
    VersionService,

    /** Sentry */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },

    { provide: 'initialHref', useValue: window.location.href },
  ],
  bootstrap: [environment.appName === 'GAWN' ? GawunTemplateComponent : MainTemplateComponent],
  exports: [],
})
export class AppModule { }
