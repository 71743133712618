import { Injectable } from '@angular/core';
import { SessionApi } from '../api/session.api';
import { RolesApi } from '../api/roles.api';
import { Observable, combineLatest, debounceTime, distinctUntilChanged, interval, map, of, startWith, switchMap } from 'rxjs';
import { IAuthCustomer } from '../model/customer.model';
import { IAuthUser } from '../model/user.model';
import { IRole } from '../model/role.model';

const className = "SecurityService";

@Injectable()
export class SecurityService {
	constructor(
		private readonly userRolesApi: RolesApi,
		private session: SessionApi,
	) { }

	/** Determines if collections should be displayed on prduct search */
	showCollectionsInProductSearch(): boolean {
		const customer = this.session.$customerData.getValue();
		if (!customer) return false;

		return customer.showProductSearchCollections;
	}

	/** Detremines if product categories should be shown in product search */
	showCategoriesInProductSearch(): boolean {
		const customer = this.session.$customerData.getValue();
		if (!customer) return true;

		return customer.showProductSearchCategories;
	}

	isAdmin(): boolean {
		const user = this.session.$userData.getValue();
		if (!user) return false;

		return user.isAdmin;
	}

	/**
	 * @description Observable to determine if the current user has any administrative access
	 */
	isAdmin$(): Observable<boolean | null> {
		return this.session.$userData.pipe(
			map(data => {
				return data && data.isAdmin;
			})
		)
	}

	/**
	 * @description Determines if the currently authenticated user is a 'superAdmin' user
	 * @returns {Observable<boolean>}
	 */
	isSuperAdmin(): Observable<boolean> {
		return this.session.$userData.pipe(
			map(data => !!(data && data.isAdmin && data.adminRoleId === null))
		)
	}

	/**
	 * @description Determines if the currently authenticated user is a 'superAdmin' user
	 * @returns {Observable<boolean>}
	 */
	actualSuperAdmin(): Observable<boolean> {
		return this.session.$userData.pipe(
			map(data => !!(data && data.actual && data.actual.isAdmin && data.actual.adminRoleId === null))
		)
	}

	isEmulatingUser(): Observable<boolean> {
		return this.session.$userData.pipe(
			map(data => !!(data && data.actual))
		)
	}

	/**
	 * Every 500ms, check if the current user is
	 *
	 * @param {string} permission The permission to search for
	 * @param {number} frequency
	 * @returns {Observable<boolean>}
	 */
	public isLoggedInWithPermission(desiredPermissions: string | string[], mode: 'ANY' | 'ALL' = 'ALL', isAdmin: boolean = false, isActualUser: boolean = false): Observable<boolean> {
		const permission: string[] = Array.isArray(desiredPermissions) ? desiredPermissions : [desiredPermissions];
		return combineLatest([
			this.session.$roleData,
			this.session.$userData,
			this.session.$customerData,
		])
			.pipe(
				debounceTime(100),
				switchMap(([role, user, customerData]) => {
					const targetUser = !isActualUser ? user : user && user.actual!;

					if (!targetUser) {
						// No user, not possible to pass
						return of(false);
					}

					if (
						// If the permission expects a "ADMIN" and the user is a "USER" (customerUser)
						(isAdmin && !targetUser.isAdmin)
						// If the permission expects a "USER" (customerUser) and the user is an "ADMIN"
						|| (!isAdmin && targetUser.isAdmin)
					) {
						return of(false);
					}

					// Evaluate the role/user of the currently logged in user
					if (!isActualUser) {
						// If a role was found
						if (role) {
							// Check for access
							// The user must have all the supplied permissions
							if (mode === 'ALL') {
								return of(this.userRolesApi.roleHasPermission(role, ...permission));
							}

							// The user must have any of the supplied permissions
							if (mode === 'ANY') {
								return of(this.userRolesApi.roleHasAnyPermission(role, ...permission));
							}
						}
					} else {
						let roleObservable: Observable<IRole | null | undefined> = of(targetUser.adminRole);

						if (!targetUser.isAdmin) {
							if (!customerData) {
								// Unexpected bailout here
								return of(false);
							}

							const customerUser = (targetUser.customerUsers || []).find(c => c.customerId === customerData.id);

							if (!customerUser) {
								// Unexpected bailout here
								return of(false);
							}

							roleObservable = this.userRolesApi.roleById(customerUser.userRoleId);

							if (mode === 'ALL') {
								return roleObservable.pipe(
									map(role => role ? this.userRolesApi.roleHasPermission(role, ...permission) : false)
								);
							}

							// The user must have any of the supplied permissions
							if (mode === 'ANY') {
								return roleObservable.pipe(
									map(role => role ? this.userRolesApi.roleHasAnyPermission(role, ...permission) : false)
								);
							}
						}
					}

					// Default to no access
					return of(false);
				}),
				distinctUntilChanged()
			);
	}

	canCheckout(): Observable<boolean> {
		return combineLatest([
			this.isStopCredit(),
			this.isObsolete(),
			this.isEmulatingUser(),
			this.actualSuperAdmin(),
			this.isLoggedInWithPermission('switch_account_users', 'ANY', false, true),
			this.isLoggedInWithPermission('place_order', 'ANY', true, true)
		]).pipe(
			map(([stopCredit, obsolete, isEmulatingUser, actualSuperAdmin, actualSwitchUsers, actualPlaceOrder]) => {
				return !stopCredit && !obsolete && (!isEmulatingUser || actualSwitchUsers || actualSuperAdmin || actualPlaceOrder);
			})
		)
	}

	/**
	 * Checks if the currently authenticated user has a role with cart_access
	 *
	 * @returns {Observable<boolean>}
	 */
	hasCartAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('cart_access');
	}

	/**
	 * Checks if the user has cart access right this second
	 */
	hasCartAccessRightNow(): boolean | null {
		const role = this.session.$roleData.getValue();

		return role && this.userRolesApi.roleHasPermission(role, 'cart_access');
	}

	/**
	 * Checks if the currently authenticated user has a role with product_access
	 *
	 * @returns {Observable<boolean>}
	 */
	hasProductAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('product_access');
	}

	/**
	 * Checks if the currently authenticated user has a role with update_own_detail
	 *
	 * @returns {Observable<boolean>}
	 */
	hasOwnDetailAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('update_own_detail');
	}

	/**
	 * Checks if the currently authenticated user has the right to see order data
	 *
	 * @returns {Observable<boolean>}
	 */
	hasManageOrderAccess(): Observable<boolean> {
		return combineLatest([
			this.isAdmin$(),
			this.isLoggedInWithPermission(['account_orders', 'cart_access'], "ANY")
		]).pipe(
			map(([isAdmin, hasOrderAccess]) => {
				return isAdmin || hasOrderAccess;
			})
		);
	}

	/**
 * Checks if the currently authenticated user has a role that allows them to enter an arbitrary shipping addres
 *
 * @returns {Observable<boolean>}
 */
	hasEditShippingAddress() {
		return this.isLoggedInWithPermission('edit_shipping_address');
	}
	/**
	 * Checks if the currently authenticated user has a role that allows them to select shipping addres
	 *
	 * @returns {Observable<boolean>}
	 */
	hasSelectAccountAddress() {
		return this.isLoggedInWithPermission('select_account_address');
	}

	/**
	 * Checks if the currently authenticated user has a role that allows them to list users attached to the account
	 *
	 * @returns {Observable<boolean>}
	 */
	canListUsers(): Observable<boolean> {
		return this.isLoggedInWithPermission(['list_account_users', 'edit_account_users'], 'ANY');
	}

	/**
 * Checks if the currently authenticated user has a role that allows them to list users attached to the account
 *
 * @returns {Observable<boolean>}
 */
	canExploreProducts(): Observable<boolean> {
		return this.isLoggedInWithPermission('explore_products');
	}

	/**
 * Checks if the user has cart access right this second
 */
	canUserApproveOrder(): Observable<boolean> {
		return this.isLoggedInWithPermission('any_order_approval');
	}

	/**
	 * Checks if the currently authenticated user has a role with edit_account_users
	 *
	 * @returns {Observable<boolean>}
	 */
	canEditUsers(): Observable<boolean> {
		return this.isLoggedInWithPermission('edit_account_users');
	}

	/**
	 * @description Checks if the currently authenticated user has a role with switch_account_users
	 *
	 * @returns {Observable<boolean>}
	 */
	canSwitchUsers(): Observable<boolean> {
		return combineLatest([
			this.isSuperAdmin(),
			this.isLoggedInWithPermission('switch_account_users'),
			this.isLoggedInWithPermission('switch_users', 'ANY', true)
		]).pipe(
			map(result => !!result.find(p => p))
		)
	}

	/**
	 * Checks if the currently authenticated user has a role with edit_business_units
	 *
	 * @returns {Observable<boolean>}
	 */
	canEditBusinessUnits(): Observable<boolean> {
		return this.isLoggedInWithPermission('edit_business_units');
	}

	/**
	 * Checks if the currently authenticated user has any of the roles that would require them to access the account screens
	 *
	 * @returns {Observable<boolean>}
	 */
	hasAccountAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'update_own_detail',
			'cart_access',
			'always_edit_addresses',
			'user_allocation',
			'edit_account_users',
			'edit_business_units',
			'order_approver'
		], 'ANY');
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage customers
	 * @returns {Observable<boolean>}
	 */
	hasManageCustomerAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_customers'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage groups
	 * @returns {Observable<boolean>}
	 */
	hasManageGroupsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_groups'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage products
	 * @returns {Observable<boolean>}
	 */
	hasManageProductsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_products'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage decorations
	 * @returns {Observable<boolean>}
	 */
	hasManageDecorationsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_decorations'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage collections
	 * @returns {Observable<boolean>}
	 */
	hasManageCollectionsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_collections'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage sizeCharts
	 * @returns {Observable<boolean>}
	 */
	hasManageSizeChartsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_sizecharts'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage categories
	 * @returns {Observable<boolean>}
	 */
	hasManageCategoriesAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_categories'
		], "ALL", true);
	}

	/**
	 * @description Checks if the currently authenticated user has the right to manage brands
	 * @returns {Observable<boolean>}
	 */
	hasManageBrandsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission([
			'manage_brands'
		], "ALL", true);
	}

	/**
	 * Checks if the currently authenticated user has any of the roles that would require them to access any reports
	 */
	hasReportsAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission(['report_allocation'], 'ANY');
	}

	/**
 * Checks if the currently authenticated user has any of the roles that would require them to access any reports
 */
	hasReportsOrderAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission(['report_order'], 'ANY');
	}

	/**
	 * Checks if the currently authenticated user has the right to access the bulk order functionality
	 */
	hasBulkOrderAccess(): boolean {
		if (this.isAdmin()) return true;

		const customer = this.session.$customerData.getValue();
		if (!customer) return true;

		return customer.enableBulkOrder;
	}

	/**
	 * Ensures the current user does not have a block preventing them from editing any address in the system
	 *
	 * @returns {Observable<boolean>}
	 */
	canEditAddresses(): Observable<boolean> {
		return combineLatest(
			interval(500),
			this.isLoggedInWithPermission('always_edit_addresses'),
		).pipe(
			startWith([0, false]),
			map(tick => {
				if (this.isAdmin())
					return true;

				return tick[1] as boolean;
			}),
			distinctUntilChanged()
		)
	}

	/**
	 * Determine if the current user has the ability to show allocation balances
	 *
	 * @returns {Observable<boolean>}
	 */
	showOwnBalance(): Observable<boolean> {
		return combineLatest([
			interval(500),
			this.isLoggedInWithPermission('user_allocation'),
		]).pipe(
			startWith([0, false]),
			map(([_tick, hasPermission]) => {
				const customerUser = this.session.$customerUser.getValue();
				if (!customerUser || !customerUser.userAllocations || !customerUser.userAllocations.length) {
					return false;
				}

				const firstActiveAllocation = customerUser.userAllocations.find(ua => ua.frequency !== 'disabled');
				if (!firstActiveAllocation) {
					return false;
				}

				return hasPermission as boolean;
			}),
			distinctUntilChanged()
		)
	}

	/**
	 * Checks if the currently authenticated user should hae access to pack-order-names functionality
	 *
	 * @returns {Observable<boolean>}
	 */
	allowPackOrderNames(): Observable<boolean> {
		return this.sessionCustomerProperty("allowPackOrderNames", false);
	}

	/**
	 * @description Responsible for notifying the rest of the application that the current user is on a StopCredit and should not be able to check out. Should return false
	 * 	for an unauthenticated user or a user with no customer data defined.
	 */
	isStopCredit(): Observable<boolean> {
		return this.sessionCustomerProperty("stopCredit", false);
	}

	/**
	 * @description Responsible for notifying the rest of the application that the current user is on a StopCredit and should not be able to check out. Should return false
	 * 	for an unauthenticated user or a user with no customer data defined.
	 */
	isObsolete(): Observable<boolean> {
		return this.sessionCustomerProperty("obsolete", false);
	}

	/**
 * @description Responsible for notifying the rest of the application that the current user is on a automatic_collection_selection and should. Should return false
 * 	for an unauthenticated user or a user with no customer data defined.
 */
	isAutomaticCollectionSelection(): Observable<boolean> {
		return this.sessionCustomerProperty("automaticCollectionSelection", false);
	}

	/**
* @description Responsible for notifying the rest of the application that the current user is on a automatic_collection_selection and should. Should return false
* 	for an unauthenticated user or a user with no customer data defined.
*/
	isUserRequireApprovalManager(): Observable<boolean> {
		return this.sessionCustomerProperty("enableUsersRequireApproval", false);
	}

	/**
	 * @description Returns an arbitrary property from the authCustomer, or the default value when no authCustomer can be found
	 * @param {keyof IAuthCustomer} prop
	 * @param {IAuthCustomer[typeof prop]} defaultVal
	 * @returns {typeof IAuthCustomer[typeof prop]}
	 */
	sessionCustomerProperty<P extends keyof IAuthCustomer, R = IAuthCustomer[P]>(prop: P, defaultVal: R): Observable<IAuthCustomer[P] | R> {
		return this.session.$customerData.pipe(
			map(customerData => {
				if (customerData) {
					return customerData[prop];
				}

				return defaultVal;
			})
		)
	}

	/**
	 * @description Returns an arbitrary property from the authUser, or the default value when no authCustomer can be found
	 * @param {keyof IAuthUser} prop
	 * @param {IAuthUser[typeof prop]} defaultVal
	 * @returns {typeof IAuthUser[typeof prop]}
	 */
	sessionUserProperty<P extends keyof IAuthUser>(prop: P, defaultVal: IAuthUser[P]): Observable<IAuthUser[P]> {
		return this.session.$userData.pipe(
			map(userData => {
				if (userData) {
					return userData[prop];
				}

				return defaultVal;
			})
		)
	}

	/**
	 * @description Simple way to determine if the current user is Authenticated
	 * @returns {Observable<boolean>}
	 */
	isAuthenticated(): Observable<boolean> {
		return combineLatest(
			this.session.$sessionChanged,
			this.session.$userData
		).pipe(
			map(([sessionInit, userData]) => {
				return !!(sessionInit && userData);
			})
		)
	}

	/**
	 * @description Checks if credit card payment can be used based on customer purchase mode.
	 *
	 * @returns {Observable<boolean>} An Observable that emits a boolean value indicating if credit card payment can be used.
	 */
	canUseCreditCardPayment(): Observable<boolean> {
		return this.sessionCustomerProperty('employeePurchaseMode', 'credit-card').pipe(
			map(purchaseMode => ['credit-card', 'both'].includes(purchaseMode))
		);
	}

	/**
	 * @description Checks if invoice payment can be used based on customer and user permissions.
	 *
	 * @returns {Observable<boolean>} An Observable that emits a boolean value indicating if invoice payment can be used.
	 */
	canUseInvoicePayment(): Observable<boolean> {
		return combineLatest([
			this.sessionCustomerProperty('employeePurchaseMode', 'credit-card').pipe(
				map(purchaseMode => ['invoice', 'both'].includes(purchaseMode))
			),
			this.isLoggedInWithPermission('payment_gateway_access_always'),
			this.isLoggedInWithPermission('payment_gateway_access_always', 'ANY', false, true),
			this.actualSuperAdmin()
		]).pipe(
			map(([customerPermission, userPermission, emulatedPermission, actualSuperAdmin]) => {
				return customerPermission || userPermission || emulatedPermission || actualSuperAdmin;
			})
		)
	}

	/**
	 * Checks if the currently authenticated user has a group with modify_user_groups
	 *
	 * @returns {Observable<boolean>}
	 */
	canModifyUserGroups(): Observable<boolean> {
		return this.isLoggedInWithPermission('modify_user_groups');
	}

	/**
	 * Checks if the currently authenticated user has a group with list_document_access
	 *
	 * @returns {Observable<boolean>}
	 */
	canListDocumentAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('list_document_access');
	}

	/**
	 * Checks if the currently authenticated user has a group with edit_document_access
	 *
	 * @returns {Observable<boolean>}
	 */
	canEditDocumentAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('edit_document_access');
	}

	/**
 * Checks if the currently authenticated user has a allocation with user_allocation
 *
 * @returns {Observable<boolean>}
 */
	canAllocationAccess(): Observable<boolean> {
		return this.isLoggedInWithPermission('user_allocation');
	}
}

