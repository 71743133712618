import { Component, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { logger } from "../util/Logger";

type ModalButton<T = unknown> = { text: string, action: 'dismissAll' | 'close', value?: T, class?: string };

const className = 'ModalComponent';

@Component({
	selector: 'modal',
	template: `
          <div class="modal-header">
	<h4 class="modal-title" id="twoFactorauthLabel">{{title}}</h4>
</div>
<div class="modal-body">
	<div class="delete-content">
		<div class="dlt-icon" *ngIf="showIcon">
			<span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
						stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
		</div>
		<div [innerHTML]="data"></div>
	</div>
	<div class="btns d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-25">
		<button *ngFor="let button of buttons" type="button" class="btn {{button.class || 'btn-secondary'}} w-sm-100"
			(click)="buttonAction(button)">
			{{button.text}}
		</button>
	</div>
</div>
    `
})
export class ModalComponent implements OnDestroy {
	@Input() data: any;
	@Input() title: string = 'Your report is ready.';
	@Input() buttons: ModalButton[] = [{
		text: 'Close',
		action: 'dismissAll'
	}];
	@Input() showIcon: boolean;

	constructor(
		private ngbModal: NgbModal,
		private ngbActiveModal: NgbActiveModal
	) { }

	buttonAction(button: ModalButton) {
		const signature = className + '.buttonAction: ';
		if (typeof button.action === 'string') {
			switch (button.action) {
				case 'dismissAll': {
					this.ngbModal.dismissAll();
					return;
				}
				case 'close': {
					this.ngbActiveModal.close(typeof button.value !== 'undefined' ? button.value : button.text);
					return;
				}
				default:
					logger.error(signature + `Unknown ButtonAction[${button.action}]`);
			}
		}

		logger.error(signature + `Unsure how to handle ButtonAction with Type[${typeof button.action}]`);
	}

	ngOnDestroy(): void {
		this.ngbModal.dismissAll();
	}

	dismissAll() {
		this.ngbModal.dismissAll();
	}
}
