import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { AuthService } from 'src/app/main/services/auth.service';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { CollectionService } from 'src/app/main/services/collection.service';
import { ModalComponent } from 'src/app/main/template/model.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: []
})
export class UserListComponent implements OnChanges, OnDestroy {
  public canSwitchUsers$ = this.securityService.canSwitchUsers();

  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  customerId: string | undefined;

  /** Page Access */
  canEditUsers: boolean = false;
  canModifyUserGroups: boolean = false;
  isAdmin: boolean | null = false;
  isEmploye: boolean = false;
  /** / Page Access */

  @Input()
  isGuest: boolean;
  @Input()
  tab: string;

  query: IQueryFilter = new IQueryFilter({
    filter: {},
    sortBy: 'first_name',
    order: 'desc',
    include: [{ association: 'user', required: true }, { association: 'customer', required: true }, { association: 'userRole', required: true }]
  });
  private readonly subscriptionGroup = new SubscriptionGroup();
  public currentUser: ICustomerUser;
  sortOptions = [{
    id: 1,
    text: "First Name",
    field: "first_name"
  }, {
    id: 2,
    text: "Last Name",
    field: "last_name"
  }, {
    id: 3,
    text: "Email",
    field: "email"
  }];

  queryResult: QueryResult<ICustomerUser> = new QueryResult();
  private unsubscribe$ = new Subject<void>();
  public isLoading = false;
  isAlreadyChecked: boolean = false;
  constructor(
    private customerUserService: CustomerUserService,
    public route: ActivatedRoute,
    private readonly session: SessionApi,
    private readonly securityService: SecurityService,
    private authenticationService: AuthService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private collectionService: CollectionService
  ) {

    this.route.params.subscribe(params => {
      if (params['customerId']) {
        this.query.filter['customerId'] = params['customerId'];
        this.query.filter.isGuest = false;
        this.monitorAccess();
      } else if (!this.session.$userData.getValue()?.isAdmin) {
        this.isEmploye = true;
        this.session.$customerData.pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(customer => {
          this.query.filter['customerId'] = customer?.id.toString();
          this.customerId = customer?.id.toString();
          this.monitorAccess();
        })

      } else {
        this.monitorAccess();
      }
    });
    this.search();
  }

  ngOnChanges(): void {
    if (this.isGuest) {
      this.query.filter.isGuest = true;
      this.loadCustomerUsers();
    }
  }

  /**
   * @description UI helper method for retrieving the text of the selected sort option
   * @returns {string | undefined}
   */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  /**
   * @description Loads the customer user data from the API
   */
  loadCustomerUsers() {
    this.isLoading = true;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    let subscription;
    if (this.query.filter['customerId']) {
      subscription = this.customerUserService.list(this.query, { params: { includeAllocationBalance: true } });
    } else {
      subscription = this.customerUserService.getAllUsers(this.query, { params: { includeAllocationBalance: true } });
    }
    subscription.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(queryResult => {
      this.isLoading = false;
      this.totalCountActive = queryResult.count;
      this.queryResult = queryResult;
      if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
        this.isAlreadyChecked = true;
        this.query.skip = 0;
        this.currentPageActive = 1;
        this.loadCustomerUsers();
      }
    });

  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).subscribe(searchTerm => {
      if (this.query.include) {
        const userInclude = this.query.include[0];

        if (!userInclude.filter) userInclude.filter = {};

        if (searchTerm && searchTerm.length)
          userInclude.filter['$or'] = [
            { firstName: { $like: '%' + searchTerm + '%' } },
            { lastName: { $like: '%' + searchTerm + '%' } },
            { email: { $like: '%' + searchTerm + '%' } },
            {
              ['$and']: [
                { firstName: { $like: '%' + searchTerm.split(' ')[0] + '%' } },
                { lastName: { $like: '%' + (searchTerm.split(' ')[1] || '') + '%' } }
              ]
            }
          ];
        else
          delete userInclude.filter['$or'];
      }
      this.loadCustomerUsers();
    });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadCustomerUsers()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadCustomerUsers();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadCustomerUsers();
  }

  /**
  * @description Monitors various access checks and updates the local viewstate where appropriate
  * @returns {void}
  */
  monitorAccess(): void {
    this.subscriptionGroup.add(this.securityService.isSuperAdmin().subscribe(admin => this.isAdmin = admin));
    this.subscriptionGroup.add(this.securityService.canEditUsers().subscribe(canEditUsers => this.canEditUsers = (this.isAdmin || canEditUsers)));
    this.subscriptionGroup.add(this.securityService.canModifyUserGroups().subscribe(canModifyUserGroups => this.canModifyUserGroups = (this.isAdmin || canModifyUserGroups)));
    if (this.authenticationService.isAuthenticated()) {
      this.route.queryParams.subscribe(params => {
        this.currentPageActive = +params['page'] || 1;
        this.query.limit = +params['pageSize'] || 10;
        // this.loadCustomerUsers();
      });
    }
  }

  switchUser(userId: Number | undefined, customerId: number | undefined) {
    if (customerId && userId) {
      this.authenticationService.switchUser(customerId, +userId).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.collectionService.loadCollections();
        this.notifications.success('Switch', 'Your access was updated');
        this.authenticationService.navigateToDefaultUrl();
      }, error => {
        this.notifications.error('Switch', 'Something wrong');
      });
    }
  }

  deleteCustomerUser(userId: number | undefined) {
    if (userId) {
      this.customerUserService.delete(userId).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.loadCustomerUsers();
      })
    }
  }

  getDetailLink(customerId: number, userId: number | undefined) {
    if (this.isEmploye && userId) {
      return `/account/user/${userId}/customer/${customerId}`;
    } else if (userId) {
      return `/manage/users/edit/${customerId}/user/${userId}`;
    }
  }

  public openRemoveModal(userId: number | undefined) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Document';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Document</h4>
					<p class="desc">Would you like to remove this document?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && userId) {
          this.notifications.warn('Processing', 'Deleting the user.');
          this.deleteCustomerUser(userId);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  public openUserModel(item: ICustomerUser) {
    this.currentUser = item;
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
