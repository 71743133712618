import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, combineLatest, debounce, debounceTime, distinctUntilChanged, filter, interval, switchMap, takeUntil } from 'rxjs';
import { IQueryFilter, QueryResult, ScopeOption } from '../../model/query.filter.class';
import { NewProduct } from '../../model/ddb.model';
import { SessionApi } from '../../api/session.api';
import { ProductService } from '../../services/product.service';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { SecurityService } from '../../services/security.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../template/model.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: []
})
export class ProductListComponent implements OnDestroy {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  private updateProductRequests$ = new BehaviorSubject<null>(null);
  private unsubscribe$ = new Subject<void>();
  public isLoading = false;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    scope: 'ExcludeArchived'
  });

  sortOptions = [{
    text: "Product Name",
    field: "name"
  }, {
    text: "Price",
    field: "basePrice"
  }, {
    text: "Modified Date",
    field: "updatedAt"
  }, {
    text: "Modified By",
    field: "modified_by"
  }];

  readonly scopeOptions = [{
    text: "Exclude Archived (Default)",
    scope: "ExcludeArchived"
  }, {
    text: "Include Archived",
    scope: "IncludeArchived"
  }, {
    text: "Archived Only",
    scope: "ArchivedOnly"
  }]

  queryResult: QueryResult<NewProduct> = new QueryResult();
  public hasManageProductsAccess: boolean = false;
  public isSuperAdmin: boolean = false;
  private readonly subscriptionGroup = new SubscriptionGroup();
  isAlreadyChecked: boolean;

  constructor(
    private session: SessionApi,
    private productService: ProductService,
    private securityService: SecurityService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private route: ActivatedRoute
  ) {
    this.handleProductUpdateRequests();
    // this.route.queryParams.subscribe(queryparams => {
    //   this.currentPageActive = +queryparams['page'] || 1;
    //   this.query.limit = +queryparams['pageSize'] || 10;
    //   this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    //   this.loadProducts();
    // });
    this.search();
    this.monitorAccess();
  }

  /**
 * Monitors various access checks and updates the local viewstate where appropriate
 */
  monitorAccess() {
    this.subscriptionGroup.add(
      combineLatest(
        this.securityService.isSuperAdmin(),
        this.securityService.hasManageProductsAccess(),
      ).subscribe(([
        isSuperAdmin,
        hasManageProductsAccess,
      ]) => {
        if (isSuperAdmin) {
          this.isSuperAdmin = isSuperAdmin;
        }
        this.hasManageProductsAccess = this.isSuperAdmin || hasManageProductsAccess;
      })
    );
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    this.loadProducts();
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadProducts();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadProducts();
  }

  loadProducts() {
    if (this.query.sortBy === "updated_at" || this.query.sortBy === "modified_by")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.updateProductRequests$.next(null);
  }

  handleProductUpdateRequests() {
    this.isLoading = true;
    this.session.$userData.pipe(
      filter(data => !!data),
      switchMap(() => this.updateProductRequests$),
      debounce(() => interval(100)),
      takeUntil(this.unsubscribe$),
      switchMap(() => this.productService.list(this.query))
    ).subscribe(queryResult => {
      this.queryResult = queryResult;
      this.totalCountActive = queryResult.count;
      this.isLoading = false;

      if (this.totalCountActive > 0 && queryResult.rows.length == 0 && !this.isAlreadyChecked) {
        this.isAlreadyChecked = true;
        this.query.skip = 0;
        this.currentPageActive = 1;
        this.loadProducts();
      }
    })
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter['$or'] = {
          name: { $like: '%' + searchTerm + '%' },
          code: { $like: '%' + searchTerm + '%' },
          uri: { $like: '%' + searchTerm + '%' },
          description: { $like: '%' + searchTerm + '%' }
        }
      else
        delete this.query.filter['$or'];

      this.loadProducts();
    });
  }

  /**
* @description clone a product
*
* @param {string} productId
*/
  cloneProduct(product: NewProduct) {
    this.productService.cloneProduct(product).subscribe((response: any) => {
      this.loadProducts();
    });
  }

  /**
 * @description Archives a product
 *
 * @param {string} productId
 */
  deleteProduct(productId: number | undefined) {
    if (productId) {
      this.productService.deleteProduct(productId).subscribe(() => {
        this.loadProducts();
      });
    }
  }

  updateScopeField(scope: string) {
    this.query.scope = scope as ScopeOption;
    this.loadProducts();
  }

  /**
* @description UI helper method for retrieving the text of the selected scope option
* @returns {string | undefined}
*/
  getScopeText = (): string | undefined => {
    if (!this.query.scope)
      return undefined;

    const scopeOption = this.scopeOptions.find(option => option.scope === this.query.scope);

    if (scopeOption)
      return scopeOption.text;
    return undefined;
  };

  /**
 * @description Restores a product from archived to unarchived
 *
 * @param {string} productId
 */
  restoreProduct(productId: number | undefined) {
    if (productId) {
      this.productService.restoreProduct(productId).subscribe(() => {
        this.loadProducts();
      })
    }
  }

  public openRemoveModal(productId: number | undefined) {
    const modal = this.modalService.open(ModalComponent, {
      scrollable: false,
      size: 'sm',
      centered: true,
      backdrop: true,
      windowClass: 'deleteModal'
    });

    if (modal.componentInstance) {
      const component = modal.componentInstance as ModalComponent;

      component.title = 'Delete Product';
      component.showIcon = true;
      component.data = `
				<div>
					<h4 class="title">Remove Product</h4>
					<p class="desc">Would you like to remove this product?</p>
				</div>`;
      component.buttons = [{
        text: 'Remove',
        action: 'close',
        value: true,
        class: 'btn-danger'
      }, {
        text: 'Cancel',
        action: 'close',
        value: false,
        class: 'btn btn-secondary'
      }]
    }

    modal.result
      .then((isDelete) => {
        if (isDelete && productId) {
          this.notifications.warn('Processing', 'Deleting the product.');
          this.deleteProduct(productId);
        }
      })
      .catch(() => {
        modal.dismiss();
      });
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
