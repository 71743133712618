import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, combineLatest, first } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { CategoryApi } from '../api/category.api';
import { Brand, EnumCreateParams, ICategory } from '../model/ddb.model';
import { SecurityService } from './security.service';
import { SubscriptionGroup } from '../util/subscriptionGroup';
import { SessionApi } from '../api/session.api';
import { logger } from '../util/Logger';

const className = 'CategoryService';

@Injectable()
export class CategoryService implements OnDestroy {
	public readonly allCategories$ = new BehaviorSubject(new QueryResult<ICategory & HasId<string>>());
	private readonly subscriptionGroup = new SubscriptionGroup();

	public staticCategories: EnumCreateParams[] = [
		{
			"id": "1",
			"name": "Workwear",
			"parentId": undefined,
			"count": 1826
		},
		{
			"id": '89',
			"name": "PPE",
			"parentId": undefined,
			"count": 487
		},
		{
			"id": '144',
			"name": "Footwear",
			"parentId": undefined,
			"count": 155
		},
		{
			"id": '153',
			"name": "Workplace Safety",
			"parentId": undefined,
			"count": 249
		},
		{
			"id": '2',
			"name": "Hi-Vis",
			"parentId": '1',
			"count": 1120
		},
		{
			"id": '27',
			"name": "Traditional Workwear",
			"parentId": '1',
			"count": 468
		},
		{
			"id": '52',
			"name": "Fire Retardant",
			"parentId": '1',
			"count": 64
		},
		{
			"id": '64',
			"name": "Casual Wear",
			"parentId": '1',
			"count": 102
		},
		{
			"id": '90',
			"name": "Eye Protection",
			"parentId": '89',
			"count": 62
		},
		{
			"id": '95',
			"name": "Hand Protection",
			"parentId": '89',
			"count": 87
		},
		{
			"id": '109',
			"name": "Head & Face Protection",
			"parentId": '89',
			"count": 148
		},
		{
			"id": '119',
			"name": "Hearing Protection",
			"parentId": '89',
			"count": 39
		},
		{
			"id": '145',
			"name": "Boots",
			"parentId": '144',
			"count": 103
		},
		{
			"id": '150',
			"name": "Gum Boots",
			"parentId": '144',
			"count": 9
		},
		{
			"id": '151',
			"name": "Safety Shoes",
			"parentId": '144',
			"count": 25
		},
		{
			"id": '152',
			"name": "Accessories",
			"parentId": '144',
			"count": 16
		},
		{
			"id": '154',
			"name": "Traffic Management",
			"parentId": '153',
			"count": 12
		},
		{
			"id": '158',
			"name": "Fire",
			"parentId": '153',
			"count": 11
		},
		{
			"id": '159',
			"name": "Asbestos removal",
			"parentId": '153',
			"count": 0
		},
		{
			"id": '160',
			"name": "Safety Storage",
			"parentId": '153',
			"count": 4
		}
	];
	
	constructor(
		private notifications: NotificationsService,
		private categoryApi: CategoryApi,
		private security: SecurityService,
		private sessionApi: SessionApi
	) {
		// Monitor a list of every category for the current user
		this.monitorCategories();
	}

	ngOnDestroy(): void {
		if (this.subscriptionGroup) {
			this.subscriptionGroup.unsubscribe();
		}
	}

	monitorCategories() {
		this.subscriptionGroup.add(
			this.security.isAuthenticated().subscribe({
				next: () => {
					this.refreshCategoryCache();
				}
			})
		);
	}

	public refreshCategoryCache() {
		const signature = className + '.refreshCategoryCache: ';
		const authState = !!this.sessionApi.$userData.getValue();
		if (authState) {
			this.categoryApi.list(new IQueryFilter({
				limit: 10000
			}))
				.pipe(first())
				.subscribe({
					next: val => {
						logger.silly(signature + `Refreshed Category Cache`);
						this.allCategories$.next(val instanceof QueryResult ? val : new QueryResult(val));
					}
				})
		} else {
			this.allCategories$.next(new QueryResult<ICategory & HasId<string>>());
		}
	}


	public list(query: IQueryFilter) {
		return apiCallWrapper(
			this.categoryApi.list(query),
			{
				notificationsService: this.notifications,
				action: "Fetching category"
			}
		)
	}

	public create(data: any): Observable<Brand & HasId<string>> {
		return apiCallWrapper(
			this.categoryApi.create(data),
			{
				notificationsService: this.notifications,
				action: "Updating Category"
			}
		)
	}
}
