export const environment = {
	production: true,
	region: 'ap-southeast-2',
	endpoint: 'https://dev.api.reali.au',
	wsEndPoint: 'wss://dev.api.reali.au',
	logLevel: 'debug',
	appName: 'REALI',
	buildId: process.env.NG_APP_BUILD_ID || 'local',
	defaultCollectionLimit: 10,
	enableSentry: true,
	defaultTestEmailAddress: "",
version: "dev_877",
	baseDomain: 'dev.reali.au',
	readApiToken: "39cafcf2862b2e6627657b726f76d0371fd0e690",
	analyticsTrackingCode: 'G-M4GDRRC77E',
	defaultSsoPool: '',
	defaultSsoDomain: ''
};
