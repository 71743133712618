<section id="blog" class="blog-section">
  <div class="max-width-container">
    <div class="container">
      <nav aria-label="breadcrumb" class="pageBreadcrumb mb-45">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <a [routerLink]="['/news']">
              News
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{slug}}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<section class="blogs">
  <div class="max-width-container">
    <div class="container">
      <div class="single-post p-0" *ngIf="post">
        <div class="single-post-meta">
          <h4 class="single-post-header mb-4">{{post.title}}</h4>
          <ul class="single-post-meta-info">
            <li>
              <a href="javascript:void(0)"><img class="me-3 rounded-circle"
                  [src]="post.author.profile_image || '../../../../assets/images/placeholder.png'" alt="#">
                {{post.author.first_name}} {{post.author.last_name}}
              </a>
            </li>
            <li>
              <a class="d-flex align-items-center justify-content-start gap-2" href="javascript:void(0)">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14" height="14" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512"
                  xml:space="preserve" class="">
                  <g>
                    <path
                      d="M53.933 10.617h-2.38v-3.11a3.508 3.508 0 0 0-7.016 0v3.11H35.59v-3.11a3.508 3.508 0 1 0-7.016 0v3.11h-8.947v-3.11a3.508 3.508 0 1 0-7.016 0v3.11h-2.544a5.345 5.345 0 0 0-5.319 5.358v38.667A5.345 5.345 0 0 0 10.068 60h43.865a5.345 5.345 0 0 0 5.319-5.358V15.975a5.345 5.345 0 0 0-5.32-5.358zm-7.396-3.11a1.508 1.508 0 0 1 3.016 0v3.11h-3.016zm-15.963 0a1.508 1.508 0 0 1 3.016 0v3.11h-3.016zm-15.963 0a1.508 1.508 0 0 1 3.016 0v3.11H14.61zm-4.544 5.11h2.544v1.934a3.526 3.526 0 0 0 4.658 3.315 1 1 0 0 0-.655-1.89 1.517 1.517 0 0 1-2.003-1.425v-1.934h13.963v1.934a3.512 3.512 0 0 0 3.508 3.508c.861.043 2.122-.382 1.768-1.465a1 1 0 0 0-1.273-.617 1.517 1.517 0 0 1-2.003-1.426v-1.934h13.963v1.934a3.526 3.526 0 0 0 4.657 3.315 1 1 0 0 0-.654-1.89 1.516 1.516 0 0 1-2.003-1.425v-1.934h7.396a3.343 3.343 0 0 1 3.319 3.358v5.035a.972.972 0 0 0-.49-.146l-50.014.052v-4.94a3.343 3.343 0 0 1 3.32-3.36zM53.933 58H10.067a3.343 3.343 0 0 1-3.319-3.358V22.916l50.016-.052a.973.973 0 0 0 .488-.146v31.924A3.343 3.343 0 0 1 53.932 58z"
                      fill="#000000" opacity="1" data-original="#000000" class=""></path>
                    <path
                      d="M20.063 27.505h-6.452a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.452a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828h-4.452v-3.828h4.452zM34.253 27.505H27.8a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.452a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828H28.8v-3.828h4.452zM48.442 27.505H41.99a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.453a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828H42.99v-3.828h4.453zM20.063 41.694h-6.452a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.452a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828h-4.452v-3.828h4.452zM34.253 41.694H27.8a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.452a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828H28.8v-3.828h4.452zM48.442 41.694H41.99a1 1 0 0 0-1 1v5.828a1 1 0 0 0 1 1h6.453a1 1 0 0 0 1-1v-5.828a1 1 0 0 0-1-1zm-1 5.828H42.99v-3.828h4.453z"
                      fill="#000000" opacity="1" data-original="#000000" class=""></path>
                  </g>
                </svg>
                {{post.published | date: "MM.dd.YYYY"}}
              </a>
            </li>
            <li *ngFor="let tag of post.tags">
              <a class="d-flex align-items-center justify-content-start gap-2" style="cursor: pointer">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="14" height="14" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512"
                  xml:space="preserve" class="">
                  <g>
                    <g data-name="2-Price tag">
                      <path
                        d="M24 62.926a5.98 5.98 0 0 1-4.243-1.755L2.771 44.185A6.027 6.027 0 0 1 2.7 35.77L29.809 7.827A6.029 6.029 0 0 1 34.119 6H44a1 1 0 0 1 0 2h-9.881a4.019 4.019 0 0 0-2.874 1.218L4.138 37.162a4.018 4.018 0 0 0 .047 5.609l16.986 16.986a4.005 4.005 0 0 0 5.657 0l28-28A3.975 3.975 0 0 0 56 28.929V11a3 3 0 0 0-3-3h-6a1 1 0 0 1 0-2h6a5.006 5.006 0 0 1 5 5v17.929a5.96 5.96 0 0 1-1.757 4.243l-28 28A5.98 5.98 0 0 1 24 62.926z"
                        fill="#000000" opacity="1" data-original="#000000"></path>
                      <path
                        d="M49 20a5.006 5.006 0 0 1-5-5 1 1 0 0 1 2 0 3 3 0 1 0 1.016-2.25 1 1 0 0 1-1.324-1.5A5 5 0 1 1 49 20z"
                        fill="#000000" opacity="1" data-original="#000000"></path>
                      <path
                        d="M49.5 14a6.5 6.5 0 1 1 4.953-10.71 1 1 0 1 1-1.523 1.3A4.5 4.5 0 1 0 49.5 12a1 1 0 0 1 0 2z"
                        fill="#000000" opacity="1" data-original="#000000"></path>
                    </g>
                  </g>
                </svg>
                {{tag.name}}</a>
            </li>
            <li *ngFor="let cat of post.categories">
              <a class="d-flex align-items-center justify-content-start gap-2" style="cursor: pointer">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="Layer 44"><path d="M10.19 2.5a7.73 7.73 0 1 0 7.73 7.73 7.74 7.74 0 0 0-7.73-7.73zm0 12.46a4.73 4.73 0 1 1 4.73-4.73A4.73 4.73 0 0 1 10.19 15zM10.19 24.27A7.73 7.73 0 1 0 17.92 32a7.74 7.74 0 0 0-7.73-7.73zm0 12.46A4.73 4.73 0 1 1 14.92 32a4.73 4.73 0 0 1-4.73 4.73zM10.19 46a7.73 7.73 0 1 0 7.73 7.73A7.74 7.74 0 0 0 10.19 46zm0 12.46a4.73 4.73 0 1 1 4.73-4.73 4.73 4.73 0 0 1-4.73 4.77zM29.92 14.84h27a4.62 4.62 0 1 0 0-9.23h-27a4.62 4.62 0 0 0 0 9.23zm0-6.23h27a1.62 1.62 0 1 1 0 3.23h-27a1.62 1.62 0 0 1 0-3.23zM56.92 27.38h-27a4.62 4.62 0 0 0 0 9.24h27a4.62 4.62 0 0 0 0-9.24zm0 6.24h-27a1.62 1.62 0 0 1 0-3.24h27a1.62 1.62 0 0 1 0 3.24zM56.92 49.15h-27a4.62 4.62 0 0 0 0 9.23h27a4.62 4.62 0 1 0 0-9.23zm0 6.23h-27a1.62 1.62 0 0 1 0-3.23h27a1.62 1.62 0 1 1 0 3.23z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></g></svg>
                {{cat.name}}</a>
            </li>
          </ul>
        </div>
        <div class="single-post-thumbnail my-5" *ngIf="post.featured_image">
          <img [src]="post.featured_image" class="w-100" alt="post.featured_image_alt">
        </div>
        <div class="single-post-body" [innerHTML]="post.body">
        </div>
      </div>
    </div>
  </div>
</section>