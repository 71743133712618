import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainTemplateComponent } from './template/main-template/main-template.component';
import { UnAuthGuard } from './guard/unauth.guard';
import { AuthTemplateComponent } from './template/auth-template/auth-template.component';
import { AppTemplateComponent } from './template/app-template/app-template.component';
import { ResetComponent } from './auth/reset/reset.component';
import { RoleGuard } from './guard/role.guard';
import { AuthGuard } from './guard/auth.guard';
import { UserRoleGuard } from './guard/user_role.guard';
import { routeSecurity } from './routing-security';
import { RegisterComponent } from './auth/register/register.component';
import { ReturnsExchangesComponent } from './view/returns-exchanges/returns-exchanges.component';
import { ShippingDeliveryComponent } from './view/shipping-delivery/shipping-delivery.component';
import { UnauthProductPageComponent } from './view/unauth-product-page/unauth-product-page.component';
import { environment } from 'src/environments/environment';
import { GawunTemplateComponent } from '../gawun/gawun-template/gawun-template.component';

const gawunRoutes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('../gawun/gawun-home/gawun-home.module').then(m => m.GawunHomeModule),
	},
];

const realiRoutes: Routes = [
	{
		path: '',
		canActivate: [UnAuthGuard],
		component: AuthTemplateComponent,
		children: [
			{
				path: 'login',
				component: ResetComponent,
				data: { animation: 'Reset' },
			},
			{
				path: '',
				loadChildren: () =>
					import('./view/home/home.module').then((m) => m.HomeModule),
			},
		],
	},
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./reports/reports.module').then((m) => m.ReportsModule),
	},
	{
		path: 'manage',
		canActivate: [AuthGuard],
		component: AppTemplateComponent,
		children: [
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
			},
			{
				path: 'customer',
				loadChildren: () =>
					import('./customers/customers.module').then(
						(m) => m.CustomersModule
					),
			},
			{
				path: 'customer',
				loadChildren: () =>
					import('./customers/customer-action/address/address.module').then((m) => m.AddressModule),
			},
			{
				path: 'customer',
				loadChildren: () =>
					import('./customers/customer-action/business-unit/business-unit.module').then((m) => m.BusinessUnitModule),
			},
			{
				path: 'customer',
				loadChildren: () =>
					import('./customers/customer-action/document/document.module').then((m) => m.DocumentModule),
			},
			{
				path: 'products',
				loadChildren: () =>
					import('./manage-products/manage-products.module').then(
						(m) => m.ManageProductsModule
					),
			},
			{
				path: 'decorations',
				loadChildren: () =>
					import('./manage-decorations/manage-decorations.module').then(
						(m) => m.ManageDecorationsModule
					),
			},
			{
				path: 'collections',
				loadChildren: () =>
					import('./collections/collections.module').then(
						(m) => m.CollectionsModule
					),
			},
			{
				path: 'sizeChart',
				loadChildren: () =>
					import('./manage-size-chart/manage-size-chart.module').then(
						(m) => m.ManageSizeChartModule
					),
			},
			{
				path: 'categories',
				canActivate: [RoleGuard],
				data: {
					key: 'categories',
					singular: 'category',
					multiple: 'categories',
					canCascade: true,
					title: 'Manage Categories',
					permissions: ['ADMIN', 'manage_categories'],
				},
				loadChildren: () =>
					import('./enum/enum.module').then((m) => m.EnumModule),
			},
			{
				path: 'brands',
				canActivate: [RoleGuard],
				data: {
					key: 'brands',
					singular: 'brand',
					multiple: 'brands',
					canCascade: false,
					title: 'Manage Brands',
					permissions: ['ADMIN', 'manage_brands'],
				},
				loadChildren: () =>
					import('./enum/enum.module').then((m) => m.EnumModule),
			},
			{
				path: 'labels',
				canActivate: [RoleGuard],
				data: {
					key: 'labels',
					singular: 'label',
					multiple: 'labels',
					canCascade: false,
					title: 'Manage Labels',
					permissions: ['ADMIN', 'manage_brands'],
				},
				loadChildren: () =>
					import('./enum/enum.module').then((m) => m.EnumModule),
			},
			{
				path: 'userRoles',
				loadChildren: () =>
					import('./manage-roles/user/user-roles.module').then(
						(m) => m.UserRolesModule
					),
			},
			{
				path: 'adminRoles',
				loadChildren: () =>
					import('./manage-roles/admin/admin-roles.module').then(
						(m) => m.AdminRolesModule
					),
			},
			{
				path: 'system',
				loadChildren: () =>
					import('./manage-system/manage-system.module').then(
						(m) => m.ManageSystemModule
					),
			},
			{
				path: 'admin-reports',
				loadChildren: () =>
					import('./admin-reports/admin-reports.module').then(
						(m) => m.AdminReportsModule
					),
			},
			{
				path: 'email-test',
				loadChildren: () =>
					import('./email-test/email-test.module').then(
						(m) => m.EmailTestModule
					),
			},
			{
				path: 'users/admin',
				loadChildren: () =>
					import('./admin/admin.module').then((m) => m.AdminModule),
			},
			{
				path: 'users/groups',
				loadChildren: () =>
					import('./groups/groups.module').then((m) => m.GroupsModule),
			},
		],
	},
	{
		path: 'manage',
		component: AppTemplateComponent,
		canActivate: routeSecurity['/manage/orders'],
		loadChildren: () =>
			import('./manage-orders/manage-orders.module').then(
				(m) => m.ManageOrdersModule
			),
	},
	{
		path: '',
		component: AuthTemplateComponent,
		children: [
			{
				path: 'home',
				loadChildren: () =>
					import('./view/home/home.module').then((m) => m.HomeModule),
			},
			{ path: 'register/:RegisterSecret', canActivate: [UnAuthGuard], component: RegisterComponent },
			{
				path: 'guest',
				loadChildren: () =>
					import('./user/user.module').then((m) => m.UserModule),
			},
			{
				path: 'products',
				loadChildren: () =>
					import('./products/product-list/product-view.module').then(
						(m) => m.ProductListModule
					),
			},
			{
				path: 'product',
				loadChildren: () =>
					import(
						'./products/product-breadcrumbs/product-breadcrumbs.module'
					).then((m) => m.ProductBreadcrumbsModule),
			},
			{
				path: 'manage/products',
				loadChildren: () =>
					import(
						'./products/product-breadcrumbs/product-breadcrumbs.module'
					).then((m) => m.ProductBreadcrumbsModule),
			},
			{
				path: 'quick-order',
				canActivate: [UserRoleGuard],
				data: {
					permissions: ['cart_access'],
				},
				loadChildren: () =>
					import(
						'./products/quick-order-list/quick-order-list.module'
					).then((m) => m.QuickOrderListModule),
			},
			{
				path: 'safety-uniform-management-system',
				loadChildren: () =>
					import('./view/solutions/sums/sums.module').then(
						(m) => m.SumsModule
					),
			},
			{
				path: 'product-customisation',
				loadChildren: () =>
					import(
						'./view/solutions/product-customization/product-customization.module'
					).then((m) => m.ProductCustomizationModule),
			},
			{
				path: 'eurekaSafety',
				loadChildren: () =>
					import(
						'./view/solutions/eureka-safety/eureka-safety.module'
					).then((m) => m.EurekaSafetyModule),
			},
			{
				path: 'diverseSupplyChain',
				loadChildren: () =>
					import('./view/solutions/supply-chain/supply-chain.module').then(
						(m) => m.SupplyChainModule
					),
			},

			{
				path: 'industries',
				loadChildren: () =>
					import('./view/industries/industries.module').then(
						(m) => m.IndustriesModule
					),
			},
			{
				path: 'contactus',
				loadChildren: () =>
					import('./view/contactus/contact-us/contact-us.module').then(
						(m) => m.ContactUsModule
					),
			},
			{
				path: 'news',
				loadChildren: () =>
					import('./view/blogs/blogs.module').then(
						(m) => m.BlogsModule
					),
			},
			{
				path: 'aboutUs',
				loadChildren: () =>
					import('./view/about/about-us/about-us.module').then(
						(m) => m.AboutUsModule
					),
			},
			{ path: 'shipping-delivery', component: ShippingDeliveryComponent },
			{ path: 'returns-exchanges', component: ReturnsExchangesComponent },
			{ path: 'connect', component: UnauthProductPageComponent }
		],
	},
	{
		path: 'cart',
		loadChildren: () =>
			import('./manage-cart/manage-cart.module').then(
				(m) => m.ManageCartModule
			),
	},
	{
		path: 'account',
		component: AppTemplateComponent,
		loadChildren: () =>
			import('./manage-orders/manage-orders.module').then(
				(m) => m.ManageOrdersModule
			),
	},
	{
		path: 'account',
		canActivate: [AuthGuard],
		component: AppTemplateComponent,
		children: [
			{
				path: 'favorites',
				loadChildren: () =>
					import('./favorites/favorites-list/favorites-list.module').then(
						(m) => m.FavoritesListModule
					),
			},
			{
				path: 'favorites',
				loadChildren: () =>
					import(
						'./favorites/favorites-detail/favorites-detail.module'
					).then((m) => m.FavoritesDetailModule),
			},
		],
	},
	{
		path: 'account/balance',
		component: AppTemplateComponent,
		loadChildren: () =>
			import('./user/balance-detail/balance-detail.module').then(
				(m) => m.BalanceDetailModule
			),
	},
	{
		path: 'manage/users',
		component: AppTemplateComponent,
		canActivate: routeSecurity['/manage/users'],
		loadChildren: () =>
			import('./user/user.module').then((m) => m.UserModule),
	},
	{
		path: 'account/user',
		component: AppTemplateComponent,
		canActivate: [UserRoleGuard],
		data: { permissions: ['list_account_users'] },
		loadChildren: () =>
			import('./user/user.module').then((m) => m.UserModule),
	},
	{
		path: 'account',
		component: AppTemplateComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'collections',
				loadChildren: () =>
					import('./collections/collections.module').then(
						(m) => m.CollectionsModule
					),
			},
			{
				path: 'employe',
				data: { permissions: ['list_account_users'] },
				loadChildren: () =>
					import('./manage-employes/manage-employes.module').then(
						(m) => m.ManageEmployesModule
					),
			},
			{
				path: 'users',
				canActivate: [UserRoleGuard],
				data: { permissions: ['list_account_users'] },
				loadChildren: () =>
					import('./manage-employes/manage-employes.module').then(
						(m) => m.ManageEmployesModule
					),
			},
			{
				path: 'documents',
				loadChildren: () =>
					import('./customers/customer-action/document/document.module').then(
						(m) => m.DocumentModule
					),
			},
			{
				path: 'addresses',
				loadChildren: () =>
					import('./customers/customer-action/address/address.module').then(
						(m) => m.AddressModule
					),
			},
			{
				path: 'business-unit',
				loadChildren: () =>
					import('./customers/customer-action/business-unit/business-unit.module').then(
						(m) => m.BusinessUnitModule
					),
			},
			{
				path: 'details/:id/customer/:customerId',
				loadChildren: () =>
					import('./user/customer-users/user-edit/user-profile-edit/user-profile-edit.module').then(
						(m) => m.UserProfileEditModule
					),
			},
		],
	},
	{ path: '**', redirectTo: 'home' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(environment.appName === 'GAWN' ? gawunRoutes : realiRoutes, {
			useHash: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
