<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item"><a href="#">manage users</a></li>
			<li class="breadcrumb-item active" aria-current="page">main page</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div
			class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4"
		>
			<h1 class="title mb-0">Users</h1>
			<div class="edit-btns w-sm-100">
				<button
					class="btn btn-secondary w-sm-100"
					type="button"
					(click)="downloadImportTemplate()"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M6.25 8.5L10 12.25L13.75 8.5"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 12.25V3.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Export Template
				</button>
				<button
					class="btn btn-secondary w-sm-100"
					type="button"
					(click)="importUser.click()"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M6.25 8.5L10 12.25L13.75 8.5"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 12.25V3.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Import Users
				</button>
				<div class="d-none">
					<input type="file" #importUser (change)="fileUploadChange($event)" />
				</div>

				<a
					class="btn btn-primary w-sm-100"
					[routerLink]="['/account/employe', customerId]"
					role="button"
				>
					Add new User
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
				</a>
			</div>
		</div>

		<div class="manage-user-list">
			<app-user-list></app-user-list>
		</div>
	</div>
</div>
