import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoNegative]'
})
export class NoNegativeDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '-' || event.key === 'Subtract' || event.key === '0') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    if (pastedText?.includes('-')) {
      event.preventDefault();
    }
  }

}
